import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FilterTableService } from '../filter-table/filter-table.service';
import { FilterTableConfigEnum, IFilterTableConfig } from '../filter-table/filter-table.model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FilterCtrlEnum, FilterCtrlTypesEnum, IfilterCtrl } from './search-filter.model';
import { DatePipe } from '@angular/common';
import { GLOBAL } from '@shared/constants/global/global.constants';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent implements OnInit, OnDestroy {

  @Input() typeFilter: FilterTableConfigEnum;
  @Input() inputListGroup: IfilterCtrl[];
  @Input() inputList: IfilterCtrl[];
  @Input() showButton = true;
  @Input() specificDependencies: any;
  @Input() showBoxBorder: boolean = true;
  @Input() guia: any;
  @Input() searchOnSelect: boolean = false;
  @Input() textWarning: boolean = true;

  @Output() searchData: EventEmitter<any> = new EventEmitter();
  @Output() externalSearchEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() setValue = new EventEmitter();


  searchFormGroup = new FormGroup({});
  private filterTableConfig: IFilterTableConfig;
  private searchParams: any = {};
  disabledSearch: boolean = false;

  constructor(
    private readonly filterTableService: FilterTableService,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit() {
    // listado controles del filtro
    if (this.typeFilter) {
      this.filterTableConfig = this.filterTableService.getFilterTableConfig(this.typeFilter);
      this.inputList = this.searchForFilterCtrlData();
      if (this.inputListGroup) {
        this.inputList = this.inputListGroup;
      }
    } else {
      if (this.inputListGroup) {
        this.inputList = this.inputListGroup;
      }
      this.inputList = this.searchForFilterCtrlData(this.inputList);
    }

    this.inputList.forEach(input => {
      input.parentName = this.searchFormGroup;
    });

    // Primera llamada del filtro
    this.doSearch(true);
  }

  /**
   * @description Método que busca si hay un filtro en la configuración del ctrl y lo setea en el searchParams
   * @memberof PocSearchFilterComponent
   */
  searchForFilterCtrlData(forceCtrlConfig?) {
    let ctrlsConfigArray = null;
    if (forceCtrlConfig) {
      ctrlsConfigArray = forceCtrlConfig;
    } else {
      if (this.typeFilter && !this.filterTableConfig) {
        this.filterTableConfig = this.filterTableService.getFilterTableConfig(this.typeFilter);
      }
      ctrlsConfigArray = this.filterTableConfig.filterCtrlsConfig as IfilterCtrl[];
    }

    ctrlsConfigArray.forEach((ctrlConfig: IfilterCtrl) => {
      if (ctrlConfig.filter) {
        this.valueSelected({
          item: {
            value: ctrlConfig.filter,
            id: ctrlConfig.filter,
            allData: {
              key: undefined,
              value: ctrlConfig.filter
            }
          },
          controlName: ctrlConfig.controlName
        });
      }
    });
    return ctrlsConfigArray;
  }

  doSearch(initial?) {
    this.searchData.emit({ button: true, initial });
  }

  /**
   * @description Método para setear un solo parámetro fuera del flujo
   * @param {*} event
   * @param {string} field
   * @param {string} param
   * @returns
   * @memberof SearchFilterComponent
   */
  setValueParam(event: any, field: string, param: string) {
    if (event.item && event.controlName === field) {
      return event.item.id ? event.item.id : undefined;
    } else {
      return this.searchParams[param];
    }
  }

  /**
   * @description Método lanzado desde el ambio de valor de un input del filtro
   * @param {*} event
   * @memberof SearchFilterComponent
   */
  valueSelected(event: any) {
    this.setValue.emit(event);

    // función para devolver el valor de un input, si el id es '' devuelve undefined
    const setValue = (field: string, param: string) => {
      if (event.controlName === field) {
        return this.setByControlName(event);
      } else {
        return this.searchParams[param] ? this.searchParams[param] : undefined;
      }
    };

    this.setBodyParams(event, setValue);

    const eventObj = {
      action: event,
      searchForm: this.searchFormGroup
    };
    this.externalSearchEvent.emit(eventObj);

    if (this.searchOnSelect && event.ctrlType === FilterCtrlTypesEnum.SEARCH) {
      this.doSearch();
    }
  }

  private setByControlName(event: any): Object {
    if (event.controlName === FilterCtrlEnum.START_DATE_REQUEST_START || event.controlName === FilterCtrlEnum.END_DATE_REQUEST_START) {
      return (event.item !== '' && event.item?.id !== '') ? this.datePipe.transform(new Date(event.item.id), 'yyyy-MM-ddT00:00:00.000') : undefined;
    } else if (event.controlName === FilterCtrlEnum.START_DATE_REQUEST_END || event.controlName === FilterCtrlEnum.END_DATE_REQUEST_END) {
      return (event.item !== '' && event.item?.id !== '') ? this.datePipe.transform(new Date(event.item.id), 'yyyy-MM-ddT23:59:59.999') : undefined;
    } else {
      return (event.item && event.item?.id !== '' && event.item?.value !== GLOBAL.COMMON_STATUS_ALL) ? event.item.id : undefined;
    }
  }

  /**
   * @description Método para el seteo de los parámetros para enviar cuando se lanze el evento de buscar
   * @param {*} setValue Método que devuelve el value del ctrl o undefined
   * @returns
   */

  setParams(setValue, event) {
    let params = {
      ...this.searchParams
      // 'status.equals': setValue(FilterCtrlEnum.STATUS, 'status.equals')
    };

    switch (this.typeFilter) {
      // EXAMPLE
      // case FilterTableConfigEnum.SYSTEM_COLLECTIVE:
      //   params = {
      //     ...params,
      //     'process.contains': setValue(FilterCtrlEnum.PROCESS, 'process.contains'),
      //     'descr.contains': setValue(FilterCtrlEnum.DESCRIPTION, 'descr.contains')
      //   };
      //   break;
      case FilterTableConfigEnum.CONSULT_REQUEST:
        params = {
          'category.equals': setValue(FilterCtrlEnum.CATEGORY, 'category.equals'),
          'status.equals': setValue(FilterCtrlEnum.STATE, 'status.equals'),
          'startDate.greaterThanOrEqual': setValue(FilterCtrlEnum.START_DATE_REQUEST_START, 'startDate.greaterThanOrEqual'),
          'startDate.lessThanOrEqual': setValue(FilterCtrlEnum.START_DATE_REQUEST_END, 'startDate.lessThanOrEqual'),
          'endDate.greaterThanOrEqual': setValue(FilterCtrlEnum.END_DATE_REQUEST_START, 'endDate.greaterThanOrEqual'),
          'endDate.lessThanOrEqual': setValue(FilterCtrlEnum.END_DATE_REQUEST_END, 'endDate.lessThanOrEqual')
        };
        break;
    }
    return params;
  }

  /**
   * @description Método para borrar los datos del filtro
   */
  removeFilterData() {
    this.searchFormGroup = new FormGroup({});
    this.filterTableConfig = null;
    this.searchParams = {};
    this.filterTableService.removeElementCacheData('OBJECT_FILTER_REQUEST');
    this.filterTableService.removeElementCacheData('BODY_REQUEST_OLD');
  }

  /**
   * @Método para la limpieza de datos del filterTableService y los datos del filtro
   * @memberof SearchFilterComponent
   */
  ngOnDestroy(): void {
    this.removeFilterData();
  }

  /**
   * @description Método que setea los parámetros del filtro que se enviarán al servicio a través del body de la llamada REST
   * @private
   * @param {*} event
   * @param {*} setValue
   * @memberof SearchFilterComponent
   */
  private setBodyParams(event: any, setValue: any) {

    this.searchParams = this.setParams(setValue, event);

    // Si son controles datepicker se evalúa el minDate y el  maxDate
    event.controlName.includes('fecha') && this.setLimitDate(event.controlName, event.item.value);

    // Guardamos los parámetros en el servcio Filter-Table
    this.filterTableService.setBodyParams(this.searchParams, this.inputListGroup ? true : false);

    // Ejecutamos las acciones que hay que realizar en los demás controles según el valor que ha seteado
    // Dicha función está en el componente padre del filtro
    if (this.specificDependencies && typeof this.specificDependencies === 'function' && !event.initial) {
      this.specificDependencies(event, this.searchFormGroup, this.inputList);
    }
  }

  /**
   * @Método para setear los límites de los datepickers (maxDate - minDate)
   * @private
   * @param {string} input
   * @param {NgbDate} date
   */
  private setLimitDate(input: string, date: NgbDate) {
    if (input === 'fechaInicioSolicitud') {
      this.inputList[this.inputList.findIndex(element => element.controlName === 'fechaFinSolicitud')].minDay = date;
    } else if (input === 'fechaFinSolicitud') {
      this.inputList[this.inputList.findIndex(element => element.controlName === 'fechaInicioSolicitud')].maxDay = date;
    } else {
      this.inputList[this.inputList.findIndex(element => element.controlName === input)].value = date;
    }
  }
}
