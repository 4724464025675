/* tslint:disable */
import { Injectable } from '@angular/core';
import { SilverBaseService } from '../silver-base-service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { SilverRequestBuilder } from '../silver-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StateDto } from '../models/state-dto';

@Injectable({
  providedIn: 'root',
})
export class StatesService extends SilverBaseService {
  constructor(
    config: EnviromentService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllStatesUsingGet
   */
  static readonly GetAllStatesUsingGetPath = '/states';

  /**
   * getAllStates.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getAllStatesUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStatesUsingGet$Response(params: {

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;
    'countryId.equals': string;
    'stateId.equals'?: string;
    'stateDesc.contains'?: string;

  }): Observable<StateDto> {

    const req = new SilverRequestBuilder(StatesService.GetAllStatesUsingGetPath, 'get');
    if (params) {
      req.query('page', params.page, {});
      req.query('size', params.size, {});
      req.query('countryId.equals', params['countryId.equals'], {});
      req.query('stateId.equals', params['stateId.equals'], {});
      req.query('stateDesc.contains', params['stateDesc.contains'], {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as StateDto;
      })
    );
  }

  /**
   * getAllStates.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAllStatesUsingGet$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStatesUsingGet(params: {

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;
    'countryId.equals': string;
    'stateId.equals'?: string;
    'stateDesc.contains'?: string;

  }): Observable<StateDto> {

    return this.getAllStatesUsingGet$Response(params).pipe(
      map(r => r as StateDto)
    );
  }

  /**
   * Path part for operation getStateUsingGet
   */
  static readonly GetStateUsingGetPath = '/states/{countryId}/{municipalityId}';

  /**
   * getState.
   *
   * Obtener la provincia (State) filtrando por país (countryId) y municipio (municipalityId).
   *
   * This method provides customization options.
   * To access only the response body, use `getStateUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStateUsingGet$Response(params: {

    /**
     * ID del país del estado que se desea obtener.
     */
    countryId: string;

    /**
     * ID del municipio del estado que se desea obtener.
     */
    municipalityId: string;

  }): Observable<StateDto> {

    const req = new SilverRequestBuilder(StatesService.GetStateUsingGetPath, 'get');
    if (params) {
      req.path('countryId', params.countryId, {});
      req.path('municipalityId', params.municipalityId, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as StateDto;
      })
    );
  }

  /**
   * getState.
   *
   * Obtener la provincia (State) filtrando por país (countryId) y municipio (municipalityId).
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getStateUsingGet$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getStateUsingGet(params: {

    /**
     * ID del país del estado que se desea obtener.
     */
    countryId: string;

    /**
     * ID del municipio del estado que se desea obtener.
     */
    municipalityId: string;

  }): Observable<StateDto> {

    return this.getStateUsingGet$Response(params).pipe(
      map(r => r as StateDto)
    );
  }

}
