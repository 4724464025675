/* tslint:disable */
import { Injectable } from '@angular/core';
import { SilverBaseService } from '../silver-base-service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { SilverRequestBuilder } from '../silver-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommentDto } from '../models/comment-dto';
import { CommentRequestDto } from '../models/comment-request-dto';

@Injectable({
  providedIn: 'root',
})
export class CommentsService extends SilverBaseService {
  constructor(
    config: EnviromentService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation addComments
   */
  static readonly AddCommentsPath = '/comments/{ticketId}';

  /**
   * addComments.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `addComments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addComments$Response(params: {

    /**
     * The ticket identifier
     */
    ticketId: string;
  
    /**
     * Comment to add to the ticket
     */
    body: CommentRequestDto
  }): Observable<CommentDto> {

    const req = new SilverRequestBuilder(CommentsService.AddCommentsPath, 'post');
    if (params) {
      req.path('ticketId', params.ticketId, {});
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as CommentDto;
      })
    );
  }

  /**
   * addComments.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `addComments$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addComments(params: {

    /**
     * The ticket identifier
     */
    ticketId: string;
  
    /**
     * Comment to add to the ticket
     */
    body: CommentRequestDto
  }): Observable<CommentDto> {

    return this.addComments$Response(params).pipe(
      map(r => r as CommentDto)
    );
  }

}
