export enum Actions {
  Edit = 'Edit',
  Delete = 'Delete',
  Show = 'Show',
}

export enum ITEM_VALUE_TYPE {
  TEXT,
  NUMBER,
  BOOLEAN
}
