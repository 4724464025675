<div class="intro-footer">
  <div class="copy-footer">
    <div class="col-4">
      <a href="https://www.santander.com/es/home" target="_blank" rel="noopener noreferrer">
        <img class="img-fluid" src="./assets/img/logo.svg" alt="Santander logo"/>
      </a>
    </div>
    <div class="col-8">
      <p class="mb-0">
        {{'LEGALS.REGISTER' | translate}}
      </p>
      <p class="recommended-browser">
        {{'WELCOME.TEXT_2' | translate}}
      </p>
    </div>
  </div>
</div>
