/* tslint:disable */
import { Injectable } from '@angular/core';
import { SilverBaseService } from '../silver-base-service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { SilverRequestBuilder } from '../silver-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Status } from '../models/status';

@Injectable({
  providedIn: 'root',
})
export class StatusService extends SilverBaseService {
  constructor(
    config: EnviromentService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllStatuses
   */
  static readonly GetAllStatusesPath = '/statuses';

  /**
   * getAllStatuses.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getAllStatuses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStatuses$Response(params: {

    /**
     * The language identifier
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<Status> {

    const req = new SilverRequestBuilder(StatusService.GetAllStatusesPath, 'get');
    if (params) {
      req.query('language', params.language, {});
      req.query('page', params.page, {});
      req.query('size', params.size, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Status;
      })
    );
  }

  /**
   * getAllStatuses.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAllStatuses$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStatuses(params: {

    /**
     * The language identifier
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<Status> {

    return this.getAllStatuses$Response(params).pipe(
      map(r => r as Status)
    );
  }

}
