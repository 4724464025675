<div class="container">
  <div class="container legals-bottom contact-container d-flex flex-column">
    <!-- <p class="contact-ctn">
      <i class="icn-help"></i>
      <span [translate]="'LEGALS.FOOTER.QUESTION'"></span>
      <a [routerLink]="['/', urlContact]" rel="noopener noreferrer" [translate]="'LEGALS.FOOTER.CONTACT'"></a>
    </p> -->
    <div class="links row">
      <div class="col-4 text-center">
        <a [routerLink]="['/', urls.AppLegals, urls.AppAdvise]">{{'LEGALS.TITLES.ADVISE' | translate}}</a></div>
      <div class="col-4 text-center">
        <a [routerLink]="['/', urls.AppLegals, urls.AppCookie]">{{'LEGALS.TITLES.COOKIE' | translate}}</a></div>
      <div class="col-4 text-center">
        <a [routerLink]="['/', urls.AppLegals, urls.AppPolicy]">{{'LEGALS.TITLES.POLICY' | translate}}</a></div>
    </div>
  </div>
</div>

