import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  @Input() content: string | string[];
  @Input() icon: string = null;
  isString: boolean;

  ngOnInit() {
    this.isString = typeof this.content === 'string';
  }
}
