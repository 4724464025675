/* tslint:disable */
import { HttpService } from '@core/http/http.service';
import { EnviromentService } from '@core/services/environment/environment.service';

/**
 * Base class for API services
 */
export class ApiBaseService {
  readonly _config;
  constructor(
    protected config: EnviromentService,
    protected http: HttpService
  ) {
  this._config = this.config.get();
  }

  /**
   * Returns the app config
   */
  get appConfig() {
    return this._config || this.config.get();
  }
}
