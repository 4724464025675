import { IColumnTableElement, TABLE_CONFIG } from '../custom-table/custom-table.model';
import {
  FILTER_CTRL_CONFIG_TYPES,
  IfilterCtrl,
  IfilterGroupCtrl,
  UserRoleTypeEnum
} from '../search-filter/search-filter.model';

/**
 * @name PageTypeEnum
 * @description Enum para saber el tipo de datos y así setear los params ya q tienen diferentes modelos
 * @export
 * @enum {number}
 */
export enum PageTypeEnum {
  // EXAMPLE
  // SYSTEM_COLLECTIVE
  'CONSULT_REQUEST' = 'CONSULT_REQUEST'
}

/**
 * @name FilterTableConfigEnum
 * @description Enum para la obtención de la configuración del filter-table (páginas)
 * @export
 * @enum {number}
 */
export enum FilterTableConfigEnum {
  SCROLL_REQUEST = 'SCROLL_REQUEST',
  GROUP_FILTER = 'GROUP_FILTER',
  CONSULT_REQUEST = 'CONSULT_REQUEST'
  // EXAMPLE
  // SYSTEM_COLLECTIVE = 'SYSTEM_COLLECTIVE',
}

/**
 * @name IFilterTableConfig
 * @description Interfaz de configuración del filter-table
 * @export
 * @interface IFilterTableConfig
 */
export interface IFilterTableConfig {
  pageType?: PageTypeEnum;
  userRoleType?: UserRoleTypeEnum;
  filterTableConfig: FilterTableConfigEnum;
  filterCtrlsConfig: IfilterCtrl[] | IfilterGroupCtrl[];
  tableColumnsConfig: IColumnTableElement[];
  downloadButton?: boolean;
  showOrders?: boolean;
  textWarning?: boolean;
}

/**
 * @name FILTER_TABLE_CONFIG
 * @description Datos de configuración de las columnas del custom-table
 * @export
 * @constant {IFilterTableConfig} FILTER_TABLE_CONFIG
 */
export const FILTER_TABLE_CONFIG: IFilterTableConfig[] = [
  // EXAMPLE
  // {
  //   pageType: PageTypeEnum.SYSTEM_COLLECTIVE,
  //   filterTableConfig: FilterTableConfigEnum.SYSTEM_COLLECTIVE,
  //   filterCtrlsConfig: [FILTER_CTRL_CONFIG_TYPES.filters[0], FILTER_CTRL_CONFIG_TYPES.filters[1], FILTER_CTRL_CONFIG_TYPES.filters[2]],
  //   tableColumnsConfig: [TABLE_ACTIONS_CONFIG.TABLE_REMOVE_EDIT[0], ...TABLE_CONFIG.SYSTEM_COLLECTIVE],
  //   downloadButton: false,
  //   showOrders: false
  // },

  // FILTRO-TABLA de Consultas de solicitudes
  {
    pageType: PageTypeEnum.CONSULT_REQUEST,
    filterTableConfig: FilterTableConfigEnum.CONSULT_REQUEST,
    filterCtrlsConfig: [
      FILTER_CTRL_CONFIG_TYPES.filters[0], FILTER_CTRL_CONFIG_TYPES.filters[3],
      FILTER_CTRL_CONFIG_TYPES.filters[4], FILTER_CTRL_CONFIG_TYPES.filters[5]
    ],
    tableColumnsConfig: [...TABLE_CONFIG.CONSULT_REQUEST],
    downloadButton: false
  }
];
