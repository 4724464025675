import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UtilsService } from '@app/core/services/utils/utils.service';
import {
  FilterTableConfigEnum,
  IFilterTableConfig,
  PageTypeEnum
} from '@app/shared/components/filter-table/filter-table.model';
import { FilterTableService } from '@app/shared/components/filter-table/filter-table.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CustomTableComponent } from '../custom-table/custom-table.component';
import { TableColumnsEnum } from '../custom-table/custom-table.model';
import { IfilterGroupCtrl } from '../search-filter/search-filter.model';
import { CustomTableService } from '../custom-table/custom-table.service';

@Component({
  selector: 'app-filter-table',
  templateUrl: './filter-table.component.html',
  styleUrls: ['./filter-table.component.scss']
})
export class FilterTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filterTableType: FilterTableConfigEnum;
  @Input() detailTemplate: TemplateRef<any>;
  @Input() showCollapse: boolean[] = [];
  @Input() guia = false;
  @Input() initialCall: boolean;
  @Input() showFilterBorder: boolean = true;
  @Input() showOnlyFilterInputs: boolean = false;
  @Input() autoSearch: boolean = false;

  @Output() rowAction: EventEmitter<any> = new EventEmitter();
  @Output() showDetail: EventEmitter<any> = new EventEmitter();
  @Output() valueSelected: EventEmitter<any> = new EventEmitter();

  @ViewChild('customTable') customTable: CustomTableComponent;
  FilterTableConfigEnum = FilterTableConfigEnum;
  filterTableConfig: IFilterTableConfig = null;
  inputListGroup: IfilterGroupCtrl[];
  totalElements: number;
  page = 0;
  columns = [];
  rows: Array<any> = [];
  order: any;
  isScroll: boolean;
  open: number;
  downloadButton = true;
  pageType = PageTypeEnum;
  showOrder: boolean = true;
  textWarning: boolean = true;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private readonly utilsService: UtilsService,
    private readonly filterTableService: FilterTableService,
    private readonly customTableService: CustomTableService
  ) {
  }


  ngOnChanges(changes: SimpleChanges): void {
    // Obtenemos la configuración del FilterTable
    this.checkForConfig();
  }

  ngOnInit(): void {
    this.checkForConfig();
    // columnas para el custom-table
    this.filterTableConfig.tableColumnsConfig.forEach(column => {
      this.columns.push(column);
    });
    // Cuando tenemos varios filtros agrupados por desplegables
    if (this.filterTableType === FilterTableConfigEnum.GROUP_FILTER) {
      // Guardamos en inputListGroup los grupos de filtros configurados en el modelo filter-group.model.ts
      this.inputListGroup = this.filterTableConfig.filterCtrlsConfig as IfilterGroupCtrl[];
      this.searchData(true);
    }

    this.downloadButton = this.filterTableConfig.downloadButton;
    if (this.filterTableConfig.hasOwnProperty('showOrders')) {
      this.showOrder = this.filterTableConfig.showOrders;
    }

    if (this.filterTableConfig.hasOwnProperty('textWarning')) {
      this.textWarning = this.filterTableConfig.textWarning;
    }
  }

  /**
   * @description Método que obtiene la configuración del filter-table si no existe
   */
  checkForConfig() {
    if (!this.filterTableConfig) {
      this.filterTableConfig = this.filterTableService.getFilterTableConfig();
      this.filterTableType = this.filterTableConfig.filterTableConfig;
    }
  }

  setValue(event) {
    this.valueSelected.emit(event);
  }


  /**
   * @description Método que lanza el servicio de búsqueda de datos para el custom-table
   * rows: Filas obtenidas del servicio para el custom-table
   * showCollapse: si procede se resetea el array para los desplegables de detalle de una fila
   * @param action.button Si la búsqueda es desde el botón del filtro, o en caso falso desde ordenación o paginación
   */
  searchData(action) {
    this.showCollapse = [];
    if (action) {
      this.customTableService.emptyCheckBoxArray();
      this.page = 0;
      !action.initial && (this.initialCall = action.button);
    } else {
      this.initialCall = true;
    }
    if (this.initialCall) {
      this.filterTableService.searchFilterData(action, this.initialCall)
        .pipe(
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(
          (res: any) => {
            this.setResponse(res, action);
          }
        );
    }
  }

  private setResponse(res: any, action): void {
    if (res && res.content) {
      this.rows = res.content;
      if (action.button) {
        // si existe checkAll se resetea
        if (this.columns[0].action?.includes('CHECK')) {
          if (this.customTable) {
            this.customTable.checkAllValue = false;
            this.customTable.createChecksForm();
          }
          this.customTableService.setCheckAll(false);
        }
      }
      // para el paginado
      this.totalElements = res.totalElements;
    }
  }

  /**
   * @description Método para la descarga de archivos (soporta word y excel)
   */
  downloadFile(type?: string): void {
    this.filterTableService.downloadFile(type).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        const contentDisposition = res.headers.get('content-disposition');
        const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        this.utilsService.openBlob(res.body, filename);
      });
  }

  /**
   * @description Change pagepage
   */
  pageSelected(event: number): void {
    this.page = event;
    const newParams = { page: this.page, sort: this.order?.toString() };
    this.filterTableService.setParam(newParams);
    this.searchData(false);
  }

  /**
   * @description Change order
   */
  orderSelected(event) {
    const column = TableColumnsEnum[event.column];
    this.order = [column, event.direction];
    const newParams = { page: this.page, sort: this.order.toString() };
    this.filterTableService.setParam(newParams);
    this.searchData(false);
  }

  /**
   * @description Evento lanzado desde la columna de acción del componente de fila (row) del custom-table
   */
  rowActionEvent(event: any) {
    this.rowAction.emit(event);
  }

  /**
   * @description Método que abre el filtro cuando hay varios filtro agrupados por dropdowns
   * @param index numero de dropdown para abrir
   */
  openFilter(index: number) {
    this.open === index ?
      this.open = null
      :
      this.open = index;
  }

  /**
   * @description Método que se ejecuta al destruir el componente. Nos dessuscribimos a los observables.
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
