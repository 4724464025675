/* tslint:disable */
import { Injectable } from '@angular/core';
import { SilverBaseService } from '../silver-base-service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { SilverRequestBuilder } from '../silver-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ActionLogDto } from '../models/action-log-dto';

@Injectable({
  providedIn: 'root',
})
export class ActionLogService extends SilverBaseService {
  constructor(
    config: EnviromentService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation saveActionLog
   */
  static readonly SaveActionLogPath = '/action-log';

  /**
   * Save action log.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `saveActionLog()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveActionLog$Response(params: {
      body: ActionLogDto
  }): Observable<void> {

    const req = new SilverRequestBuilder(ActionLogService.SaveActionLogPath, 'post');
    if (params) {
      req.body(params.body, 'application/json');
    }

    const sendReq = req.build({
      responseType: 'text',
      accept: '*/*'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return (r as void);
      })
    );
  }

  /**
   * Save action log.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `saveActionLog$Response()` use instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveActionLog(params: {
      body: ActionLogDto
  }): Observable<void> {

    return this.saveActionLog$Response(params).pipe(
      map(r => r as void)
    );
  }

}
