<div class="d-flex mb-3 justify-content-between">
  <h1 class="m-0">{{ pageTitle | translate }}</h1>
  <!-- BOTÓN TÍTULO AÑADIR -->
  <ng-container *ngIf="button">
    <button *ngIf="button.primary" type="button" class="btn btn-lg" [disabled]="disableButton"
            [ngClass]="{' btn-primary': button.primary}" (click)="actionButton(button)"
            data-cy="btn-primary"
    >
      {{ button.primary.value | translate }}
    </button>
    <button *ngIf="button.secondary" type="button" class="btn btn-lg" [disabled]="disableButton"
            [ngClass]="{' btn-secondary': button.secondary}" (click)="actionButton(button)">
      {{ button.secondary.value | translate }}
    </button>
  </ng-container>
</div>
