import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalErrorComponent } from '@shared/components/modal-error/modal-error.component';
import { ModalConfirmationComponent } from '@shared/components/modal-confirmation/modal-confirmation.component';
import { Router } from '@angular/router';
import { ModalSuccessComponent } from '@shared/components/modal-success/modal-success.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private readonly modalService: NgbModal,
              private readonly router: Router) {
  }

  triggerErrorModal(errorTitle: string, errorMsg: string, callbackUrl?: string, callbackFuncion: () => any = null) {
    const modalErr = this.modalService.open(ModalErrorComponent, { centered: true, size: 'sm' });
    modalErr.componentInstance.errorTitle = errorTitle;
    modalErr.componentInstance.errMsg = errorMsg;
    modalErr.componentInstance.callbackUrl = callbackUrl;

    modalErr.result.then((res) => {
      if (callbackUrl) {
        this.router.navigate([callbackUrl]);
      }
      if (callbackFuncion) {
        callbackFuncion();
      }
    });
  }

  triggerInfoModal(modalTitle: string, modalText: string, callbackUrl?: string, callbackFuncion: () => any = null) {
    const modalErr = this.modalService.open(ModalSuccessComponent, { centered: true, size: 'sm' });
    modalErr.componentInstance.title = modalTitle;
    modalErr.componentInstance.text = modalText;
    modalErr.componentInstance.callbackUrl = callbackUrl;

    modalErr.result.then((res) => {
      if (callbackUrl) {
        this.router.navigate([callbackUrl]);
      }
      if (callbackFuncion) {
        callbackFuncion();
      }
    });
  }

  triggerInfoModalWithTemplate(modalTitle: string, modalComponent, callbackUrl?: string, callbackFuncion: () => any = null): void {
    const modalErr = this.modalService.open(modalComponent, { centered: true, size: 'sm' });
    modalErr.componentInstance.title = modalTitle;
    modalErr.componentInstance.callbackUrl = callbackUrl;

    modalErr.result.then((res) => {
      if (callbackUrl) {
        this.router.navigate([callbackUrl]);
      }
      if (callbackFuncion) {
        callbackFuncion();
      }
    });
  }

  triggerConfirmationModal(
    properties: {
      actionOK?: () => void;
      actionKO?: () => void;
      title?: string;
      desc?: string;
      textOK?: string;
      textKO?: string;
    }
  ) {
    if (!properties) {
      properties = {};
    }
    if (!properties.actionOK) {
      properties.actionOK = () => {
      };
    }
    if (!properties.actionKO) {
      properties.actionKO = () => {
      };
    }
    if (!properties.title) {
      properties.title = 'COMMON.CONFIRM.TITLE';
    }
    if (!properties.desc) {
      properties.desc = 'COMMON.CONFIRM.DESC';
    }
    if (!properties.textOK) {
      properties.textOK = 'COMMON.CONFIRM.OK';
    }
    if (!properties.textKO) {
      properties.textKO = 'COMMON.CONFIRM.KO';
    }

    const modalConfirm = this.modalService.open(ModalConfirmationComponent, { centered: true });
    modalConfirm.componentInstance.title = properties.title;
    modalConfirm.componentInstance.desc = properties.desc;
    modalConfirm.componentInstance.textOK = properties.textOK;
    modalConfirm.componentInstance.textKO = properties.textKO;

    modalConfirm.result.then((res) => {
      if (res === 'OK') {
        properties.actionOK();
      } else {
        properties.actionKO();
      }
    });

  }
}
