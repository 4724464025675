import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from '@app/core/services/cookie/cookie.service';
import { TagManagerService } from '@app/core/services/tag-manager/tag-manager.service';
import { AppUrls } from '@config/app-urls.config';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CookieConfigModalComponent } from './cookie-config-modal/cookie-config-modal.component';
import { CookieModalComponent } from './cookie-modal/cookie-modal.component';

@Injectable({
  providedIn: 'root'
})
export class CookiesAppModalService {
  destroy$: Subject<boolean> = new Subject();


  private expire = 731 // 24 meses de duración de las cookies

  public static keys = {
    setted: 'one_silver_coo',
    analyt: 'one_silver_coo_ana'
  };

  constructor(
    private readonly cookie: CookieService,
    public readonly modalService: NgbModal,
    private readonly router: Router,
    private tag: TagManagerService
  ) {
  }


  init() {
    if (!this.isConfigured()) {
      this.router.events.pipe(
        takeUntil(this.destroy$),
        filter((event) => {
          return event instanceof NavigationEnd;
        }),
        filter((event: NavigationEnd) => {
          this.modalService.dismissAll();
          return (event.urlAfterRedirects || event.url).indexOf('/' + AppUrls.AppLegals + '/') !== 0;
        })
      ).subscribe(() => {
        if (!this.isConfigured()) {
          this.openCookieModal();
        }
      });
    } else {
      this.cookieCompleted();
      this.haveAnalyt() ? this.enableAnalyt() : this.disableAnalyt();
    }
  }

  openCookieModal() {
    this.deleteCookiesGTM();
    const modalCookie = this.modalService.open(CookieModalComponent,
      { centered: true, size: 'sm', backdrop: 'static', keyboard: false });

    modalCookie.result.then((accepted) => {
      if (accepted) {
        if (accepted === 'policy') {
          this.router.navigate(['/', AppUrls.AppLegals, AppUrls.AppCookie]);
        } else if (accepted === 'config') {
          this.openCookieConfigModal();
        } else if (accepted === 'refused') {
          this.setBase();
          this.setAnalyt('false');
        } else {
          this.setAll();
          this.cookieCompleted();
        }
      } else {
        this.openCookieModal();
      }
    });
  }

  openCookieConfigModal() {
    const modalCookie = this.modalService.open(CookieConfigModalComponent, { centered: true, size: 'sm' });
    modalCookie.componentInstance.haveAnalyt = !TagManagerService.disableAnalyt;
    modalCookie.result.then((accepted) => {
      if (accepted === 'policy') {
        this.router.navigate(['/', AppUrls.AppLegals, AppUrls.AppCookie]);
      } else if (accepted) {
        this.setBase();
        this.setAnalyt(accepted.analyt === false ? 'false' : 'true');
      } else if (!accepted) {
        if (!this.isConfigured()) {
          this.openCookieModal();
        }
      }
    });
  }

  isConfigured() {
    return this.cookie.get(CookiesAppModalService.keys.setted) === 'true';
  }

  haveAnalyt() {
    return this.cookie.get(CookiesAppModalService.keys.analyt) !== 'false';
  }

  enableAnalyt() {
    TagManagerService.disableAnalyt = false;
    this.tag.updateDisableAnalyt(false);
  }

  disableAnalyt() {
    TagManagerService.disableAnalyt = true;
    this.tag.updateDisableAnalyt(true);
  }

  deleteCookiesGTM() {
    TagManagerService.disableAnalyt = true;
    this.cookie.delete('_ga');
    this.cookie.delete('_gid');
    this.cookie.delete('_gat_UA-63304610-8');
  }

  setAll() {
    this.setBase();
    this.setAnalyt();
  }

  setBase() {
    this.cookie.set(CookiesAppModalService.keys.setted, 'true', this.expire);
  }

  setAnalyt(value = 'true') {
    this.cookie.set(CookiesAppModalService.keys.analyt, value, this.expire);
    if (value === 'false') {
      this.disableAnalyt();
    } else {
      this.enableAnalyt();
    }
  }

  cookieCompleted() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
