import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SuccessService } from '@core/services/success/success.service';
import { Router } from '@angular/router';
import { BreadcrumbService } from '@core/services/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-ok-msg',
  templateUrl: './ok-msg.component.html',
  styleUrls: ['./ok-msg.component.scss']
})
export class OkMsgComponent implements OnInit {

  @Input() text = '';
  @Input() buttonName = '';
  @Output() goTo: EventEmitter<string> = new EventEmitter();
  breadcrumb = null;
  pageTitle: string = '';
 

  constructor(private breadcrumbService: BreadcrumbService,
              private successService: SuccessService,
              private router: Router) {
  }

  ngOnInit() {
    if (!this.text) {
      this.breadcrumb = this.breadcrumbService.breadcrumbs;
      this.pageTitle = this.successService.pageTitle;
      this.text = this.successService.textToShow;
    }

    if (!this.buttonName) {
      this.buttonName = this.successService.buttonName;
    }
  }

  navigate(element) {

    if (this.successService.callbackUrl) {
      this.router.navigate([this.successService.callbackUrl], { replaceUrl: true });
    } else {
      this.goTo.emit(element);
    }
  }

}
