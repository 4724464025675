import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'silver-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss']
})
export class ModalErrorComponent{
  @Input() errMsg: string;
  @Input() errorTitle: string;
  @Input() callbackUrl: string;

  constructor(public activeModal: NgbActiveModal) {
  }
}
