import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { HeaderService } from '@shell/header/header.service';
import { TranslationsService } from '@core/services/translations/translations.service';

@Component({
  selector: 'silver-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss']
})
export class PublicHeaderComponent implements OnDestroy {
  @Output() hideMenu = new EventEmitter();
  openMenu = false;
  availableLanguages: any[] = [
    {
      id: 1,
      name: 'Español',
      hrLanguages: null,
      isDefault: true,
      cmsLanguageCode: 'es_ES',
      hrLanguage: 'es'
    },
    {
      id: 2,
      name: 'English',
      hrLanguages: null,
      isDefault: false,
      cmsLanguageCode: 'en_GB',
      hrLanguage: 'en'
    }
  ];
  showLanguagesDropdown: boolean = false;
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    public headerService: HeaderService,
    private translationsService: TranslationsService
  ) {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openTooltipLanguage() {
    this.showLanguagesDropdown = !this.showLanguagesDropdown;
  }

  setLanguage(lang: string, cmsLanguageCode: string) {
    this.translationsService.setLanguage(lang, cmsLanguageCode);
    const languageSelected = this.availableLanguages.find(lang => lang.cmsLanguageCode === cmsLanguageCode);
    this.availableLanguages.forEach(lang => lang.isDefault = false);
    languageSelected.isDefault = true;
    this.showLanguagesDropdown = false;
  }
}
