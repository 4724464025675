/* tslint:disable */
import { Injectable } from '@angular/core';
import { SilverBaseService } from '../silver-base-service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { SilverRequestBuilder } from '../silver-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AttachmentDto } from '../models/attachment-dto';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService extends SilverBaseService {
  constructor(
    config: EnviromentService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createAttachment
   */
  static readonly CreateAttachmentPath = '/attachments/{ticket_id}';

  /**
   * createAttachment.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `createAttachment()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createAttachment$Response(params: {

    /**
     * The ticket identifier
     */
    ticket_id: string;
      body: { 'attachment'?: Blob }
  }): Observable<AttachmentDto> {

    const req = new SilverRequestBuilder(AttachmentsService.CreateAttachmentPath, 'post');
    if (params) {
      req.path('ticket_id', params.ticket_id, {});
      req.body(params.body, 'multipart/form-data');
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as AttachmentDto;
      })
    );
  }

  /**
   * createAttachment.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `createAttachment$Response()` use instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createAttachment(params: {

    /**
     * The ticket identifier
     */
    ticket_id: string;
      body: { 'attachment'?: Blob }
  }): Observable<AttachmentDto> {

    return this.createAttachment$Response(params).pipe(
      map(r => r as AttachmentDto)
    );
  }

  /**
   * Path part for operation getCaseAttachmentUsingGet
   */
  static readonly GetCaseAttachmentUsingGetPath = '/attachments/{attachment_id}';

  /**
   * getCaseAttachment.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getCaseAttachmentUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseAttachmentUsingGet$Response(params: {

    /**
     * Attachmente id
     */
    attachment_id: string;

  }): Observable<string> {

    const req = new SilverRequestBuilder(AttachmentsService.GetCaseAttachmentUsingGetPath, 'get');
    if (params) {
      req.path('attachment_id', params.attachment_id, {});
    }

    const sendReq = req.build({
      responseType: 'blob',
      accept: 'application/*'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as string;
      })
    );
  }

  /**
   * getCaseAttachment.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getCaseAttachmentUsingGet$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getCaseAttachmentUsingGet(params: {

    /**
     * Attachmente id
     */
    attachment_id: string;

  }): Observable<string> {

    return this.getCaseAttachmentUsingGet$Response(params).pipe(
      map(r => r as string)
    );
  }

  /**
   * Path part for operation deleteAttachment
   */
  static readonly DeleteAttachmentPath = '/attachments/{attachment_id}';

  /**
   * deleteAttachment.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `deleteAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAttachment$Response(params: {

    /**
     * The attachment identifier
     */
    attachment_id: string;

  }): Observable<AttachmentDto> {

    const req = new SilverRequestBuilder(AttachmentsService.DeleteAttachmentPath, 'delete');
    if (params) {
      req.path('attachment_id', params.attachment_id, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as AttachmentDto;
      })
    );
  }

  /**
   * deleteAttachment.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `deleteAttachment$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAttachment(params: {

    /**
     * The attachment identifier
     */
    attachment_id: string;

  }): Observable<AttachmentDto> {

    return this.deleteAttachment$Response(params).pipe(
      map(r => r as AttachmentDto)
    );
  }

}
