import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { BoxTitleForm, BoxTitleFormTypesEnum } from "./box-title-form.model";
import { IfilterCtrl } from "../search-filter/search-filter.model";
import { UtilsService } from "@app/core/services/utils/utils.service";

/**
 * @export
 * @description Componente para la creación dinámica de campos con estructura dee filas y columnas
 * {BoxTitleForm} dataForm Datos para los controles creados dinámicamente
 * {boolean} allDisabled deshabilita todos los controles
 * {boolean} boxBorderForm Añade o quita el borde del contenedor del componente
 * {FormGroup} parentForm Formulario para encapsular los controles
 * {EventEmitter} search Emite el valor del input y el nombre del controlador en los inputs de búsqueda
 * {EventEmitter} valueSelected Emite el valor seleccionado en los inputs con desplegable
 * {BoxTitleFormStateEnum} boxBoxTitleFormStateEnum Enum de los estados de un control de formulario
 */
@Component({
  selector: "esda-box-title-form",
  templateUrl: "./box-title-form.component.html",
  styleUrls: ["./box-title-form.component.scss"],
})
export class BoxTitleFormComponent implements OnInit, OnChanges {
  @Input() dataForm: BoxTitleForm;
  @Input() allDisabled = false;
  @Input() boxBorderForm = true;
  @Input() parentForm: FormGroup = null;
  @Output() search: EventEmitter<any> = new EventEmitter();
  @Output() valueSelected: EventEmitter<any> = new EventEmitter();
  @Output() fileSelected: EventEmitter<any> = new EventEmitter();
  @Output() fileDeleted: EventEmitter<any> = new EventEmitter();
  @Output() goLink: EventEmitter<string> = new EventEmitter();

  readonly BoxTitleFormTypesEnum = BoxTitleFormTypesEnum;

  maxLength: number = 200;
  fileAttach: any;
  multipleFiles: boolean = true;

  constructor(private utilsService: UtilsService, public fb: FormBuilder) {}

  /**
   * @description Controla si vienen los datos antes de iniciar el componente y el formulario para crear su estructura
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.parentForm &&
      changes.parentForm.firstChange &&
      changes.dataForm
    ) {
      this.createForm();
    }
  }

  /**
   * @description Inicia el componente y si no existe la estructura del frmulario la crea
   */
  ngOnInit(): void {
    if (Object.keys(this.parentForm.controls).length !== 0) {
      this.createForm();
    }
  }

  /**
   * @description Crea la estructura de controles del formulario
   */
  createForm() {
    this.dataForm.elements.forEach((row: IfilterCtrl[]) => {
      row.forEach((element: IfilterCtrl) => {
        // tslint:disable-next-line: max-line-length
        if (element.type !== BoxTitleFormTypesEnum.SUB_TITLE) {
          this.setBoxTitleForm(element);
        }
      });
    });
  }

  openFile(event) {
    this.utilsService.openFile(event.value, event.filename, event.filetype);
  }

  deleteFile(event) {
    this.fileDeleted.emit(event);
  }

  setFiles(event) {
    this.fileAttach = event;
    this.fileSelected.emit(event);
  }

  private setBoxTitleForm(element: IfilterCtrl): void {
    const checkValueType =
      typeof element.value === "object" &&
      element.value !== null &&
      element.value !== undefined;

    if (element.controlName) {
      this.parentForm.addControl(
        element.controlName,
        new FormControl(
          checkValueType ? element.value.value : element.value,
          []
        )
      );
    }

    if (element.disableInput || this.allDisabled) {
      this.parentForm.controls[element.controlName].disable();
    } else {
      this.parentForm.controls[element.controlName].enable();
    }

    if (element.required) {
      const validatorsArray: ValidatorFn[] = [
        Validators.required,
        Validators.minLength(element.minLength),
      ];
      this.parentForm.controls[element.controlName].setValidators(
        Validators.compose(validatorsArray)
      );
    }
  }

  searchInput(event: string, name: string) {
    this.search.emit({ value: event, service: name });
  }

  setValue(event, fromType?: string) {
    if (fromType && (fromType === "text" || fromType === "textArea")) {
      event.value = this.parentForm.controls[event.controlName].value;
    }
    this.valueSelected.emit(event);
  }

  preventIllegalChars(event: any): void {
    const pattern = /[0-9]/;

    if (!pattern.test(event.data)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    }
  }

  goToLink(name: string) {
    this.goLink.emit(name);
  }
}
