<div class="menu-bg" (click)="hideMenu()">
</div>
<div class="menu col-12 fade-in">
  <div class="row menu-box width-control">

    <div class="section col-2 d-flex flex-column" *ngFor="let section of sectionList">
      <a class="menu-title mb-2"(click)="goToLink(section.parent)">{{section.parent.descrItem}}</a>
      <ul>
        <ng-container *ngFor="let subsection of section.children; let i = index;">
          <li class="d-flex" *ngIf="subsection.accessType === 'INF1'">
            <div class="icn-accede-info-level-1 pr-1"></div>
            <a (click)="goToLink(subsection)">{{subsection.descrItem}}</a>
          </li>
          <li *ngIf="subsection.accessType === 'PRC1'" [ngClass]="{'collapse': i > section.limitShow}">
            <a (click)="goToLink(subsection)">{{subsection.descrItem}}</a>
          </li>
          <li class="options" (click)="desplegar(section)" *ngIf="i === section.limitShow && i < section.children.length - 1" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
            <a>{{'MASOPCIONES' | translate}}</a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
