import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CompAccess } from '@app/core/services/routeDinamyc/mbox.model';
@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss']
})
export class BoxComponent implements OnInit {
  url: string;
  @Input() role;

  constructor(private router: Router) {}

  public goToPath(item: CompAccess) {
    // La prioridad de navegcion de la caja seria
    // 1º el linkItem
    // 2º Si tiene url externa
    // 3º Si tiene salto de navegación
    // 4º Si enlaza a un Documento de Liferay
    if (item.linkItem !== '') {
      this.router.navigate([item.linkItem]);
    } else if (item.urlId !== '') {
      window.open(item.urlId, '_blank');
    } else if (item.sNavega !== '') {
      this.router.navigate([item.sNavega]);
    }

  }
  ngOnInit() {
  }
}
