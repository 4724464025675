/* tslint:disable */
import { Injectable } from '@angular/core';
import { SilverBaseService } from '../silver-base-service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { SilverRequestBuilder } from '../silver-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { VersionInfo } from '../models/version-info';


/**
 * Version
 */
@Injectable({
  providedIn: 'root',
})
export class VersionService extends SilverBaseService {
  constructor(
    config: EnviromentService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation versionInfoGet
   */
  static readonly VersionInfoGetPath = '/version/info';

  /**
   * Get version info.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `versionInfoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionInfoGet$Response(params?: {

  }): Observable<VersionInfo> {

    const req = new SilverRequestBuilder(VersionService.VersionInfoGetPath, 'get');
    if (params) {
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as VersionInfo;
      })
    );
  }

  /**
   * Get version info.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `versionInfoGet$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  versionInfoGet(params?: {

  }): Observable<VersionInfo> {

    return this.versionInfoGet$Response(params).pipe(
      map(r => r as VersionInfo)
    );
  }

}
