<ng-container [formGroup]="parentForm">

  <button type="button" class="btn btn-secondary btn-add btn-sm">
    {{ btnText | translate}}
    <input
      type="file"
      id="files"
      name="files"
      accept="{{typesFiles}}"
      #fileInput
      (change)="onFileChange($event)"
    />
  </button>
  <div class="add-doc-list mb-3">
    <!-- ADD-DOC-ITEM -->
    <div class="add-doc-item" *ngFor="let file of arrayFiles?.value; let i = index">    
      <i [ngClass]="'icn-pdf'"></i>
      <span class="file-name" (click)="showDoc(file)">{{file.name}}</span>
      <i *ngIf="delete" class="icn-trash" (click)="deleteDoc(file)"></i>       
    </div>
    <span class="error" *ngIf="error">
      <i class="icn-alert"></i>{{ error | translate }}</span>
  </div>
</ng-container>