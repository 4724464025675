<ngb-carousel>
  <ng-template ngbSlide *ngFor="let slide of carousel" [ngSwitch]="true">
    <div class="picsum-img-wrapper" *ngSwitchCase="slide.mainTemplate === 'DVD'" >      
      <img *ngIf="slide.webcontent" [src]="'./assets/img/' + slide.webcontent?.text" />
      <div class="carousel-video">
        <i class="icn-videotutoriales-level-1" (click)="openModal(content,slide)"></i>
      </div>
    </div>
    <div class="picsum-img-wrapper" *ngSwitchCase="mainTemplates.includes(slide.mainTemplate)">
      <a (click)="goTo(slide)"><img *ngIf="slide.webcontent" [src]="'./assets/img/' + slide.webcontent?.text" alt=""/></a>
    </div>
  </ng-template>
</ngb-carousel>

<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="embed-responsive embed-responsive-16by9">
      <iframe width="100%" height="369px" [title]="title" class="embed-responsive-item"
        [src]="urlVideo | safe: 'resourceUrl'" allow="encrypted-media; fullscreen;">
      </iframe>
    </div>
  </div>
</ng-template>