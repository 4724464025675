<ng-container *ngIf="isPublicMode !== null">
  <onehr-header *ngIf="!isPublicMode; else publicHeader"></onehr-header>
  <ng-template #publicHeader>
    <silver-public-header></silver-public-header>
  </ng-template>
</ng-container>

<div [ngClass]="{'container py-4': !isPublicMode, 'full-flex': isPublicMode}">
  <router-outlet></router-outlet>
</div>
<esda-loader></esda-loader>
<!-- Only declare router-outlet, loader and (optionally, but recommended) header and footer (even navbar) here -->
<!-- Elements declared here will exist across the whole application -->

<ng-container *ngIf="isPublicMode !== null">
  <app-footer *ngIf="!isPublicMode; else publicFooter"></app-footer>
  <ng-template #publicFooter>
    <silver-public-footer></silver-public-footer>
  </ng-template>
</ng-container>
