import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StepsServiceModule } from '@shared/components/step/steps-service.module';
import { StepModel } from '@shared/components/step/step.model';

@Injectable({
  providedIn: StepsServiceModule
})
export class StepsService {
  steps = [];
  steps$: BehaviorSubject<StepModel[]> = new BehaviorSubject<StepModel[]>(null);
  currentStep$: BehaviorSubject<StepModel> = new BehaviorSubject<StepModel>({
    stepIndex: 0,
    isComplete: false
  });

  initStepService(steps: StepModel[]) {
    this.steps = steps;
    this.steps$.next(steps);
    this.currentStep$.next(this.steps$.getValue()[0]);
  }

  getCurrentStep$(): Observable<StepModel> {
    return this.currentStep$.asObservable();
  }

  getSteps(): Observable<StepModel[]> {
    return this.steps$.asObservable();
  }

  moveToNextStep(): void {
    const index = this.currentStep$.value.stepIndex;

    if (index < this.steps$.value.length) {
      this.currentStep$.next(this.steps$.getValue()[index + 1]);
    }
  }

  isLastStep(): boolean {
    return this.currentStep$.value.stepIndex === this.steps$.value.length;
  }

  getCurrentStepIndex(): number {
    return this.currentStep$.getValue().stepIndex;
  }

  completeCurrentStep(): void {
    const index = this.currentStep$.value.stepIndex;
    const steps = this.steps$.getValue();

    steps[index].isComplete = true;
    this.steps$.next(steps);
  }
}
