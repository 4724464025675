<th class="d-flex align-items-start justify-content-start" *ngIf="pageType === FilterTableConfigEnum.SCROLL_REQUEST || pageType === FilterTableConfigEnum.GROUP_FILTER">
  <ng-container [formGroup]="parentForm">
      <div class="action-item d-flex flex-row align-items-center">
        <i class="icn-edit" (click)="rowEdit($event)"></i>
        <label class="regulation-check" *ngIf="pageType === FilterTableConfigEnum.SCROLL_REQUEST || pageType === FilterTableConfigEnum.GROUP_FILTER && row?.status === 'SUPERVISOR'">
          <input [formControlName]="nameCtrl" [id]="nameCtrl" type="checkbox" [name]="nameCtrl" (change)="changeCheckbox($event)">
          <span class="checkmark"></span>
      </label>
      </div>
  </ng-container>
</th>
<td class="empty-column-action" *ngIf="pageType === FilterTableConfigEnum.SCROLL_REQUEST || pageType === FilterTableConfigEnum.GROUP_FILTER">
</td>
<td>
<p class="d-flex align-items-center">
  {{ requests.employeeId | translate }}
  <app-tooltip *ngIf="tooltipEmployees.length" [content]="tooltipEmployees"  [icon]="'icn-plus-user'"></app-tooltip>
</p>
</td>
<td>
<p class="d-flex align-items-center">
  {{ requests.employee | translate}}
  <app-tooltip *ngIf="tooltipEmployees.length" [content]="tooltipEmployees"  [icon]="'icn-plus-user'"></app-tooltip>
</p>
</td>
<td>
<p class="d-flex align-items-center">
  {{ requests.companyEmployee | translate}}
  <app-tooltip *ngIf="tooltipEmployees.length" [content]="tooltipEmployees"  [icon]="'icn-plus-user'"></app-tooltip>
</p>
</td>
<td>
<p class="d-flex align-items-center">
  {{ requests.company | translate}}
  <app-tooltip *ngIf="tooltipPowers.length" [content]="tooltipPowers"  [icon]="'icn-plus-user'"></app-tooltip>
</p>
</td>
<td>
<p class="d-flex align-items-center">
  {{ requests.group | translate}}
  <app-tooltip *ngIf="tooltipPowers.length" [content]="tooltipPowers"  [icon]="'icn-plus-user'"></app-tooltip>
</p>
</td>
<td>
<p class="d-flex align-items-center">
  {{ requests.power | translate}}
  <app-tooltip *ngIf="tooltipPowers.length" [content]="tooltipPowers"  [icon]="'icn-plus-user'"></app-tooltip>
</p>
</td>
<td>
{{ row?.requestType | translate }}
</td>
<td class="date">{{ row?.date | date: 'dd/MM/yyyy' }}</td>
<td class="status">
  <!-- STATUS OK -->
  <div *ngIf="!row?.status.includes('CANCELED') && !row?.status.includes('REJECTED') else statusKO"
  class="status-ok">
    <i class="icn-ok"></i>
    <span>{{ row?.status | translate }}</span>
  </div>
</td>

<!-- STATUS KO -->
<ng-template #statusKO>
  <div class="status-ko">
    <i class="icn-denied"></i>
    <span>{{ row?.status | translate }}</span>
  </div>
</ng-template>

