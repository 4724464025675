import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  errParams: string = '';
  private err$ = new BehaviorSubject<CustomError>(null);

  setErrors(message: string, url: string, errorAdditionalParams?: string) {
    const error = new CustomError();
    error.url = url;
    error.message = message;
    this.err$.next(error);

    if (errorAdditionalParams) {
      this.errParams = errorAdditionalParams;
    }
  }

  getError() {
    return this.err$;
  }

  resetError() {
    this.errParams = '';
    return this.err$.next(null);
  }
}

export class CustomError {
  status: string | number;
  message: string;
  buttonName?: string;
  url?: string;

  constructor() {
    this.message = '';
    this.status = '';
  }
}

