import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent implements OnInit {

  @Input() label: string;
  @Input() placeholder = '';
  @Input() controlName: string;
  @Input() minLength: number;
  @Input() required: boolean;
  @Input() value: string;
  @Input() disabled: boolean;
  @Input() parentForm: FormGroup;
  @Output() search = new EventEmitter();
  @Output() setValue = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.parentForm.addControl(
      this.controlName,
      new FormControl({value: this.value, disabled: this.disabled}, this.required ? Validators.required : undefined)
    );
  }

  public inputChanges(control: string) {
    const inputValue: string =  this.parentForm.get(control).value;
    this.search.emit({controlName: control, item: { value: inputValue, id: inputValue } });
  }

}
