<ng-container [formGroup]="parentForm">
    <div class="col mt-3">
        <div class="form-group">
            <label class="regulation-check">
                <input type="checkbox"
                       [formControlName]="controlName"
                       [checked]="checked"
                       (change)="changeStatus($event,controlName)">
                <span class="checkmark"></span>
                {{ label | translate}}
            </label>
        </div>
    </div>
</ng-container>
