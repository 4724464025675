import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { StepModel } from '@shared/components/step/step.model';
import { StepsService } from '@shared/components/step/steps.service';

@Component({
  selector: 'silver-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements OnInit {
  steps: Observable<StepModel[]>;
  currentStep: Observable<StepModel>;

  constructor(private stepsService: StepsService) {
  }

  ngOnInit(): void {
    this.steps = this.stepsService.getSteps();
    this.currentStep = this.stepsService.getCurrentStep$();
  }
}
