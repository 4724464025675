import { BoxTitleForm } from '@shared/components/box-title-form/box-title-form.model';
import { FormGroup } from '@angular/forms';
import { Actions } from '@shared/components/list-item/list-item.enum';

export interface ListBox {
  id?: number | string;
  box: Box[];
  extraData?: any;
  actions?: Action[];
  isEditMode?: boolean;
  translations?: Translation[];
}

export interface Box {
  id?: number | string | string[] | number[];
  slug?: BoxSlug | string;
  label: string;
  value: string[] | number[];
  url?: string[];
  bootstrapClass?: string;
  isHidden?: boolean;
  class?: string;
  createdBy?:string;
}

export interface Action {
  action: Actions;
  icon: string;
  title: string;
  onActionExecute?: (position?: number) => void;
}

export interface Translation {
  id: number | undefined;
  label: string;
  value: string;
}

export enum BoxSlug {
  pilot,
  startDate,
  date,
  comment,
  document
}

export interface ListConfig {
  itemName: string;
  itemList: any;
  itemFormData: BoxTitleForm | BoxTitleForm [];
  itemFormGroup: FormGroup;
  newItemEvent?: Function;
  saveItemEvent?: Function;
  editItemEvent?: Function;
  deleteItemEvent?: Function;
  selectItemEvent?: Function;
  childList?: ListConfig;
}
