import { Component, OnInit } from '@angular/core';
import { AppUrls } from '@config/app-urls.config';

@Component({
  selector: 'silver-public-footer',
  templateUrl: './public-footer.component.html',
  styleUrls: ['./public-footer.component.scss']
})
export class PublicFooterComponent implements OnInit {
  urls = AppUrls;
  constructor() { }

  ngOnInit(): void {
  }

}
