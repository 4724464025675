import { NgModule } from '@angular/core';
import { AppVersionComponent } from './app-version/app-version.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    AppVersionComponent
  ],
  imports: [
    SharedModule
  ]
})
export class AppVersionModule {
}
