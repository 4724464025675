// Angular locale
import { registerLocaleData } from '@angular/common';
import localeEN from '@angular/common/locales/en';
import localeES from '@angular/common/locales/es';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';


/**
 * Translation service that uses ngx-translate
 * It relies on sessionStorage so as to provide consistent language experience
 * (pending on defining if it should be session-based, and use sessionStorage instead)
 */
@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  langSelected: string;
  private langDefault = 'es';
  private langDefaultContId = 'es_ES';
  private langStore = 'language';
  private langStoreContId = 'languageContId';

  /**
   * Sets default language
   * It first considers the language set on environment configs, and goes for default if none is found
   * It also sets the Angular Locale for diverse Angular pipes
   * Right now it only considers 'es' and 'en' languages
   */
  constructor(private readonly translateService: TranslateService) {
    this.translateService.setDefaultLang(sessionStorage[this.langStore]);
  }

  private _currentLanguage: BehaviorSubject<string> = new BehaviorSubject<string>('');

  get currentLanguage(): Observable<string> {
    return this._currentLanguage.asObservable();
  }

  /**
   * Returns selected language
   */
  getLanguageSelected(): string {
    return this.langSelected;
  }

  /**
   * Initializes a language. It checks if it has been already set
   * and initializes default language otherwise
   */
  init(): string {
    if (this.langSelected) {
      return this.langSelected;
    }
    const lang = sessionStorage && sessionStorage[this.langStore] ? sessionStorage[this.langStore] : this.langDefault;
    const langCode = sessionStorage && sessionStorage[this.langStoreContId] ? sessionStorage[this.langStoreContId] : this.langDefaultContId;

    this.setLanguage(lang, langCode);
    return this.langSelected;
  }

  /**
   * Returns instant translation
   * @param key: string
   */
  instant(key: string) {
    return this.translateService.instant(key);
  }

  /**
   * Sets language considering "lang" param
   * @param lang: string
   * @param cmsLanguageCode
   */
  setLanguage(lang: string, cmsLanguageCode: string) {
    if (sessionStorage) {
      sessionStorage[this.langStore] = lang;
      sessionStorage[this.langStoreContId] = cmsLanguageCode;
    }
    this.langSelected = lang;
    this.translateService.use(lang);
    this.translateService.setDefaultLang(lang);
    registerLocaleData(this.langSelected === this.langDefault ? localeES : localeEN, this.langSelected);
    this._currentLanguage.next(lang);
  }

  getCurrentLanguage(): string {
    switch (this.langSelected) {
      case 'es':
        return 'es_ES';
      case 'en':
        return 'en_GB';
      default:
        return 'es_ES';
    }
  }
}
