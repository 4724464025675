import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

type SafeType =  SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl;
/**
 * @export
 * @class SafePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  /**
   * Creates an instance of SafePipe.
   * @param {DomSanitizer} sanitizer
   * @memberof SafePipe
   */
  constructor(protected sanitizer: DomSanitizer) {}

  /**
   * @param {*} value
   * @param {string} type
   * @returns {(SafeType | void)}
   * @memberof SafePipe
   */
  transform(value: any, type: string): SafeType | void {
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    }
  }
}
