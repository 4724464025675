import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '@shell/loader/services/loader.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { AppUrls } from '@config/app-urls.config';

export interface LoaderOptions {
  showLoader?: boolean;
  delay?: number;
}

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private interceptorConfig: LoaderOptions;
  private readonly requests: HttpRequest<any>[] = [];
  private delay: number;
  private enable = true;

  constructor(
    private readonly loaderService: LoaderService,
    // private readonly logger: CustomLoggerService,
    private readonly utils: UtilsService
  ) {
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.delay ?
      setTimeout(() => {
        this.loaderService.isLoading$.next(this.requests.length > 0);
      }, this.delay) :
      this.loaderService.isLoading$.next(this.requests.length > 0);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const isConfigEndpoint = request.url.indexOf(AppUrls.Config) !== -1;
    if (!isConfigEndpoint) {
      const requestEndpoint = this.utils.matchRequestEndpoint(request);
      this.interceptorConfig = this.utils.getInterceptorConfig(requestEndpoint, 'Loader');
    }

    if (typeof this.interceptorConfig !== 'undefined') {
      this.enable = this.interceptorConfig.showLoader;
      this.delay = this.interceptorConfig.delay;
    }

    if (this.enable) {
      this.requests.push(request);
      this.loaderService.isLoading$.next(true);
      return new Observable(observer => {
        const subscription = next.handle(request)
          .subscribe(
            event => {
              if (event instanceof HttpResponse) {
                this.removeRequest(request);
                observer.next(event);
              }
            },
            err => {
              this.removeRequest(request);
              observer.error(err);
            },
            () => {
              this.removeRequest(request);
              observer.complete();
            });
        return () => {
          this.removeRequest(request);
          subscription.unsubscribe();
        };
      });
    } else {
      return next.handle(request);
    }
  }
}
