import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { Order } from './custom-table.model';

@Injectable({
  providedIn: 'root'
})
export class CustomTableService {
  // Datos almacenados por el servicio
  private readonly cache = {
    CHECKBOX_ARRAY: [],
    CHECK_ALL: false,
    ORDERS_ARRAY: []
  };

  /**
   * @description BehaviorSubject del estado del checkAll. Observable para suscribirse al estado del checkAll
   * @private
   */
  private readonly hasCheckAllBS = new BehaviorSubject<boolean>(this.cache.CHECK_ALL);
  private hasCheckAllObs = this.hasCheckAllBS.asObservable();

  /**
   * @description BehaviorSubject de los checkbox seleccionados. Observable para suscribirse al array de checkbox seleccionados
   * @private
   */
  private readonly hasCheckDataBS = new BehaviorSubject<Array<any>>(this.cache.CHECKBOX_ARRAY);
  private hasCheckDataObs = this.hasCheckDataBS.asObservable();

  /**
   * @description BehaviorSubject del tipo de orden y columna seleccionados.
   * Observable para suscribirse al tipo de orden y columna seleccionados
   * @private
   */
  private readonly hasOrderBS = new BehaviorSubject<Array<Order>>(this.cache.ORDERS_ARRAY);
  private hasOrderObs = this.hasOrderBS.asObservable();

  /*********************** CHECKBOX COLUMNA ACTIONS *************************************/
  /**
   * @description Método para guardar el checkbox seleccionado en una fila de la tabla
   * @param {PageTypeEnum} pageType
   * @param {*} itemSelected
   * @param {boolean} [checkAll]
   */
  setCheckboxArray(itemSelected: any, checkAll?: boolean): void {
    const indexItem = this.getExistItem(itemSelected);
    if ((itemSelected.checked || this.cache.CHECK_ALL) && indexItem === -1) {
      this.cache.CHECKBOX_ARRAY.push(itemSelected);
      this.hasCheckDataBS.next(this.cache.CHECKBOX_ARRAY);
    } else if (!itemSelected.checked && typeof indexItem === 'number' && indexItem !== -1) {
      this.cache.CHECKBOX_ARRAY.splice(indexItem, 1);
      this.hasCheckDataBS.next(this.cache.CHECKBOX_ARRAY);
    }
  }

  /**
   * @description Método que averigua si existe el checkbox en el array
   * @param {*} elementoToCompare
   * @returns
   * @memberof FilterTableService
   */
  getExistItem(elementoToCompare: any) {
    if (this.cache.CHECKBOX_ARRAY && this.cache.CHECKBOX_ARRAY.length > 0) {
      return this.cache.CHECKBOX_ARRAY.findIndex((element: any) => {
        // TODO: específico de poderes. Introducir la condición necesaria para saber si ya existe el row seleccionado
        return element.requestId === elementoToCompare.requestId;
      });
    } else {
      return -1;
    }
  }

  /**
   * @description Método que devuleve el array de checkbox seleccionados
   * @returns
   */
  getCheckboxArray() {
    return this.cache.CHECKBOX_ARRAY;
  }

  /**
   * @description Método que devuelve evalúa si hay elementos en el checkboxArray (checkbox seleccionados), para consumo async en vista
   * @param {*} [customValidation] validador custom para evaluar el checkboxArray (checkbox seleccionados)
   * @returns
   */
  getCheckArrayObs(customValidation?) {
    return this.hasCheckDataObs.pipe(
      debounceTime(100),
      map((val: any) => {
        let validateData = val.length > 0;
        if (validateData) {
          if (typeof customValidation === 'function') {
            validateData = customValidation(this.cache.CHECKBOX_ARRAY);
          }
        }
        return validateData;
      })
    );
  }

  /**
   * @description Método para vaciar el array de checkbox seleccionados
   */
  emptyCheckBoxArray() {
    this.cache.CHECKBOX_ARRAY = [];
    this.hasCheckDataBS.next(this.cache.CHECKBOX_ARRAY);
  }

  /*********************** FIN CHECKBOX COLUMNA ACTIONS *************************************/

  /*********************** CHECKBOX_ALL COLUMNA ACTIONS *************************************/
  /**
   * @description Método que setea el checkAll
   * @param {*} value
   */
  setCheckAll(value: any) {
    this.cache.CHECK_ALL = value;
    this.hasCheckAllBS.next(this.cache.CHECK_ALL);
  }

  /**
   * @description Método que devuelve el estado del checkAll
   * @returns
   */
  getCheckAll() {
    return this.cache.CHECK_ALL;
  }

  /**
   * @description Método que devuelve el estado del checkAll a través de un observable para consumo async en vista
   * @returns
   */
  getCheckAllObs() {
    return this.hasCheckAllObs;
  }

  /*********************** FIN CHECKBOX_ALL COLUMNA ACTIONS *************************************/

  /**
   * @description Método para borrar el array de ordenes seleccionados en la tabla
   */
  removeOrdersArray() {
    this.cache.ORDERS_ARRAY = [];
    this.hasOrderBS.next(this.cache.ORDERS_ARRAY);
  }

  /**
   * @description Método para borrar todos los datos de la caché del servicio
   */
  removeAllCacheData(): void {
    Object.keys(this.cache).forEach((elementRef: string) => {
      if (Array.isArray(this.cache[elementRef])) {
        this.cache[elementRef] = [];
      } else {
        this.cache[elementRef] = null;
      }
    });
  }

  /**
   * @description Método para borrar todos los datos del servicio
   */
  removeAllServiceData() {
    this.removeAllCacheData();
    this.hasOrderBS.next(this.cache.ORDERS_ARRAY);
    this.hasCheckDataBS.next(this.cache.CHECKBOX_ARRAY);
    this.hasCheckAllBS.next(this.cache.CHECK_ALL);
  }
}
