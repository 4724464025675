import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '@core/services/login/login.service';
import { AppUrls } from '@config/app-urls.config';
import { map } from 'rxjs/operators';
import { StorageService } from '@core/services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate, CanLoad {
  constructor(private readonly loginService: LoginService,
              private readonly storageService: StorageService,
              private readonly router: Router) {
  }

  checkResolution(callb: () => boolean | UrlTree) {
    return this.loginService.loginStatus
      .pipe(
        map(isLogged => {
          if (isLogged) {
            return true;
          } else {
            return callb();
          }
        }));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkResolution(() => {
      this.storageService.set('authUser', false);
      return this.router.createUrlTree([AppUrls.AppPublicLogin, AppUrls.AppLogin]);
    });
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkResolution(() => {
      this.storageService.set('authUser', false);
      return this.router.createUrlTree([AppUrls.AppPublicLogin, AppUrls.AppLogin]);
    });
  }
}
