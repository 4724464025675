<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ 'LEGALS.COOKIE.MODAL.TITLE' | translate }}</h4>
</div>
<div class="modal-body">
  <p> {{ 'LEGALS.COOKIE.MODAL.TEXT' | translate }}
    <span class="link-txt" (click)="activeModal.close('policy')">{{ 'LEGALS.COOKIE.MODAL.LINK' | translate }}</span>
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary"
          (click)="activeModal.close(true)">{{ 'LEGALS.COOKIE.MODAL.BUTTON_ACCEPTALL' | translate }}</button>
  <button type="button" class="btn btn-primary"
          (click)="activeModal.close('config')">{{ 'LEGALS.COOKIE.MODAL.BUTTON_CONFIG' | translate }}</button>
  <button type="button" class="btn btn-primary"
          (click)="activeModal.close('refused')">{{ 'LEGALS.COOKIE.MODAL.BUTTON_REFUSEALL' | translate }}</button>
</div>
