<app-breadcrumb *ngIf="breadcrumb" [breadcrumbInput]="breadcrumb"></app-breadcrumb>
<app-page-title *ngIf="pageTitle !== ''" [title]="pageTitle | translate"></app-page-title>

<div class="container">
  <div class="msg-container">
    <i class="icn-ok"></i>
    <p class="mt-4 mb-5" [innerHTML]="text | translate"></p>
    <div>
      <button type="button" class="btn btn-primary btn-lg" (click)="navigate('button')">{{buttonName | translate}}</button>
    </div>
  </div>
</div>
