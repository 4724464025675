import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SuccessService {
  textToShow: string = '';
  buttonName: string = '';
  callbackUrl: string = '';
  pageTitle: string = '';
}
