import { Component, OnInit } from '@angular/core';
import { LoginService } from '@app/core/services/login/login.service';
import { AppUrls, UrlsFaq } from '@config/app-urls.config';

@Component({
  selector: 'silver-legal-footer',
  templateUrl: './legal-footer.component.html',
  styleUrls: ['./legal-footer.component.scss']
})
export class LegalFooterComponent implements OnInit {
  urls = AppUrls;
  urlContact: string;

  constructor(private loginService: LoginService) { }

  ngOnInit(): void {
    this.getUrlByCollective()
  }

  getUrlByCollective() {
    const collective = this.loginService.getCollective();
    this.urlContact = UrlsFaq[collective] || 'faq_A10';
  }

}


