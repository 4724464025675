import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppUrls } from '@config/app-urls.config';

@Component({
  selector: 'silver-cookie-config-modal',
  templateUrl: './cookie-config-modal.component.html',
  styleUrls: ['./cookie-config-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookieConfigModalComponent implements OnInit {
  @Input() haveAnalyt = false;
  formRef = new FormGroup({
    global: new FormControl({ value: true, disabled: true }),
    analyt: new FormControl({ value: false })
  });
  AppUrls = AppUrls;

  get analytValue() {
    return this.formRef.get('analyt').value;
  }

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit(): void {
    this.formRef.controls.analyt.setValue(this.haveAnalyt);
    this.formRef.controls.analyt.updateValueAndValidity();
  }

  markSelected() {
    this.activeModal.close(this.formRef.value);
  }
}
