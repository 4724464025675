import { Component, OnInit } from "@angular/core";
import { VersionInfo } from "@app/core/api/SILVER/models";
import { VersionService } from "@app/core/api/SILVER/services";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import packageInfo from "./../../../../../package.json";

@Component({
  selector: "onehr-app-version",
  templateUrl: "./app-version.component.html",
})
export class AppVersionComponent implements OnInit {
  frontVersion: string = packageInfo.version;
  backVersion: VersionInfo;
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly versionservice: VersionService
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.versionservice
      .versionInfoGet()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: VersionInfo) => {
        this.backVersion = res;
      });
  }
}
