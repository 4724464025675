/* tslint:disable */
import { ApiRequestBuilder } from '../api-request-builder';

/**
 * Helper to build http requests from parameters
 * It allows per-API request customization (discouraged since it may be probably better done using interceptors)
 */
export class SilverRequestBuilder extends ApiRequestBuilder {

  constructor(
    operationPath: string,
    method: string
  ) {
    super(operationPath, method);
  }
}

