import { APP_INITIALIZER, Injector, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpCustomInterceptor } from './interceptors/http-custom.interceptor';
import { HttpErrorsInterceptor } from './interceptors/http-errors.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { CoreRoutingModule } from './core-routing.module';
import { ConfigModule, ɵapp } from '@devstack-angular/config';
import { OrderPipe } from '@app/shared/pipes/order/order.pipe';
import { TagManagerService } from './services/tag-manager/tag-manager.service';
import { SafePipe } from '@app/shared/pipes/safe-html/safe-html.pipe';
import { environment } from '@environments/environment';
import { HttpMockRequestInterceptor } from './interceptors/mock.interceptor';
import { RouteDynamicService } from '@core/services/routeDinamyc/route-dynamic.service';
import { HeaderCleanerInterceptor } from './interceptors/header-cleaner.interceptor';

/**
 * Both SecurityModule and LoggerModule can be imported with .forRoot() to add extra custom headers if needed
 * TheModule.forRoot({
 *     customHeaders: () => new Headers([['myHeader', 'myValue']])
 *   })
 */

export const HttpLoaderFactory = (handler: HttpBackend) => {
  const http = new HttpClient(handler);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

// export const ConfigLoadFactory = (config: ConfigService) => async () => await config.getConfig();

export function initRoute(injector: Injector, routeService: RouteDynamicService, config: ɵapp) {
  return () => routeService.initRoutes(injector, config);
}

export const isMock = environment.mock;

@NgModule({
  imports: [
    CommonModule,
    CoreRoutingModule,
    HttpClientModule,
    ConfigModule.forRoot({
      endPoint: '/scg/cfg/frontconfig'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    })
  ],
  /* Keep empty. Providers will be exported with the 'forRoot' method so as to be imported only on app module */
  providers: [OrderPipe, TagManagerService, SafePipe]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      // Avoid loading this module multiple times
      throw new Error('Do not try to load CoreModule multiple times, please. Import it in AppModule only :)');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [/* We'll use the .forRoot() on AppModule, so as to make core providers available app-wide */
        {
          provide: APP_INITIALIZER,
          useFactory: initRoute,
          deps: [Injector, RouteDynamicService, ɵapp],
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorsInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpCustomInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpMockRequestInterceptor,
          multi: true
        },
        { // THIS SHOULD BE THE LAST: Clean the header parameters of the call
          provide: HTTP_INTERCEPTORS,
          useClass: HeaderCleanerInterceptor,
          multi: true
        },
        DatePipe
      ]
    };
  }
}
