<div class="form-steps-container mb-3">
  <ng-container *ngFor="let step of steps | async; let i = index;">
    <div class="step-divider"
         [ngClass]="{ 'step-complete': step.isComplete, 'step-incomplete': !step.isComplete, 'step-current': (currentStep | async)?.stepIndex === step.stepIndex }">
      <div *ngIf="!step.isComplete"
           [ngClass]="{ 'step-complete': step.isComplete, 'step-incomplete': !step.isComplete, 'step-current': (currentStep | async)?.stepIndex === step.stepIndex }"
           class="step-bubble">
        <span>{{ step.stepIndex + 1  }}</span>
      </div>
      <div *ngIf="step.isComplete" class=" step-bubble step-bubble-complete">
        <span></span>
      </div>
    </div>

  </ng-container>
</div>
