<label *ngIf="label" class="input-label">{{label | translate}}</label>

<ng-container [formGroup]="parentForm">
  <input #d="ngbDatepicker" #dataPicker (closed)="closeCalendar(controlName)"
         (dateSelect)="onDateSelection($event, controlName)"
         [formControlName]="controlName" [markDisabled]="markDisabled" (ngModelChange)="changeDate()"
         autocomplete="date-picker"
         [maxDate]="maxDateSelected" [minDate]="minDateSelected" [name]="controlName" [ngModel]="inputValue"
         [readonly]="readonly" class="form-control"
         ngbDatepicker [placeholder]="placeholder"/>
  <div class="d-flex justify-content-end">
    <span class="ngx-daterangepicker-action calendar">
      <i *ngIf="btnBorrar && hasDate" class="icn-close" (click)="deleteDate()"></i>
    </span>
    <span class="ngx-daterangepicker-action calendar" (click)="!disableInput && toggle(d)">
      <i class="icon-calendar-ds"></i>
    </span>
  </div>
</ng-container>
