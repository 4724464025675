<td (click)="showDetail()">
  <div class="d-flex align-items-center">
    <i class="icn-view" [title]="'COMMON.BUTTONS.SHOW_CASE_DETAIL' | translate"></i>
    <a class="limit-text">{{'COMMON.BUTTONS.SHOW_CASE_DETAIL' | translate}}</a>
  </div>
</td>
<td class="align-center">
  <i
    [ngClass]="{'icon-information': row.status.statusId === 3, 'icon-more': row.status.statusId === 2, 'icon-check': row.status.statusId === 4 }"></i>
</td>
<td>
  <a (click)="showDetail()" class="d-flex align-items-center">{{row.id}}</a>
</td>
<td>
  <a class="not-active"
    [ngStyle]="{'color': (row.status.statusId === 10) ? '#63ba68' : ''}">{{row.status.translation}}</a>
</td>
<td>
  <a class="not-active">{{row.startDate | date: 'dd/MM/yyyy'}}</a>
</td>
<td>
  <a class="not-active">{{row.endDate | date: 'dd/MM/yyyy'}}</a>
</td>
<td>
  <a class="not-active">{{row.category}}</a>
</td>
<td>
  <a class="not-active">{{row.subcategory}}</a>
</td>
<td>
  <a class="not-active limit-text">{{row.description}}</a>
</td>
<td>
  <a class="not-active limit-text">{{row.lastComment?.comment}}</a>
</td>