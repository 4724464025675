export const SETTINGS = {
  MENU: {
    limitShow: 6
  },
  TAG_MANAGER: {
    page_name: '/silver',
    page_title: 'Silver - ',
    procesos: 'PROCESOS',
    buscador: 'BUSC',
    enlaces_externos_menu: {
      category: 'navegacion',
      action: 'menu'
    },
    enlaces_externos_link: {
      category: 'navegacion',
      action: 'enlace_externo'
    },
    enlaces_externos_doc: {
      category: 'descargas',
      action: 'Documento'
    },
    enlaces_externos_video: {
      category: 'descargas',
      action: 'Video'
    },
    busqueda: {
      category: 'navegacion',
      action: 'click'
    },
    colectivo: {
      españa: 'España',
      corporativo: 'Corporativo'
    }
  }
};
