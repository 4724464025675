<h3 class="mb-4">{{'COMMON.TABLE_FILTER_TITLES.FILTER_TITLE' | translate}}</h3>
<!-- Cuando tenemos varios filtros agrupados por desplegables -->
<ng-container *ngIf="filterTableType === FilterTableConfigEnum.GROUP_FILTER; else elseTemplate">
  <div class="filter-dropdown" *ngFor="let item of inputListGroup; let i = index">
    <div class="filter-dropdown-title" [ngClass]="{'open-filter': open === i}" (click)="openFilter(i)">
      <h2>{{item.group.title | translate}}</h2>
      <i class="icn-dropdown"></i>
    </div>
    <app-search-filter [typeFilter]="filterTableType" (setValue)="setValue($event)" [showButton]="false"
                       [inputListGroup]="item.list" [ngClass]="open === i ? 'showContent' : 'hideContent'"
                       [guia]="guia"></app-search-filter>
  </div>
  <div class="col-md-12 p-0">
    <div class="btn-block border-top pb-3">
      <div class="search-warning text-right pr-4">
        {{ 'COMMON.FILTER.SEARCH_WARNING' | translate }}
      </div>
      <button type="button" class="btn btn-primary" (click)="searchData(true)">
        {{'COMMON.FILTER.SEARCH' | translate}}
      </button>
    </div>
  </div>
</ng-container>
<!-- Cuando el filtro es normal -->
<ng-template #elseTemplate>
  <app-search-filter (searchData)="searchData($event)" (setValue)="setValue($event)"
                     [searchOnSelect]="autoSearch" [showBoxBorder]="showFilterBorder"
                     [showButton]="!showOnlyFilterInputs" [textWarning]="textWarning"
                     [typeFilter]="filterTableType"></app-search-filter>
  <!-- <app-search-filter [typeFilter]="filterTableType" (searchData)="searchData($event)" [specificDependencies]="specificDependenciesGuide"></app-search-filter> -->
</ng-template>

<ng-content select="[editContent]"></ng-content>

<ng-container *ngIf="initialCall">
  <div class="d-flex py-3 justify-content-between">
    <ng-container [ngSwitch]="filterTableConfig.pageType">
      <!-- <h3 *ngSwitchCase="pageType.SYSTEM_COLLECTIVE" class="mb-3">{{'MASTER.TABLE_TITLE.SYSTEM_LIST' | translate}}</h3> -->
    </ng-container>

    <div *ngIf="downloadButton" class="d-flex download-btn">
      <div
        [ngClass]="totalElements > 0 ? 'icon-red-txt' : 'icon-grey-txt' "
        (click)="totalElements > 0 && downloadFile()">
        <i
          [ngClass]="totalElements > 0 ? 'icn-excel' : 'icn-excel'">
        </i>
        <div>{{'COMMON.BUTTONS.DOWNLOAD' | translate}}</div>
      </div>
      <ng-content select="[download]"></ng-content>
    </div>
  </div>

  <ng-content select="[table]"></ng-content>
  <ng-content select="[checkBoxArray]"></ng-content>

  <onehr-custom-table #customTable (orderSelected)="orderSelected($event)"
                      (pageSelected)="pageSelected($event)"
                      (rowAction)="rowActionEvent($event)"
                      *ngIf="filterTableType"
                      [columns]="columns"
                      [detailTemplate]="detailTemplate"
                      [p]="page + 1"
                      [rows]="rows"
                      [showCollapse]="showCollapse"
                      [showOrders]="showOrder"
                      [tableType]="filterTableType"
                      [totalRecords]="totalElements">
  </onehr-custom-table>
</ng-container>
