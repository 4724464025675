import { FormControl, FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss']
})
export class InputCheckBoxComponent implements OnInit {

  @Input() label: string;
  @Input() placeholder: string = '';
  @Input() controlName: string;
  @Input() minLength: number;
  @Input() required: boolean;
  @Input() value: string;
  @Input() disabled: boolean;
  @Input() parentForm: FormGroup;
  @Output() search = new EventEmitter();
  @Output() setValue = new EventEmitter();
  checked: any = false;

  constructor() { }

  ngOnInit(): void {
    this.parentForm.addControl(
      this.controlName,
      new FormControl({value: this.value, disabled: this.disabled})
    );
    this.checked = this.value;
  }

  changeStatus(event, control: string) {
    const inputValue: boolean = event.currentTarget.checked;
    this.search.emit({controlName: control, item: { value: inputValue, id: inputValue } });
  }
}
