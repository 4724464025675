/* tslint:disable */
import { Injectable } from '@angular/core';
import { SilverBaseService } from '../silver-base-service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { SilverRequestBuilder } from '../silver-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';



/**
 * Logout
 */
@Injectable({
  providedIn: 'root',
})
export class LogoutService extends SilverBaseService {
  constructor(
    config: EnviromentService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation logout
   */
  static readonly LogoutPath = '/logout';

  /**
   * Logout.
   *
   * Logout user deleting NewUniversalCookie
   *
   * This method provides customization options.
   * To access only the response body, use `logout()` instead.
   *
   * This method doesn't expect any request body.
   */
  logout$Response(params?: {

  }): Observable<void> {

    const req = new SilverRequestBuilder(LogoutService.LogoutPath, 'post');
    if (params) {
    }

    const sendReq = req.build({
      responseType: 'text',
      accept: '*/*'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return (r as void);
      })
    );
  }

  /**
   * Logout.
   *
   * Logout user deleting NewUniversalCookie
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `logout$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  logout(params?: {

  }): Observable<void> {

    return this.logout$Response(params).pipe(
      map(r => r as void)
    );
  }

}
