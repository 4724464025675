import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { BreadcrumbObject } from '@app/shared/models/breadcrumb/breadcrumbs.model';
import { AppUrls } from '@config/app-urls.config';
import { ɵapp } from '@devstack-angular/config';
import { concatMap } from 'rxjs/operators';
import { EnviromentService } from '../environment/environment.service';
import { FindChildrenService } from '../findChildren/find-children.service';
import { LoginService } from '../login/login.service';
import { CompAccess } from './mbox.model';
import { CompAccessI } from '@core/services/routeDinamyc/compAccess.model';
import { StorageService } from '@core/services/storage/storage.service';
import { EMPTY } from 'rxjs';
import { TagManagerService } from '../tag-manager/tag-manager.service';

@Injectable({
  providedIn: "root",
})
export class RouteDynamicService {
  routesDinamyc: Array<any> = [];
  aMBox: Array<CompAccess> = [];

  constructor(
    private findChildren: FindChildrenService,
    private router: Router,
    private loginService: LoginService,
    private enviromentService: EnviromentService,
    private storageService: StorageService,
    private http: HttpClient,
    private gtm: TagManagerService
  ) {
  }

  initRoutes(injector: Injector, configService: ɵapp) {
    const user = this.storageService.get("authUser");

    if (user) {
      return this.allRoutes(injector, configService);
    } else {
      return EMPTY;
    }
  }

  allRoutes(injector: Injector, configService: ɵapp) {
    // Cogemos la configuracion del entorno antes de traernos todo el arbol de rutas.
    return configService
      .onConfig()
      .pipe(
        concatMap((config) => {
          return this.loginService
            .launchLogin()
            .then((data) => {
              return this.getRoutesPS(injector).then((routes) => {});
            })
            .catch((data) => {
              return this.getRoutesPS(injector).then((routes) => {});
            });
        })
      )
      .toPromise();
  }

  /**
   * Método que obtiene las rutas padres e hijas de People de los servicios HeaderMod y LvlsPrcssandInfo
   * @param injector Se utiliza para injectar las rutas estáticas que tenemos en el app.routing
   */
  getRoutesPS(injector?: Injector): Promise<any> {
    // Convetirmos el Observable en una promesa y una vez resuelta Contruimos las rutas de la aplicación
    const promise = new Promise((resolve, reject) => {
      this.getMbox()
        .toPromise()
        .then((routes) => {
          if (routes) {
            resolve(this.contructorRoutes(routes));
          } else {
            resolve(routes);
          }
        })
        .catch((err) => {
          console.error("ERROR en la promise de routes", err);
          resolve(true);
        });
    });
    return promise;
  }

  /**
   * Llamada al servicio HeaderMod par obtener la rutas padres.
   */
  getMbox() {
    this.gtm.loadTagManagerScript(this.enviromentService.get().gtmAuth, this.enviromentService.get().gtmPreview);
    const baseUrl = this.enviromentService.get().app.rest.PS.baseUrl;
    const url =
      this.enviromentService.get().app.rest.PS.endpoints.HeaderMod.url;
    const UrlHeaderMod: string = baseUrl + url;
    return this.http.request("get", UrlHeaderMod, {
      params: {
        hrStructureId: sessionStorage.getItem("hrStructureId"),
        languageId: sessionStorage.getItem("language"),
      },
    });

    // Mock cuando no funciona PS
    // return of(NcHeaderMod['default']);
  }

  searchParents(
    allRoutes: Array<CompAccess>,
    itemChild: CompAccess,
    breadCrumbObject: BreadcrumbObject
  ) {
    allRoutes.forEach((item: CompAccess) => {
      // Buscamos los padres
      if (item.accessItem === itemChild.parentName && item.parentName !== "") {
        breadCrumbObject.breadcrumb.levels.unshift({
          level: item.descrItem,
          sref: "/" + item.linkItem,
        });
        // Llamamos recursivamente para saber si el item tiene padre.
        this.searchParents(allRoutes, item, breadCrumbObject);
      } else if (
        item.accessItem === itemChild.parentName &&
        item.parentName === ""
      ) {
        // Si no tiene mas padres es el padre de todos
        breadCrumbObject.breadcrumb.levels.unshift({
          level: item.descrItem,
          sref: "/" + item.linkItem,
        });
      }
      breadCrumbObject.breadcrumb.h1 = itemChild.titulo;
    });
  }

  /**
   * Método para crear los breadcrumbs que tiene cada ruta.
   * @param linkItem Link actual que se recorre.
   * @param allRoutes Todas la rutas (padres e hijos) que vienen de People
   */
  createBreadcrumbs(linkItem: string, allRoutes: Array<CompAccess>) {
    const breadCrumbObject: BreadcrumbObject = {
      breadcrumb: {
        h1: "",
        levels: [],
        item: null,
      },
    };

    const itemParent: Array<CompAccess> = allRoutes.filter(
      (item: CompAccess) => item.linkItem === linkItem && item.parentName === ""
    );
    // Si es padre
    if (itemParent[0]) {
      breadCrumbObject.breadcrumb.levels.push({
        level: itemParent[0].descrItem,
        sref: "",
      });
      breadCrumbObject.breadcrumb.h1 = itemParent[0].descrItem;
      /* Guardamos en acceso dentro del objeto breadcrumb
      (Actualmente solo necesitamos el accesType y el mainTemplate para pintar o no el h1)*/
      breadCrumbObject.breadcrumb.item = itemParent[0];
      // Si es un hijo
    } else {
      // Buscamos los que sean hijos
      const itemChild: Array<CompAccess> = allRoutes.filter(
        (child: CompAccess) =>
          child.linkItem === linkItem && child.parentName !== ""
      );
      breadCrumbObject.breadcrumb.levels.unshift({
        level: itemChild[0].descrItem,
        sref: "",
      });
      const item = itemChild[0];
      /* Guardamos en acceso dentro del objeto breadcrumb
      (Actualmente solo necesitamos el accesType y el mainTemplate para pintar o no el h1)*/
      breadCrumbObject.breadcrumb.item = item;
      this.searchParents(allRoutes, item, breadCrumbObject);
    }
    // Metemos el nivel Inicio en los breadcrumbs para todas las paginas.
    breadCrumbObject.breadcrumb.levels.unshift({
      level: "BREADCRUMBS.INICIO",
      sref: "/home",
    });
    return breadCrumbObject;
  }

  /**
   * Método para construir las rutas de la aplicación
   * @param aMBoxAux Rutas padres
   * @param aNVLAux Rutas hijas
   */
  contructorRoutes(routesAll) {
    // rutas de PS
    let routes: Array<CompAccess> = [];
    const key = "compAccess";
    // Concatenamos las rutas padres e hijas para tenerlas en un mismo array.
    routes = routesAll[key];

    const key1 = "compContLiferay";
    let accessList: Array<CompAccessI> = [];
    // Array con todas las plantillas de Liferay de padres e hijos
    accessList = routesAll[key1];

    // linkItem es el enlace interno normal de cada acceso de PS.
    // sNavega es para cuando si una caja hija redirige a otro flujo.
    // urlId es cuando una caja va a un sitio externo
    // routes = routes.filter(route => route && (route.linkItem !== '' || route.sNavega !== '' || route.urlId !== ''));

    // Aqui a a añadirle a este objeto los parametros del array compContLiferay
    // Recorrer routes (Array de PS) y buscar los elementos liferay que le pertenezcan y añadirlo a routes
    routes.forEach((element) => {
      let compContLiferay: Array<CompAccessI> = accessList.filter(
        (item: CompAccessI) => {
          return item.accessPermission === element.accessItem;
        }
      );
      if (compContLiferay[0]) {
        // console.log('compContLiferay[0]: ', compContLiferay[0]);
        element.mainTemplate = compContLiferay[0].template;
        element.compContAccess = compContLiferay;
      }
      compContLiferay = [];
    });

    // Se setean variables para utilizarse en el componente menu
    this.findChildren.setChildren(routes);

    // Obtenemos las rutas de app.routing con el injector.get(Router)
    const router: Router = this.router;
    console.log(router.config)
    router.config.splice(router.config.length-1, 1);

    // Rutas estáticas de app.routing antes de añadir las rutas de PS dinamicamente
    const staticRoutes: Routes = router.config;
    // creamos una copia de array con la rutas estáticas
    const copyArray = staticRoutes.slice(0, staticRoutes.length);
    // Nos recorremos el array con las rutas que vienen de PS (padres e hijos)
    routes.forEach((element) => {
      // Comprobamos si existe la ruta de PS dentro de la estáticas(app.routing)
      const flagRoute = this.existElement(element.linkItem, copyArray).result;
      /** Si existe nos posicionamos en el array de rutas final(router.config)
       * y le añadimos en el objecto data los breadcrumbs correpondientes.
       */
      if (element.linkItem !== "" && flagRoute) {
        const breadcrumb = this.createBreadcrumbs(element.linkItem, routes);
        const positionRoute = this.existElement(
          element.linkItem,
          copyArray
        ).position;
        router.config[positionRoute].data = breadcrumb;
      } else {
        if (element.linkItem !== "") {
          // Si no tiene urlId o sNavega y tiene linkItem cargamos el componente que corresponda segun la template.
          this.loadModule(element, router, routes);
        }
      }
    });
  
    router.config.push({
      path: "**",
      redirectTo: AppUrls.AppHome,
      pathMatch: "full",
    });
    return router.config;
  }

  /**
   * Carga de Modulo de componente dependiendo del template que nos dice el campo mainTemplate.
   */
  loadModule(element, router, routes) {
    switch (element.mainTemplate) {
      case "MOD":
        router.config.push({
          path: element.linkItem,
          loadChildren: () =>
            import("@modules/first-level/first-level.module").then(
              (m) => m.FirstLevelModule
            ),
          data: this.createBreadcrumbs(element.linkItem, routes),
        });
        break;
      case "DS1":
        router.config.push({
          path: element.linkItem,
          loadChildren: () =>
            import("@modules/second-level/second-level.module").then(
              (m) => m.SecondLevelModule
            ),
          data: this.createBreadcrumbs(element.linkItem, routes),
        });
        break;
      case "CPA":
        router.config.push({
          path: element.linkItem,
          loadChildren: () =>
            import(
              "@modules/final-content-paragraph/final-content-paragraph.module"
            ).then((m) => m.FinalContentParagraphModule),
          data: this.createBreadcrumbs(element.linkItem, routes),
        });
        break;
      case "CAC":
        router.config.push({
          path: element.linkItem,
          loadChildren: () =>
            import(
              "@modules/final-content-accordion/final-content-accordion.module"
            ).then((m) => m.FinalContentAccordionModule),
          data: this.createBreadcrumbs(element.linkItem, routes),
        });
        break;
    }
  }

  existElement(param: string, arrayAux: Routes) {
    const result = { result: false, position: 0 };
    arrayAux.forEach((element, iter) => {
      if (param === element.path) {
        result.result = true;
        result.position = iter;
      }
    });
    return result;
  }
}
