<ng-container *ngIf="!isScroll;else hasScroll">
  <table class="table table-striped">
    <thead>
    <tr>
      <th *ngFor="let column of columns" class="padding-15 sides-no-padding sides-padding-right" [ngStyle]="{width: column.width ? column.width + '%' : '', 'padding-right': column.enum === 'statusIcon' ? '0' : '15px'}" scope="col">
        <div class="d-flex align-items-center" *ngIf="column.enum" >
          {{ column.value | translate }}
        </div>
        <div *ngIf="!column.enum">
          {{ column.value | translate }}</div>
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container
      *ngFor="let row of rows | paginate: {itemsPerPage: itemsPerPage, currentPage: p, totalItems: totalRecords, id: tableType} ; let i = index "
      [ngSwitch]="true">
      <!-- EXAMPLE -->
      <!-- <tr class="tbody" *ngSwitchCase="tableType === FilterTableConfigEnum.SYSTEM_COLLECTIVE"
          onehr-row-master-system (rowAction)="rowActionEvent($event)" [row]="row"> -->
      <tr class="tbody" *ngSwitchCase="tableType === FilterTableConfigEnum.CONSULT_REQUEST"
          app-row-consult-request (rowAction)="rowActionEvent($event)" [row]="row">
    </ng-container>
    </tbody>
  </table>
</ng-container>

<ng-template #hasScroll>
  <div class="outer-table-scroll mb-4">
    <div class="inner-table-scroll scroll-styled">
      <ng-container [formGroup]="checkForm">
        <!-- columns:{{columns|json}} -->
        <table class="table table-striped table-scroll" [ngClass]="oddTable ? 'table-odd' : 'table-even'">
          <tr class="thead"
              [ngClass]="{ actionColumn: showActionColumn }">
            <ng-container *ngFor="let column of columns; let i = index;">
              <!-- CHECK ALL-->
              <th class="d-flex align-items-start justify-content-end pt-0"
                  *ngIf="rows.length> 0 && i===0 && showActionColumn">
                <div class="action-item d-flex flex-column align-items-center" *ngIf="showCheckAll">
                  <label class="regulation-check">
                    <input formControlName="checkAll" id="checkAll" type="checkbox" name="checkAll"
                           (change)="checkAllChange()">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </th>
              <!-- COLUMNA VACÍA PARA EL SCROLL, LA COLUMNA DE ACCIONES FLOTA ENCIMA DE ESTA -->
              <td *ngIf="i===0 && showActionColumn" class="padding-15 sides-no-padding empty-column-action">
              </td>
              <td *ngIf="!(i===0 && showActionColumn) && !column.enum" class="padding-15 sides-no-padding">
                <div class="d-flex align-items-center">
                  {{ column.value | translate }}
                </div>
              </td>
              <td class="padding-15 empty-column-action sides-no-padding" *ngIf="column.enum">
                <div class="d-flex align-items-center justify-content-center">
                  {{ column.value | translate }}
                </div>
              </td>
            </ng-container>
          </tr>
          <ng-container
            *ngFor="let row of rows | paginate: {itemsPerPage: itemsPerPage, currentPage: p, totalItems: totalRecords, id: tableType} ; let i = index "
            [ngSwitch]="true">
            <tr class="tbody"
                *ngSwitchCase="tableType === FilterTableConfigEnum.SCROLL_REQUEST || tableType === FilterTableConfigEnum.GROUP_FILTER"
                app-row-example-check-all [tableType]="tableType"
                [parentForm]="checkForm" [row]="row" [i]="i"
                (rowAction)="rowActionEvent($event)"
                aria-controls="'collapseExample'+ i">
            </tr>
          </ng-container>
        </table>
      </ng-container>
    </div>
  </div>
</ng-template>
<div
  *ngIf="rows?.length === 0"
  class="text-center my-5" [ngClass]="{'empty-data-scroll': isScroll}">{{'COMMON.TABLE.EMPTY_DATA' | translate}}</div>
<pagination-controls *ngIf="showPagination" [id]="tableType"
                     previousLabel="{{'COMMON.LABELS.PREVIOUS' | translate  }}"
                     nextLabel="{{'COMMON.LABELS.NEXT' | translate }}"
                     (pageChange)="p = $event; pageChange($event)">
</pagination-controls>
