import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoaderComponent } from './loader/components/loader.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from '@app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { PublicHeaderComponent } from './public-header/public-header.component';
import { PublicFooterComponent } from './public-footer/public-footer.component';
import { CookieModalComponent } from './cookie/cookie-modal/cookie-modal.component';
import { CookieConfigModalComponent } from './cookie/cookie-config-modal/cookie-config-modal.component';
import { CookiesAppModalService } from './cookie/cookies-app-modal.service';
import { LegalFooterComponent } from './legal-footer/legal-footer.component';

/**
 * This module will contain the main layout elements
 * for the app: header, footer and loader
 */
@NgModule({
  declarations: [/* Components, directives and pipes to be shared */
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    PublicHeaderComponent,
    PublicFooterComponent,
    CookieModalComponent,
    CookieConfigModalComponent,
    LegalFooterComponent
  ],
  imports: [
    SharedModule,
    RouterModule
  ],
  exports: [/* Components, directives and pipes to be shared */
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    PublicHeaderComponent,
    PublicFooterComponent,
    CookieModalComponent,
    CookieConfigModalComponent
  ]
})
export class ShellModule {
  constructor(private readonly cookies: CookiesAppModalService) {
    this.cookies.init();
  }
}
