import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: '[app-row-consult-request]',
  templateUrl: './row-consult-request.component.html',
  styleUrls: ['./row-consult-request.component.scss']
})
export class RowConsultRequestComponent {
  @Input() row: any= {};
  @Output() rowAction = new EventEmitter();

  showDetail() {
    this.rowAction.emit({ row: this.row });
  }

}
