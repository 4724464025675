import { FormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { BoxTitleFormTypesEnum } from '../box-title-form/box-title-form.model';
import { BootstrapClass } from '@app/shared/constants/bootstrap/bootstrap.constants';
import { GLOBAL } from '@shared/constants/global/global.constants';

/**
 * @description Enum del tipo de usuarios para el Filter-table
 * @export
 * @enum {number}
 */
export enum UserRoleTypeEnum {
  'BASIC' = 'BASIC',
  'ADVANCED' = 'ADVANCED',
  'SUPERVISOR' = 'SUPERVISOR',
  'HR_MANAGER' = 'HR_MANAGER',
  'OPERATION' = 'OPERATION',
  'GENERAL_SECRETARY' = 'GENERAL_SECRETARY',
  'ADMIN' = 'ADMIN'
}

/**
 * @description Enum del tipo de controles del filtro
 * @export
 * @enum {number}
 */
export enum FilterCtrlEnum {
  ACC_BANK = 'accBank',
  ACC_BRANCH = 'accBranch',
  ACC_CHECK_DIGIT = 'accCheckDigit',
  ACC_NUMBER = 'accNumber',
  ADDR_FIELD_1 = 'addrField1',
  ADDR_NUM_1 = 'addrNum1',
  ADDR_NUM_2 = 'addrNum2',
  ADDRESS_1 = 'address1',
  BENEFICIARIES = 'beneficiaries',
  BIRTH_DATE = 'birthDate',
  CAUSATIVE_ID = 'causativeId',
  CATEGORY = 'category',
  CITY_DESC = 'cityDesc',
  COMPANY_DESC = 'companyDesc',
  COMPANY_ID = 'companyId',
  COUNTRY = 'country',
  EMPLID = 'emplid',
  END_DATE_REQUEST_START = 'endDate',
  END_DATE_REQUEST_END = 'endDateEnd',
  END_DATE_REQUEST = 'finishDate',
  MAR_STATUS = 'marStatus',
  NAME = 'name',
  NATIONAL_ID = 'nationalId',
  PAYROLL_SERIAL_NAME = 'payrollSerialName',
  POSTAL = 'postal',
  SECTION = 'section',
  SOURCE_BANK_DESC = 'sourceBankDesc',
  START_DATE_REQUEST_START = 'startDate',
  START_DATE_REQUEST_END = 'startDateEnd',
  START_DATE_REQUEST = 'initDate',
  STATE = 'state',
  STATE_DESC = 'stateDesc',
  STATE_ID = 'stateId',
  STATUS_CODE = 'statusCode',
  STATUS_DESC = 'statusDesc',
  STATUS_INIT_DATE = 'statusInitDate',
  SUBCATEGORY = 'subcategory',
  WORKDAY_ID = 'workdayId',
  BENID = 'benid',
  FAMILY_STATUS = 'familyStatus',
  GENRE = 'genre',
  PSYPHY_COND_DESC = 'psyphyCondDesc',
  PSYPHY_COND_ID = 'psyphyCondId',
  REL_DESC = 'relDesc',
  EMAIL = 'email',
  PHONE = 'phone',
  YEAR = 'year',
  MONTH = 'month',
  COMMENT = 'comment',
  CITY_CODE = 'postalCode',
  WITHHOLDING_CERTIFICATE_YEAR = 'withholdingCertificateYear',
  ADDRESS_TYPE = 'addressType',
  FISCAL_ADDR = 'fiscalAddr',
  ADDR_NUMBER = 'addrNumber',
  ADDR_LADDER = 'addrLadder',
  ADDR_FLOOR = 'addrFloor',
  ADDR_DOOR = 'addrDoor',
  ADDR_BLOCK = 'addrBlok',
  ADDR_POSTAL_STATE = 'addrPostalState',
  ADDR_MUNICIPALITY = 'addrMunicipality',
  ADDR_STATE = 'addrState',
  ADDR_MUNICIPALITY_OTHER = 'addrMunicipalityOther',
}

/**
 * @description Enum del tipo de controles del filtro
 * @export
 * @enum {number}
 */
export enum FilterCtrlTypesEnum {
  TEXT = 'text',
  NUMBER = 'number',
  DATEPICKER = 'date',
  LINK = 'link',
  DATE = 'date',
  SEARCH = 'search',
  SELECT = 'select',
  TEXTAREA = 'textArea',
  PDF = 'pdf',
  CHECKBOX = 'checkBox',
  ONLY_TEXT = 'onlyText'
}

const userRoleTypeConst = 'userRoleType';

/**
 * @description Clase para implementar crear los datos de la llamada del filtro
 * @export
 * @class FIlterPowerSendData
 */
export class FIlterSendData {
  public 'X-USER-ROL': string;
  public page: number;
  public sort: string; // example: description, asc
  public size: number;
  public body: any;

  constructor(
    page = 0,
    sort = null,
    size = 6,
    body = null) {
    this.page = page;
    this.sort = sort;
    this['X-USER-ROL'] = '';
    this.size = size;
    this.body = body;
  }
}

/**
 * @description Interfaz de configuraciónde controles del search-filter
 * type: Tipo de ctrl a implementar
 * name: Texto para el Label y para los atributos name de los input/textarea
 * controlName: Nombre del control para el formControlName
 * parentName: Formulario al que integrar el control
 * required: Valor de ctrl requerido
 * placeholder: placeholder de los input
 * minLength:  minLength de los input
 * results: Listado de resultado para el desplegable del search-input
 * resultsMapKey: Valores para el mapeado de los resultados de la llamada REST que rellena el desplegable de los search-input
 * selectType: Para el tipo de ctrl search-input. Indica si es un select o un input con lupa
 * dropdown: Forzado desplegar listado search-input
 * value: Valor del ctrl.
 * bootstrapClass: Clase de bootstrap, para el columnado.
 * dependency: Objeto para manejar las dependencias entre los valores de los controles del formulario.
 * initialLoad: Si se debe hacer una llamada al inicio para rellenar los search-input (sus deplegables). Usable por los select.
 * cache: SI se debe cachear la llamada REST que trae datos al search-input
 * filter: SI se debe filtrar la llamada REST que trae datos al search-input.
 *        Usable por los select para capar las listas. Dicho filtro se pasa a Back.
 * minDay: minDay para el calendario del datepicker
 * maxDay: maxDay para el calendario del datepicker
 * btnBorrar: Botón de borrado de la fecha en el datepicker
 * extraData: Para otro tipo de propiedades
 * @export
 * @interface IfilterCtrl
 */
export interface IfilterCtrl {
  isHidden?: any;
  type: string;
  name?: string;
  controlName?: string;
  parentName?: FormGroup;
  disableInput?: boolean;
  required?: boolean;
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
  minValue?: number;
  maxValue?: number;
  results?: any[];
  resultsMapKey?: IResultsMapKey;
  selectType?: boolean;
  dropdown?: boolean;
  value?: any;
  bootstrapClass?: string;
  dependency?: IdependencyCtrl;
  dependency2?: IdependencyCtrl;
  dependency3?: IdependencyCtrl;
  initialLoad?: boolean;
  cache?: boolean;
  filter?: string;
  startDay?: NgbDateStruct;
  minDay?: NgbDateStruct;
  maxDay?: NgbDateStruct;
  btnBorrar?: boolean;
  extraData?: any;
  // catalog?: CatalogField;
  catalog?: any;
  strictDependency?: boolean;
  extraParamOnlyInDropdown?: boolean;
  maximHeight?: number;
}

/**
 * @description Interfaz de datos de las dependencias de los valores de los controles
 * controlName: ctrl del que dependen su valor
 * dependencyKey: key de la propiedad de datos almacenados el los elementos de lista de un serach-input, del cual depende su valor.
 * dependencyInitialData: Si el ctrl del cual se tiene dependencia de su valor tiene un preseteo inicial.
 *                        Se debe indicar ya q a veces no se puede acceder a su valor pq todavía no se ha creado el ctrl,
 *                        dependiendo de su posición en el inputList.
 * @export
 * @interface IdependencyCtrl
 */
export interface IdependencyCtrl {
  controlName: string;
  dependencyKey: any;
  dependencyInitialData?: string | number;
  dependencyData?: any;
  dependencyDataId?: string | number;
}

/**
 * @description Interfaz de datos del valor de un control searchInput con estructura key/value necesaria.
 * value: Valor
 * id: Propiedad
 * @export
 * @interface IdependencyCtrl
 */
export interface ISelectValueData {
  value: string;
  id: string;
}

/**
 * @description Interfaz para el mapeo de datos de los servicios con estructura key/value necesaria.
 * keys: Keys a mapear. Puede haber más de una, pero la primera siempre se mapeará a la propiedad id para que funcione correctamente el deplegable del search-input.
 * id: Propiedad
 * @export
 * @interface IdependencyCtrl
 */
export interface IResultsMapKey {
  keys: string[];
  value: string[];
}

export interface IfilterGroupCtrl {
  group: Group;
  list: IfilterCtrl[];
}

export interface Group {
  id: number;
  title: string;
}

/**
 * @description Datos de configuración de los controles del search-filter
 * @export
 * @constant FILTER_CTRL_CONFIG_TYPES
 */
export const FILTER_CTRL_CONFIG_TYPES: { filters: IfilterCtrl[] } = {
  filters: [
    // EXAMPLES
    {
      type: BoxTitleFormTypesEnum.SELECT,
      name: 'CTRL_NAMES.CATEGORY',
      controlName: FilterCtrlEnum.CATEGORY,
      parentName: null,
      bootstrapClass: BootstrapClass.classCol6,
      placeholder: '',
      selectType: true,
      value: {
        id: '',
        value: GLOBAL.COMMON_STATUS_ALL
      },
      results: [
        { value: GLOBAL.COMMON_STATUS_ALL, id: '' }
      ],
      resultsMapKey: {
        keys: ['id'],
        value: ['value']
      },
      maximHeight: 200
    },
    {
      type: BoxTitleFormTypesEnum.SELECT,
      name: 'CTRL_NAMES.SUBCATEGORY',
      controlName: FilterCtrlEnum.SUBCATEGORY,
      parentName: null,
      bootstrapClass: BootstrapClass.classCol6,
      placeholder: '',
      selectType: true,
      value: { id: '', value: GLOBAL.COMMON_STATUS_ALL },
      results: [
        { value: GLOBAL.COMMON_STATUS_ALL, id: '' }
      ],
      resultsMapKey: {
        keys: ['id'],
        value: ['value']
      },
      dependency: {
        controlName: FilterCtrlEnum.CATEGORY,
        dependencyKey: 'value'
      }
    },
    {
      type: BoxTitleFormTypesEnum.SELECT,
      name: 'CTRL_NAMES.SECTION',
      controlName: FilterCtrlEnum.SECTION,
      parentName: null,
      bootstrapClass: BootstrapClass.classCol6,
      placeholder: '',
      selectType: true,
      value: { id: '', value: GLOBAL.COMMON_STATUS_ALL },
      results: [
        { value: GLOBAL.COMMON_STATUS_ALL, id: '' }
      ],
      resultsMapKey: {
        keys: ['id'],
        value: ['value']
      },
      dependency: {
        controlName: FilterCtrlEnum.CATEGORY,
        dependencyKey: 'value'
      },
      dependency2: {
        controlName: FilterCtrlEnum.SUBCATEGORY,
        dependencyKey: 'value'
      }
    },
    {
      type: BoxTitleFormTypesEnum.SELECT,
      name: 'CTRL_NAMES.STATE',
      controlName: FilterCtrlEnum.STATE,
      parentName: null,
      bootstrapClass: BootstrapClass.classCol6,
      placeholder: '',
      selectType: true,
      value: { id: '', value: GLOBAL.COMMON_STATUS_ALL },
      results: [],
      resultsMapKey: {
        keys: ['id'],
        value: ['value']
      },
      maximHeight: 200
    },
    {
      type: BoxTitleFormTypesEnum.DATE,
      name: 'CTRL_NAMES.START_DATE_REQUEST',
      controlName: FilterCtrlEnum.START_DATE_REQUEST_START,
      placeholder: '',
      parentName: null,
      results: [],
      value: '',
      btnBorrar: true,
      bootstrapClass: BootstrapClass.classCol3,
      required: true
    },
    {
      type: BoxTitleFormTypesEnum.DATE,
      name: 'CTRL_NAMES.START_DATE_REQUEST_END',
      controlName: FilterCtrlEnum.END_DATE_REQUEST_END,
      placeholder: '',
      parentName: null,
      results: [],
      value: '',
      btnBorrar: true,
      bootstrapClass: BootstrapClass.classCol3,
      required: true
    },
    {
      type: BoxTitleFormTypesEnum.DATE,
      name: 'CTRL_NAMES.END_DATE_REQUEST',
      controlName: FilterCtrlEnum.END_DATE_REQUEST_START,
      placeholder: '',
      parentName: null,
      results: [],
      value: '',
      btnBorrar: true,
      bootstrapClass: BootstrapClass.classCol3,
      required: true
    },
    {
      type: BoxTitleFormTypesEnum.DATE,
      name: 'CTRL_NAMES.END_DATE_REQUEST_END',
      controlName: FilterCtrlEnum.END_DATE_REQUEST_END,
      placeholder: '',
      parentName: null,
      results: [],
      value: '',
      btnBorrar: true,
      bootstrapClass: BootstrapClass.classCol3,
      required: true
    },
    {
      type: BoxTitleFormTypesEnum.DATE
    }
  ]
};
