<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ title | translate }}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.close('KO')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p> {{ desc | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="activeModal.close('KO')">{{ textKO | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="activeModal.close('OK')">{{ textOK | translate }}</button>
  </div>
  