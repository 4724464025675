<div class="container">
  <div class="msg-container msg-error col-12">
    <i class="icn-alert"></i>
    <p class="mt-4 mb-5">
      {{ text | translate }}
      <span *ngIf="errParams">{{ errParams }}</span>
    </p>
    <div>
      <button class="btn btn-primary btn-lg" type="button"
              (click)="goToRoute(url)"> {{ textButton | translate}} </button>
    </div>
  </div>
</div>
