<header *ngIf="headerService.isHeaderVisible | async">
  <div class="d-flex">
    <div class="menu-icon" (click)="toggleMenu($event)" *ngIf="headerService.isLogoVisible">
      <div class="icon-menu">
        <div class="checkbox-toggle" [ngClass]="{ closed: openMenu, opened: !openMenu }"></div>
        <div class="hamburger">
          <div></div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!openMenu; else elseTemplate">
      <div class="text-hamburger" (click)="openMenu = !openMenu">{{ 'HEADER_MAIN.MENU' | translate }}</div>
    </ng-container>
    <ng-template #elseTemplate>
      <div class="text-hamburger" (click)="openMenu = !openMenu">{{ 'HEADER_MAIN.CLOSE' | translate }}</div>
    </ng-template>
    <div class="logo-img px-3" *ngIf="headerService.isLogoVisible">
      <img [routerLink]="['/home']" class="img-fluid pointer" src="./assets/img/santanderSilver_pos.svg">
    </div>
    <div class="header" *ngIf="headerService.titleInHeader !== '' && !headerService.isLogoVisible">
      <h1> {{ headerService.titleInHeader | translate }}</h1>
    </div>
  </div>

  <div class="icons-header-container d-flex">
    <ng-container *ngIf="!headerService.showCloseButton">
      <div *ngFor="let element of headerElements; let i=index">
        <div class="header-language" *ngIf="element.xlatElementType === 'P' && element.text === 'Idioma'">
          <div class="header-language-item-red" (click)="openTooltipLanguage()">
            <i *ngIf="element.iconName" [ngClass]="element.iconName"></i>
          </div>
          <div class="tooltip-container" *ngIf="tooltipLanguage">
            <div *ngFor="let language of totalLanguages" class="tooltip-text"
                 [ngClass]="{'icon-success': language.isDefault}"
                 (click)="setLanguage(language.hrLanguage,language.cmsLanguageCode)">{{ language.name }}</div>
          </div>
        </div>
        <div class="header-email" *ngIf="element.xlatElementType === 'E'">
          <i [ngClass]="element.iconName" (click)="goToLink(element)"></i>
        </div>
        <div class="header-avatar-container" *ngIf="element.xlatElementType === 'S'">
          <div class="pointer" (click)="showSubMenu(element);">
            <i [ngClass]="element.iconName"></i>
          </div>
          <ng-container *ngIf="element.showSubMenu">
            <div class="tooltip-container">
              <ng-container *ngFor="let subMenu of element.subMenu">
                <div class="tooltip-text" (click)="goToLinkSubMenu(subMenu,element)">
                  <ng-container *ngIf="subMenu.descrPermissionSB; else elseTemplate">
                    {{subMenu.descrPermissionSB}}
                  </ng-container>
                  <ng-template #elseTemplate>
                    {{subMenu.descrUrlExt}}
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <!-- CABECERA SANTANDER ESPAÑA: USUARIO -->
        <div class="header-avatar-photo" *ngIf="element.xlatElementType === 'U'" (click)="goToLink(element)">
          <div class="header-avatar-name">
            <div>{{ element.userFirstName }}</div>
            <div>{{ element.userLastName }} {{ element.userSecondLastName }}</div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="icon-header-item" style="cursor: pointer;">
      <i class="icn-sign-off" (click)="logout()"></i>
    </div>
    <div class="icon-header-item" *ngIf="headerService.showCloseButton">
      <button type="button" class="btn btn-primary" (click)="headerService.goToCallbackRoute()">
        {{'COMMON.BUTTONS.CLOSE' | translate}}
      </button>
    </div>

  </div>
</header>
<app-menu (toggleMenu)="toggleMenu($event)" *ngIf="openMenu"></app-menu>
