/* tslint:disable */
import { Injectable } from '@angular/core';
import { SilverBaseService } from '../silver-base-service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { SilverRequestBuilder } from '../silver-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ContentProviderResponse } from '../models/content-provider-response';


/**
 * Content provider
 */
@Injectable({
  providedIn: 'root',
})
export class ContentService extends SilverBaseService {
  constructor(
    config: EnviromentService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getContent
   */
  static readonly GetContentPath = '/accesses/contents/{contentId}/{language}';

  /**
   * Get content.
   *
   * Returns content by id and language
   *
   * This method provides customization options.
   * To access only the response body, use `getContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent$Response(params: {
    contentId: string;
    language: string;

  }): Observable<ContentProviderResponse> {

    const req = new SilverRequestBuilder(ContentService.GetContentPath, 'get');
    if (params) {
      req.path('contentId', params.contentId, {});
      req.path('language', params.language, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as ContentProviderResponse;
      })
    );
  }

  /**
   * Get content.
   *
   * Returns content by id and language
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getContent$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getContent(params: {
    contentId: string;
    language: string;

  }): Observable<ContentProviderResponse> {

    return this.getContent$Response(params).pipe(
      map(r => r as ContentProviderResponse)
    );
  }

}
