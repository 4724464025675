<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ errorTitle | translate }}</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.close('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p [innerHTML]="errMsg | translate"> </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="activeModal.close('Ok click')">Ok</button>
</div>
