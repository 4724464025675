import { TranslateService } from '@ngx-translate/core';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { FilterTableConfigEnum } from '../filter-table/filter-table.model';
import { FormControl, FormGroup } from '@angular/forms';
import { IColumnTableElement } from './custom-table.model';
import { CustomTableService } from './custom-table.service';

@Component({
  selector: 'onehr-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() columns: Array<IColumnTableElement>;
  @Input() rows: Array<any> = [];

  // Paginación
  @Input() p: number;
  @Input() totalRecords: number;
  @Input() showPagination = true;
  @Input() itemsPerPage = 6;
  @Output() pageSelected = new EventEmitter();
  // Ordenación
  @Input() showOrders = false;
  @Output() orderSelected = new EventEmitter<{ direction: any, column: any }>();

  // template detail
  @Input() detailTemplate: TemplateRef<any>;
  @Input() showCollapse: boolean[] = [];

  // tipo de tabla para elegir el template (OnInit -> switch)
  @Input() tableType: FilterTableConfigEnum;
  @Input() isScroll: boolean = false;
  @Input() showActionColumn = false;

  // Evento de acción en el row
  @Output() rowAction = new EventEmitter();

  previous = this.translateService.instant('COMMON.LABELS.PREVIOUS');
  next = this.translateService.instant('COMMON.LABELS.NEXT');

  // checks columna de acción
  showCheckAll = false;
  checkForm: FormGroup = new FormGroup({});
  checkAllValue = false;

  // Enums de tipos de configuración
  FilterTableConfigEnum = FilterTableConfigEnum;

  // Zebra filas
  oddTable = false;

  constructor(
    private readonly translateService: TranslateService,
    private readonly customTableService: CustomTableService
  ) {
  }

  ngOnInit() {
    if (this.tableType) {
      switch (this.tableType) {
        // EXAMPLE
        // case FilterTableConfigEnum.LDAP:
        //   this.isScroll = true;
        //   break;
        default:
          this.showActionColumn = true;
          this.showPagination = true;
          this.isScroll = false;
          break;
      }
    }
    if (this.columns[0].action?.includes('CHECK')) {
      this.showCheckAll = true;
      this.createChecksForm();
    }
  }

  /**
   * @description Método para resetear el formulario
   */
  createChecksForm() {
    this.checkForm = new FormGroup({});
    if (this.showCheckAll) {
      this.checkForm.addControl('checkAll', new FormControl(this.checkAllValue));
    }
  }

  /**
   * @description Al cambiar el valor del checkAll se setean todos los checkbox de las row, se vacía el array de checkbox seleccionados
   */
  checkAllChange() {
    this.checkAllValue = this.checkForm.controls.checkAll.value;
    this.customTableService.emptyCheckBoxArray();
    this.customTableService.setCheckAll(this.checkAllValue);
    Object.keys(this.checkForm.controls).forEach(ctrlName => {
      this.checkForm.controls[ctrlName].setValue(this.checkForm.controls.checkAll.value);
    });
  }

  /**
   * @description Evento capturado de los row de la tabla.
   * se usa el método setCheckboxArray del servicio customTableService en el caso que la acción sea 'check'.
   */
  rowActionEvent(event) {
    if (event.action && event.action === 'check') {
      this.checkForm.get('checkAll') && this.checkForm.get('checkAll').value && (event = { ...event, checkAll: true });
      this.customTableService.setCheckboxArray(event);
    } else {
      this.rowAction.emit(event);
    }
  }

  getDetail(index: number, rows: Array<any>) {
    this.showCollapse[index] = !this.showCollapse[index];
    if (this.showCollapse[index]) {
      this.closeOthers(rows, index);
    }
  }

  closeOthers(rows: Array<any>, index: number) {
    rows.forEach((element, i) => {
      if (i !== index) {
        this.showCollapse[i] = false;
      }
    });
  }

  pageChange(event: number) {
    this.showCollapse = [];
    this.pageSelected.emit(event - 1);
    if (this.checkForm.get('checkAll') && this.checkForm.controls.checkAll.value) {
      this.checkAllChange();
    }
  }

  checkRow(row) {
    this.rowAction.emit(row);
  }

  // Check for .table-odd or table-even
  ngOnChanges(changes: SimpleChanges): any {
    if (this.rows?.length % 2 !== 0) {
      this.oddTable = true;
    } else {
      this.oddTable = false;
    }
    return this.oddTable;
  }

  ngOnDestroy(): void {
    this.customTableService.removeOrdersArray();
  }
}
