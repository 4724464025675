import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'escapeSpaces'
})
export class EscapeSpacesPipe implements PipeTransform {
  transform(value: string, args?: any): string {
    return value.replace(/[^A-Za-z0-9]/, '');
  }
}
