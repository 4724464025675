import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CustomError, ErrorService } from '@app/core/services/error/error.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {
  text: string;
  textButton: string = 'COMMON.ERROR.BUTTON_TEXT';
  url: string = '';
  errParams: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private errorService: ErrorService, private router: Router) {
  }

  ngOnInit() {
    document.body.style.overflowY = 'scroll';
    this.errorService.getError()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((error: CustomError) => {
        if (error) {
          this.text = error.message;
          this.url = error.url;

          if (this.errorService.errParams) {
            this.errParams = this.errorService.errParams;
          }
        }
      });
  }

  goToRoute(url: string) {
    this.router.navigate([url], { replaceUrl: true });
  }

  ngOnDestroy() {
    this.errorService.resetError();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
