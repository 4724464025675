/* tslint:disable */
import { Injectable } from '@angular/core';
import { SilverBaseService } from '../silver-base-service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { SilverRequestBuilder } from '../silver-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HierarchyLevelAttachmentDto } from '../models/hierarchy-level-attachment-dto';
import { HierarchyLevelsDto } from '../models/hierarchy-levels-dto';
import { PageableHierarchyLevelDto } from '../models/pageable-hierarchy-level-dto';

@Injectable({
  providedIn: 'root',
})
export class HierarchyLevelsService extends SilverBaseService {
  constructor(
    config: EnviromentService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllCategoriesByCollectiveId
   */
  static readonly GetAllCategoriesByCollectiveIdPath = '/hierarchy-levels/categories';

  /**
   * Get all categories according to the logged user collective id.
   *
   * Returns all categories according to the logged user collective id.
   *
   * This method provides customization options.
   * To access only the response body, use `getAllCategoriesByCollectiveId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCategoriesByCollectiveId$Response(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableHierarchyLevelDto> {

    const req = new SilverRequestBuilder(HierarchyLevelsService.GetAllCategoriesByCollectiveIdPath, 'get');
    if (params) {
      req.query('language', params.language, {});
      req.query('page', params.page, {});
      req.query('size', params.size, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as PageableHierarchyLevelDto;
      })
    );
  }

  /**
   * Get all categories according to the logged user collective id.
   *
   * Returns all categories according to the logged user collective id.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAllCategoriesByCollectiveId$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCategoriesByCollectiveId(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableHierarchyLevelDto> {

    return this.getAllCategoriesByCollectiveId$Response(params).pipe(
      map(r => r as PageableHierarchyLevelDto)
    );
  }

  /**
   * Path part for operation getAllSubcategoriesByCollectiveId
   */
  static readonly GetAllSubcategoriesByCollectiveIdPath = '/hierarchy-levels/subcategories';

  /**
   * Get all subcategories according to the logged user collectiveId.
   *
   * Returns all subcategories according to the logged user collectiveId.
   *
   * This method provides customization options.
   * To access only the response body, use `getAllSubcategoriesByCollectiveId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSubcategoriesByCollectiveId$Response(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableHierarchyLevelDto> {

    const req = new SilverRequestBuilder(HierarchyLevelsService.GetAllSubcategoriesByCollectiveIdPath, 'get');
    if (params) {
      req.query('language', params.language, {});
      req.query('page', params.page, {});
      req.query('size', params.size, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as PageableHierarchyLevelDto;
      })
    );
  }

  /**
   * Get all subcategories according to the logged user collectiveId.
   *
   * Returns all subcategories according to the logged user collectiveId.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAllSubcategoriesByCollectiveId$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSubcategoriesByCollectiveId(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableHierarchyLevelDto> {

    return this.getAllSubcategoriesByCollectiveId$Response(params).pipe(
      map(r => r as PageableHierarchyLevelDto)
    );
  }

  /**
   * Path part for operation getAllSectionsByCollectiveId
   */
  static readonly GetAllSectionsByCollectiveIdPath = '/hierarchy-levels/sections';

  /**
   * Get all sections according to the logged user collectiveId.
   *
   * Returns all sections according to the logged user collectiveId.
   *
   * This method provides customization options.
   * To access only the response body, use `getAllSectionsByCollectiveId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSectionsByCollectiveId$Response(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableHierarchyLevelDto> {

    const req = new SilverRequestBuilder(HierarchyLevelsService.GetAllSectionsByCollectiveIdPath, 'get');
    if (params) {
      req.query('language', params.language, {});
      req.query('page', params.page, {});
      req.query('size', params.size, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as PageableHierarchyLevelDto;
      })
    );
  }

  /**
   * Get all sections according to the logged user collectiveId.
   *
   * Returns all sections according to the logged user collectiveId.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAllSectionsByCollectiveId$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSectionsByCollectiveId(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableHierarchyLevelDto> {

    return this.getAllSectionsByCollectiveId$Response(params).pipe(
      map(r => r as PageableHierarchyLevelDto)
    );
  }

  /**
   * Path part for operation getAllSubcategoriesByCategoryIdAndCollectiveId
   */
  static readonly GetAllSubcategoriesByCategoryIdAndCollectiveIdPath = '/hierarchy-levels/categories/{category_id}/subcategories';

  /**
   * Get all subcategories according to the selected category id and logged user collective id.
   *
   * Returns all subcategories according to the selected category id and logged user collective id.
   *
   * This method provides customization options.
   * To access only the response body, use `getAllSubcategoriesByCategoryIdAndCollectiveId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSubcategoriesByCategoryIdAndCollectiveId$Response(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * Category id is the id of a category
     */
    category_id: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableHierarchyLevelDto> {

    const req = new SilverRequestBuilder(HierarchyLevelsService.GetAllSubcategoriesByCategoryIdAndCollectiveIdPath, 'get');
    if (params) {
      req.query('language', params.language, {});
      req.path('category_id', params.category_id, {});
      req.query('page', params.page, {});
      req.query('size', params.size, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as PageableHierarchyLevelDto;
      })
    );
  }

  /**
   * Get all subcategories according to the selected category id and logged user collective id.
   *
   * Returns all subcategories according to the selected category id and logged user collective id.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAllSubcategoriesByCategoryIdAndCollectiveId$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSubcategoriesByCategoryIdAndCollectiveId(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * Category id is the id of a category
     */
    category_id: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableHierarchyLevelDto> {

    return this.getAllSubcategoriesByCategoryIdAndCollectiveId$Response(params).pipe(
      map(r => r as PageableHierarchyLevelDto)
    );
  }

  /**
   * Path part for operation getAllSectionsByCategoryIdAndSubcategoryIdAndCollectiveId
   */
  static readonly GetAllSectionsByCategoryIdAndSubcategoryIdAndCollectiveIdPath = '/hierarchy-levels/categories/{category_id}/subcategories/{subcategory_id}/sections';

  /**
   * Get all sections according to the selected category id and subcategory id and logged user collective id.
   *
   * Returns all sections according to the selected category id and subcategory id and logged user collective id.
   *
   * This method provides customization options.
   * To access only the response body, use `getAllSectionsByCategoryIdAndSubcategoryIdAndCollectiveId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSectionsByCategoryIdAndSubcategoryIdAndCollectiveId$Response(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * Category id is the id of a category
     */
    category_id: string;

    /**
     * Subcategory id is the id of a subcategory
     */
    subcategory_id: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableHierarchyLevelDto> {

    const req = new SilverRequestBuilder(HierarchyLevelsService.GetAllSectionsByCategoryIdAndSubcategoryIdAndCollectiveIdPath, 'get');
    if (params) {
      req.query('language', params.language, {});
      req.path('category_id', params.category_id, {});
      req.path('subcategory_id', params.subcategory_id, {});
      req.query('page', params.page, {});
      req.query('size', params.size, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as PageableHierarchyLevelDto;
      })
    );
  }

  /**
   * Get all sections according to the selected category id and subcategory id and logged user collective id.
   *
   * Returns all sections according to the selected category id and subcategory id and logged user collective id.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAllSectionsByCategoryIdAndSubcategoryIdAndCollectiveId$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSectionsByCategoryIdAndSubcategoryIdAndCollectiveId(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * Category id is the id of a category
     */
    category_id: string;

    /**
     * Subcategory id is the id of a subcategory
     */
    subcategory_id: string;

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableHierarchyLevelDto> {

    return this.getAllSectionsByCategoryIdAndSubcategoryIdAndCollectiveId$Response(params).pipe(
      map(r => r as PageableHierarchyLevelDto)
    );
  }

  /**
   * Path part for operation getWithAttachmentsByCategoryAndSubCategoryAndSection
   */
  static readonly GetWithAttachmentsByCategoryAndSubCategoryAndSectionPath = '/hierarchy-levels/categories/{category_id}/subcategories/{subcategory_id}/attachments';

  /**
   * Get if the combination needs to attach documents (TRUE OR FALSE).
   *
   * Returns if the combination needs to attach documents (TRUE OR FALSE)
   *
   * This method provides customization options.
   * To access only the response body, use `getWithAttachmentsByCategoryAndSubCategoryAndSection()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWithAttachmentsByCategoryAndSubCategoryAndSection$Response(params: {

    /**
     * Section id is the id of a Section
     */
    sectionId?: string;

    /**
     * Category id is the id of a category
     */
    category_id: string;

    /**
     * Subcategory id is the id of a subcategory
     */
    subcategory_id: string;

  }): Observable<HierarchyLevelAttachmentDto> {

    const req = new SilverRequestBuilder(HierarchyLevelsService.GetWithAttachmentsByCategoryAndSubCategoryAndSectionPath, 'get');
    if (params) {
      req.query('sectionId', params.sectionId, {});
      req.path('category_id', params.category_id, {});
      req.path('subcategory_id', params.subcategory_id, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as HierarchyLevelAttachmentDto;
      })
    );
  }

  /**
   * Get if the combination needs to attach documents (TRUE OR FALSE).
   *
   * Returns if the combination needs to attach documents (TRUE OR FALSE)
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getWithAttachmentsByCategoryAndSubCategoryAndSection$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getWithAttachmentsByCategoryAndSubCategoryAndSection(params: {

    /**
     * Section id is the id of a Section
     */
    sectionId?: string;

    /**
     * Category id is the id of a category
     */
    category_id: string;

    /**
     * Subcategory id is the id of a subcategory
     */
    subcategory_id: string;

  }): Observable<HierarchyLevelAttachmentDto> {

    return this.getWithAttachmentsByCategoryAndSubCategoryAndSection$Response(params).pipe(
      map(r => r as HierarchyLevelAttachmentDto)
    );
  }

  /**
   * Path part for operation getCategoryDouble
   */
  static readonly GetCategoryDoublePath = '/hierarchy-levels/narrow/{narrow_id}';

  /**
   * Get category double category-subcategory of the logged user.
   *
   * Returns category info of the logged user.
   *
   * This method provides customization options.
   * To access only the response body, use `getCategoryDouble()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoryDouble$Response(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * NarrowId is the id of a double category-subcategory
     */
    narrow_id: number;

  }): Observable<HierarchyLevelsDto> {

    const req = new SilverRequestBuilder(HierarchyLevelsService.GetCategoryDoublePath, 'get');
    if (params) {
      req.query('language', params.language, {});
      req.path('narrow_id', params.narrow_id, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as HierarchyLevelsDto;
      })
    );
  }

  /**
   * Get category double category-subcategory of the logged user.
   *
   * Returns category info of the logged user.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getCategoryDouble$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoryDouble(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * NarrowId is the id of a double category-subcategory
     */
    narrow_id: number;

  }): Observable<HierarchyLevelsDto> {

    return this.getCategoryDouble$Response(params).pipe(
      map(r => r as HierarchyLevelsDto)
    );
  }

  /**
   * Path part for operation getCategoryTriple
   */
  static readonly GetCategoryTriplePath = '/hierarchy-levels/short/{short_id}';

  /**
   * Get category triple category-subcategory-section of the logged user collective.
   *
   * Returns category info of the logged user.
   *
   * This method provides customization options.
   * To access only the response body, use `getCategoryTriple()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoryTriple$Response(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * ShortId is the id of a triple category-subcategory-section
     */
    short_id: number;

  }): Observable<HierarchyLevelsDto> {

    const req = new SilverRequestBuilder(HierarchyLevelsService.GetCategoryTriplePath, 'get');
    if (params) {
      req.query('language', params.language, {});
      req.path('short_id', params.short_id, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as HierarchyLevelsDto;
      })
    );
  }

  /**
   * Get category triple category-subcategory-section of the logged user collective.
   *
   * Returns category info of the logged user.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getCategoryTriple$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoryTriple(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * ShortId is the id of a triple category-subcategory-section
     */
    short_id: number;

  }): Observable<HierarchyLevelsDto> {

    return this.getCategoryTriple$Response(params).pipe(
      map(r => r as HierarchyLevelsDto)
    );
  }

  /**
   * Path part for operation getCategoryBySubcategory
   */
  static readonly GetCategoryBySubcategoryPath = '/hierarchy-levels/subcategories/{subcategory_id}';

  /**
   * Get category by subcategoryId of the logged user collective.
   *
   * Returns category by subcategoryId info of the logged user.
   *
   * This method provides customization options.
   * To access only the response body, use `getCategoryBySubcategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoryBySubcategory$Response(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * With the id of the subcategory, the search is carried out to return the category to which it belongs
     */
    subcategory_id: string;

  }): Observable<HierarchyLevelsDto> {

    const req = new SilverRequestBuilder(HierarchyLevelsService.GetCategoryBySubcategoryPath, 'get');
    if (params) {
      req.query('language', params.language, {});
      req.path('subcategory_id', params.subcategory_id, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as HierarchyLevelsDto;
      })
    );
  }

  /**
   * Get category by subcategoryId of the logged user collective.
   *
   * Returns category by subcategoryId info of the logged user.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getCategoryBySubcategory$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoryBySubcategory(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * With the id of the subcategory, the search is carried out to return the category to which it belongs
     */
    subcategory_id: string;

  }): Observable<HierarchyLevelsDto> {

    return this.getCategoryBySubcategory$Response(params).pipe(
      map(r => r as HierarchyLevelsDto)
    );
  }

  /**
   * Path part for operation getCategoryAndSubCategoryBySection
   */
  static readonly GetCategoryAndSubCategoryBySectionPath = '/hierarchy-levels/sections/{section_id}';

  /**
   * Get category and subcategory by sectionId of the logged user collective.
   *
   * Returns category and subcategory by sectionId info of the logged user.
   *
   * This method provides customization options.
   * To access only the response body, use `getCategoryAndSubCategoryBySection()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoryAndSubCategoryBySection$Response(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * With the id of the section, the search is carried out to return the category and subcategory to which it belongs
     */
    section_id: string;

  }): Observable<HierarchyLevelsDto> {

    const req = new SilverRequestBuilder(HierarchyLevelsService.GetCategoryAndSubCategoryBySectionPath, 'get');
    if (params) {
      req.query('language', params.language, {});
      req.path('section_id', params.section_id, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as HierarchyLevelsDto;
      })
    );
  }

  /**
   * Get category and subcategory by sectionId of the logged user collective.
   *
   * Returns category and subcategory by sectionId info of the logged user.
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getCategoryAndSubCategoryBySection$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getCategoryAndSubCategoryBySection(params: {

    /**
     * Translation language
     */
    language: string;

    /**
     * With the id of the section, the search is carried out to return the category and subcategory to which it belongs
     */
    section_id: string;

  }): Observable<HierarchyLevelsDto> {

    return this.getCategoryAndSubCategoryBySection$Response(params).pipe(
      map(r => r as HierarchyLevelsDto)
    );
  }

}
