import { NgxPaginationModule } from 'ngx-pagination';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  NgbAccordion,
  NgbActiveModal,
  NgbCarousel,
  NgbCollapseModule,
  NgbModule,
  NgbPanel,
  NgbPanelContent, NgbPanelHeader, NgbPanelTitle, NgbPanelToggle
} from '@ng-bootstrap/ng-bootstrap';
import { OrderPipe } from './pipes/order/order.pipe';
import { SafePipe } from './pipes/safe-html/safe-html.pipe';
import { MenuComponent } from './components/menu/menu.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BoxImgComponent } from './components/box-img/box-img.component';
import { BoxComponent } from './components/box/box.component';
import { OkMsgComponent } from './components/ok-msg/ok-msg.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { ModalComponent } from './components/modal/modal.component';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { InputDatepickerComponent } from './components/input-datepicker/input-datepicker.component';
import { ErrorComponent } from './components/error/error.component';
import { ClickOutsideDirective } from './directives/clickOutside/click-outside.directive';
import { BoxTitleFormComponent } from './components/box-title-form/box-title-form.component';
import { InputTextComponent } from './components/input-text/input-text.component';
import { ModalSuccessComponent } from './components/modal-success/modal-success.component';
import { FilterTableComponent } from './components/filter-table/filter-table.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { InputCheckBoxComponent } from './components/input-checkbox/input-checkbox.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { RowExampleCheckAllComponent } from './components/row-example-check-all/row-example-check-all.component';
import { ListItemComponent } from '@shared/components/list-item/list-item.component';
import { ItemDetailComponent } from './components/item-detail/item-detail.component';
import { ItemTranslationComponent } from './components/item-translation/item-translation.component';
import { StatusPipe } from '@shared/pipes/status/status.pipe';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { StepComponent } from './components/step/step.component';
import { StepsServiceModule } from '@shared/components/step/steps-service.module';
import { ModalErrorComponent } from './components/modal-error/modal-error.component';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';
import { EscapeSpacesPipe } from '@shared/pipes/escape-spaces/escape-spaces.pipe';
import {
  RowConsultRequestComponent
} from '@shared/components/custom-table/custom-rows/row-consult-request/row-consult-request.component';
import { CarouselComponent } from './components/carousel/carousel.component';

/**
 * This module should not contain providers (they'd be on core module)
 * and must be imported on every module from (+modules) so as
 * to use its elements (components, directives and pipes)
 *
 * This module also exports Flex Layout and Material resources from
 * visuals module, so it is mandatory to import it on every module
 * from (+modules) that needs to use them
 */
@NgModule({
  declarations: [/* Components, directives and pipes to be shared */
    MenuComponent,
    OrderPipe,
    SafePipe,
    BreadcrumbComponent,
    BoxImgComponent,
    BoxComponent,
    OkMsgComponent,
    TooltipComponent,
    InputDatepickerComponent,
    ModalComponent,
    CustomTableComponent,
    UploadFileComponent,
    MenuComponent,
    ErrorComponent,
    ClickOutsideDirective,
    BoxTitleFormComponent,
    InputTextComponent,
    ModalSuccessComponent,
    BoxTitleFormComponent,
    FilterTableComponent,
    SearchInputComponent,
    SearchFilterComponent,
    InputCheckBoxComponent,
    PageTitleComponent,
    RowExampleCheckAllComponent,
    ListItemComponent,
    ItemDetailComponent,
    ItemDetailComponent,
    ItemTranslationComponent,
    StatusPipe,
    InputNumberComponent,
    RowConsultRequestComponent,
    StepComponent,
    ModalErrorComponent,
    ModalConfirmationComponent,
    EscapeSpacesPipe,
    CarouselComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    NgxPaginationModule,
    StepsServiceModule
  ],
  exports: [/* Components, directives and pipes to be shared */
    CommonModule,
    MenuComponent,
    OrderPipe,
    SafePipe,
    EscapeSpacesPipe,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbComponent,
    BoxImgComponent,
    BoxComponent,
    OkMsgComponent,
    TooltipComponent,
    InputDatepickerComponent,
    ModalComponent,
    CustomTableComponent,
    UploadFileComponent,
    MenuComponent,
    ErrorComponent,
    ClickOutsideDirective,
    NgbCollapseModule,
    BoxTitleFormComponent,
    InputTextComponent,
    ModalSuccessComponent,
    BoxTitleFormComponent,
    FilterTableComponent,
    SearchInputComponent,
    SearchFilterComponent,
    InputCheckBoxComponent,
    PageTitleComponent,
    RowConsultRequestComponent,
    RowExampleCheckAllComponent,
    ListItemComponent,
    ItemDetailComponent,
    ItemTranslationComponent,
    ItemDetailComponent,
    ItemTranslationComponent,
    StatusPipe,
    InputNumberComponent,
    NgxPaginationModule,
    RowConsultRequestComponent,
    StepComponent,
    ModalErrorComponent,
    ModalConfirmationComponent,
    NgbAccordion,
    NgbPanel,
    NgbPanelContent,
    NgbPanelTitle,
    NgbPanelHeader,
    NgbPanelToggle,
    NgbCarousel,
    CarouselComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  entryComponents: [ModalComponent, ModalSuccessComponent, ModalErrorComponent, ModalConfirmationComponent],
  providers: [
    NgbActiveModal
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [/* In case (rare) we want to share providers too, we'll use the .forRoot() method when importing the module*/]
    };
  }
}
