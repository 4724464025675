import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'onehr-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent implements OnInit {
  @Input() label: string;
  @Input() placeholder: string = '';
  @Input() controlName: string;
  @Input() min: number;
  @Input() max: number;
  @Input() value: string;
  @Input() disabled: boolean;
  @Input() parentForm: FormGroup;
  @Output() search = new EventEmitter();

  ngOnInit(): void {
    this.parentForm.addControl(
      this.controlName,
      new FormControl({ value: this.value, disabled: this.disabled })
    );
  }

  inputChanges(control: string) {
    const inputValue: string = this.parentForm.get(control).value;
    this.search.emit({ controlName: control, item: { value: inputValue, id: inputValue } });
  }
}
