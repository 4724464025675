
<i class="tooltip-info" [ngClass]="icon? icon: 'icn-accede-info'">
  <span *ngIf="isString else arrayContent">
    <div class="tooltip-info-text string">
      {{ content }}
    </div>
  </span>
</i>

<ng-template #arrayContent>
    <div class="tooltip-info-text">
      <p *ngFor="let item of content">
        {{ item }}
      </p>
    </div>
</ng-template>
