/* tslint:disable */
import { Injectable } from '@angular/core';
import { SilverBaseService } from '../silver-base-service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { SilverRequestBuilder } from '../silver-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PageableCombo } from '../models/pageable-combo';
import { Pdf } from '../models/pdf';


/**
 * Payroll receipts.
 */
@Injectable({
  providedIn: 'root',
})
export class PayrollService extends SilverBaseService {
  constructor(
    config: EnviromentService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getYearslLoggedUser
   */
  static readonly GetYearslLoggedUserPath = '/payroll-receipts/years';

  /**
   * Get payroll years.
   *
   * Returns all years
   *
   * This method provides customization options.
   * To access only the response body, use `getYearslLoggedUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getYearslLoggedUser$Response(params?: {

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableCombo> {

    const req = new SilverRequestBuilder(PayrollService.GetYearslLoggedUserPath, 'get');
    if (params) {
      req.query('page', params.page, {});
      req.query('size', params.size, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as PageableCombo;
      })
    );
  }

  /**
   * Get payroll years.
   *
   * Returns all years
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getYearslLoggedUser$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getYearslLoggedUser(params?: {

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;

  }): Observable<PageableCombo> {

    return this.getYearslLoggedUser$Response(params).pipe(
      map(r => r as PageableCombo)
    );
  }

  /**
   * Path part for operation getMonthslLoggedUser
   */
  static readonly GetMonthslLoggedUserPath = '/payroll-receipts';

  /**
   * Get payroll months.
   *
   * Returns all month by year
   *
   * This method provides customization options.
   * To access only the response body, use `getMonthslLoggedUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonthslLoggedUser$Response(params?: {

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;
    'year.equals'?: number;

  }): Observable<PageableCombo> {

    const req = new SilverRequestBuilder(PayrollService.GetMonthslLoggedUserPath, 'get');
    if (params) {
      req.query('page', params.page, {});
      req.query('size', params.size, {});
      req.query('year.equals', params['year.equals'], {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as PageableCombo;
      })
    );
  }

  /**
   * Get payroll months.
   *
   * Returns all month by year
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getMonthslLoggedUser$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getMonthslLoggedUser(params?: {

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;
    'year.equals'?: number;

  }): Observable<PageableCombo> {

    return this.getMonthslLoggedUser$Response(params).pipe(
      map(r => r as PageableCombo)
    );
  }

  /**
   * Path part for operation downalodPayrollReceiptPdf
   */
  static readonly DownalodPayrollReceiptPdfPath = '/payroll-receipts/year/{year}/month/{month}';

  /**
   * Download payroll receipt pdf.
   *
   * Returns pdf by year and month
   *
   * This method provides customization options.
   * To access only the response body, use `downalodPayrollReceiptPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  downalodPayrollReceiptPdf$Response(params: {
    year: number;
    month: number;

  }): Observable<Pdf> {

    const req = new SilverRequestBuilder(PayrollService.DownalodPayrollReceiptPdfPath, 'get');
    if (params) {
      req.path('year', params.year, {});
      req.path('month', params.month, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Pdf;
      })
    );
  }

  /**
   * Download payroll receipt pdf.
   *
   * Returns pdf by year and month
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `downalodPayrollReceiptPdf$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  downalodPayrollReceiptPdf(params: {
    year: number;
    month: number;

  }): Observable<Pdf> {

    return this.downalodPayrollReceiptPdf$Response(params).pipe(
      map(r => r as Pdf)
    );
  }

}
