import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ShellModule } from '@shell/shell.module';
import { CoreModule } from '@core/core.module';
import { AppVersionModule } from '@modules/app-version/app-version.module';
import { SecurityModule } from '@devstack-angular/security';
/* to load routes */

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    SecurityModule,
    ShellModule,
    HttpClientModule,
    CoreModule.forRoot(),
    AppVersionModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
}
