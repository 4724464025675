/* tslint:disable */
import { Injectable } from '@angular/core';
import { SilverBaseService } from '../silver-base-service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { SilverRequestBuilder } from '../silver-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MunicipalityDtoPage } from '../models/municipality-dto-page';

@Injectable({
  providedIn: 'root',
})
export class MunicipalitiesService extends SilverBaseService {
  constructor(
    config: EnviromentService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllMunicipalitiesUsingGet
   */
  static readonly GetAllMunicipalitiesUsingGetPath = '/municipalities';

  /**
   * getAllMunicipalities.
   *
   *
   *
   * This method provides customization options.
   * To access only the response body, use `getAllMunicipalitiesUsingGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMunicipalitiesUsingGet$Response(params: {

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;
    'countryId.equals': string;
    'stateId.equals'?: string;
    'municipalityId.contains'?: string;
    'municipalityDesc.contains'?: string;

  }): Observable<MunicipalityDtoPage> {

    const req = new SilverRequestBuilder(MunicipalitiesService.GetAllMunicipalitiesUsingGetPath, 'get');
    if (params) {
      req.query('page', params.page, {});
      req.query('size', params.size, {});
      req.query('countryId.equals', params['countryId.equals'], {});
      req.query('stateId.equals', params['stateId.equals'], {});
      req.query('municipalityId.contains', params['municipalityId.contains'], {});
      req.query('municipalityDesc.contains', params['municipalityDesc.contains'], {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as MunicipalityDtoPage;
      })
    );
  }

  /**
   * getAllMunicipalities.
   *
   *
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `getAllMunicipalitiesUsingGet$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMunicipalitiesUsingGet(params: {

    /**
     * page
     */
    page?: number;

    /**
     * size
     */
    size?: number;
    'countryId.equals': string;
    'stateId.equals'?: string;
    'municipalityId.contains'?: string;
    'municipalityDesc.contains'?: string;

  }): Observable<MunicipalityDtoPage> {

    return this.getAllMunicipalitiesUsingGet$Response(params).pipe(
      map(r => r as MunicipalityDtoPage)
    );
  }

}
