import { Component } from '@angular/core';
import { AppUrls } from '@config/app-urls.config';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'silver-cookie-modal',
  templateUrl: './cookie-modal.component.html',
  styleUrls: ['./cookie-modal.component.scss']
})
export class CookieModalComponent {
  AppUrls: any = AppUrls;

  constructor(public activeModal: NgbActiveModal) {
  }
}
