import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDate, NgbDateParserFormatter, NgbDatepickerConfig, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { UtilsService } from '@core/services/utils/utils.service';
import { I18n, CustomDatepickerI18n } from './languageDatePikerService';
import { NgbDateFRParserFormatter } from './formatDatePikerService';

@Component({
  selector: 'app-input-datepicker',
  templateUrl: './input-datepicker.component.html',
  styleUrls: ['./input-datepicker.component.scss'],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }
  ]
})
export class InputDatepickerComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() controlName: string;
  @Input() minDateSelected: NgbDateStruct;
  @Input() maxDateSelected: NgbDateStruct;
  @Input() markDisabled?: any;
  @Input() readonly: boolean;
  @Input() btnBorrar = false;
  @Input() inputValue?: NgbDateStruct;
  @Input() selectedValues;
  @Input() label;
  @Input() forcePattern = false;
  @Input() required = false;
  @Input() navigation: 'arrows' | 'select' | 'none' = 'select';
  @Input() disableInput: boolean;
  @Input() placeholder = 'dd/mm/yyyy'
  @Output() dateSelected = new EventEmitter();
  @Output() closed = new EventEmitter();

  @ViewChild('dataPicker') dataPicker;
  disabledDelete = false;
  hasDate = false;

  constructor(private _i18n: I18n,
              config: NgbDatepickerConfig,
              private utilsService: UtilsService
  ) {
    // Disabled weekend days (saturday & sunday).
    // config.markDisabled = (date: NgbDate) => calendar.getWeekday(date) >= 6;
    // Navigate between months only with arrows, not select.
    config.navigation = this.navigation;
    // Set language
    this.language = sessionStorage.getItem('language');
  }

  set language(language: string) {
    this._i18n.language = language;
  }

  get language() {
    return this._i18n.language;
  }

  ngOnInit() {
    if (!this.parentForm.get(this.controlName)) {
      this.setControlInParentForm();
    }

    this.minDateSelected = this.minDateSelected ? this.minDateSelected : { year: 1900, month: 1, day: 1 };
  }

  private initEmptyDateInput(): void {
    this.parentForm.addControl(
      this.controlName,
      new FormControl({
        value: ''
      })
    );
  }

  onDateSelection(evt, inputSelected) {
    const date = {
      id: `${ evt.year }-${ this.formatDate(evt.month) }-${ this.formatDate(evt.day) }`,
      inputDate: inputSelected,
      value: evt
    };
    this.hasDate = true;
    this.dateSelected.emit({ item: date, controlName: inputSelected });
  }

  deleteDate() {
    this.hasDate = false;
    this.parentForm.controls[this.controlName].setValue('');
    const date = '';
    this.dateSelected.emit({ item: date, controlName: this.controlName });
  }

  closeCalendar(inputSelected) {
    this.closed.emit(inputSelected);
  }

  isNumber(value: any): boolean {
    return !isNaN(this.toInteger(value));
  }

  toInteger(value: any): number {
    return parseInt(`${ value }`, 10);
  }

  private formatDate(date_: number): string | number {
    return date_.toString().length < 2 ? '0' + date_ : date_;
  }

  toggle(d) {
    this.language = sessionStorage.getItem('language');
    d.toggle();
  }

  private setControlInParentForm(): void {
    if (this.inputValue instanceof NgbDate) {
      this.parentForm.addControl(
        this.controlName,
        new FormControl({
          value: this.inputValue ? this.inputValue : ''
        })
      );

      if (this.required) {
        this.parentForm.controls[this.controlName].setValidators([Validators.required]);
      }

      if (this.parentForm.get(this.controlName).disabled) {
        this.disabledDelete = true;
      }
    } else if (this.inputValue) {
      console.warn('Input date is not in the recommended format, trying to convert to Ngbdate...');
      this.inputValue = this.utilsService.convertDateToNgbDate(this.inputValue as any);
    } else if (this.parentForm.get(this.controlName)?.value) {
      const data = this.parentForm.get(this.controlName).value;

      this.inputValue = this.utilsService.convertDateToNgbDate(data);
    } else {
      this.initEmptyDateInput();
    }
  }

  changeDate(): void {
    const value = this.dataPicker?.nativeElement?.value;

    if (value && this.forcePattern) {
      if (!value.match('^(?:(?:31(\\/)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$')) {
        this.parentForm.get(this.controlName).setErrors({
          patternBirthdate: true
        });
        this.parentForm.updateValueAndValidity();
      } else {
        this.parentForm.get(this.controlName).setErrors(null);
        this.parentForm.updateValueAndValidity();
      }
    }
  }
}
