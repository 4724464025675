<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ 'LEGALS.COOKIE.CONFIG.TITLE' | translate }}</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" style="text-align: left;">
  <form [formGroup]="formRef">
    <label class="check">
      <input type="checkbox" formControlName="global">
      <span class="checkmark checked"></span>
      <span>{{ 'LEGALS.COOKIE.CONFIG.GLOBAL_TITLE' | translate }}</span>
    </label>
    <p>
      {{ 'LEGALS.COOKIE.CONFIG.GLOBAL_TEXT' | translate }}
    </p>
    <label class="check">
      <input type="checkbox" formControlName="analyt">
      <span class="checkmark" [ngClass]="{'checked': analytValue}"></span>
      <span>{{ 'LEGALS.COOKIE.CONFIG.ANALYT_TITLE' | translate }}</span>
    </label>
    <p>
      {{ 'LEGALS.COOKIE.CONFIG.ANALYT_TEXT' | translate }}
      <a href="{{ 'LEGALS.COOKIE.CONFIG.ANALYT_LINK' | translate }}" target="_blank" rel="noopener noreferrer">
        {{ 'LEGALS.COOKIE.CONFIG.ANALYT_LINK' | translate }}
      </a>
    </p>
    <p>
      {{ 'LEGALS.COOKIE.MODAL.TEXT_POLICY' | translate }}
      <a [routerLink]="['/', AppUrls.AppLegals, AppUrls.AppCookie]" (click)="activeModal.close('policy')">{{ 'LEGALS.COOKIE.MODAL.LINK2' | translate }}</a>
      {{ 'LEGALS.COOKIE.MODAL.TEXT_POLICY2' | translate }}
    </p>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger"
          (click)="activeModal.close(false)">{{ 'LEGALS.COOKIE.CONFIG.BUTTON_CLOSE' | translate }}</button>
  <button type="button" class="btn btn-primary"
          (click)="markSelected()">{{ 'LEGALS.COOKIE.CONFIG.BUTTON_ACCEPT' | translate }}</button>
</div>
