import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { Action, BoxSlug, ListBox, ListConfig } from '@shared/components/list-item/list-item.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Actions } from '@shared/components/list-item/list-item.enum';
import { BoxTitleForm } from '@shared/components/box-title-form/box-title-form.model';
import { UtilsService } from '@app/core/services/utils/utils.service';
import { AttachmentsService } from '@app/core/api/SILVER/services';
import { Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

interface ItemCtx {
  dataForm: BoxTitleForm[] | BoxTitleForm;
}

@Component({
  selector: 'onehr-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title: string;
  @Input() isNewItemEnabled = true;
  @Input() customItemTemplate: TemplateRef<any>;
  @Input() closeBoxes = true;
  @Input() isDisable: boolean;
  @Input() itemList: ListBox[] = [];
  @Input() listConfig: ListConfig;
  @Input() isNewMode = false;

  @Output() saveItemEvent: EventEmitter<any> = new EventEmitter();
  @Output() newItemEvent: EventEmitter<any> = new EventEmitter();
  @Output() cancelItemEvent: EventEmitter<any> = new EventEmitter();
  @Output() editItemEvent: EventEmitter<ListBox> = new EventEmitter();
  @Output() deleteItemEvent: EventEmitter<any> = new EventEmitter();
  @Output() selectItemEvent: EventEmitter<any> = new EventEmitter();
  @Output() selectFileEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteFileEvent: EventEmitter<any> = new EventEmitter();

  readonly BoxSlug = BoxSlug;

  destroy$ = new Subject();
  itemName = 'ITEM_LABEL';
  itemFormData;
  itemFormGroup: FormGroup;
  subList: ListConfig = null;
  itemCtxForTemplate: { dataForm: BoxTitleForm [] | BoxTitleForm };
  itemToSave: any = {};
  isArray: boolean;
  subSectionContext = {
    subSectionOpen: false
  };
  makeRequestFormGroup: FormGroup = new FormGroup({});

  constructor(private utilsService: UtilsService,
              private attachmentsService: AttachmentsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.closeBoxes && changes.closeBoxes.currentValue) || this.isDisable) {
      this.closeAllBoxes();
    }
    if (changes.isNewMode?.currentValue) {
      this.addNewItem();
    }
  }

  ngOnInit(): void {
    this.itemName = this.listConfig?.itemName;
    this.itemFormData = this.listConfig?.itemFormData;
    this.isArray = Array.isArray(this.itemFormData);
    this.itemFormGroup = this.listConfig?.itemFormGroup;
    if (this.listConfig?.hasOwnProperty('childList') && this.listConfig?.childList) {
      this.subList = this.listConfig?.childList;
    }
  }

  actionFun(action: Action, item: ListBox, i) {
    switch (action.action) {
      case Actions.Edit:
        this.closeAllBoxes();
        this.editItemEvent.emit(item);
        break;
      default:
        action.onActionExecute(i);
        this.deleteItemEvent.emit(item);
        break;
    }
  }

  saveEdit() {
    this.saveItemEvent.emit();
    this.itemFormGroup.reset();

    if (this.closeBoxes) {
      this.closeAllBoxes();
    }
  }

  toggleNewMode() {
    this.isNewMode = !this.isNewMode;
  }

  addNewItem(): void {
    this.initNewItemForm();
    this.closeAllBoxes();
    this.newItemEvent.emit();
    this.itemCtxForTemplate = {
      dataForm: this.itemFormData
    };
    this.toggleNewMode();
  }

  getItemCtx(): ItemCtx {
    if (!this.itemToSave.isEdit) {
      const dataFormForEdit = this.listConfig.itemFormData;

      this.itemToSave = {};
      this.itemToSave.isEdit = true;
      this.itemCtxForTemplate = {
        dataForm: dataFormForEdit
      };
    }

    return this.itemCtxForTemplate;
  }

  cancelItem() {
    this.closeAllBoxes();
    this.cancelItemEvent.emit();
    this.itemFormGroup.reset();
  }

  closeAllBoxes(): void {
    this.isNewMode = false;
    this.closeOtherRowInEditMode();
    this.initNewItemForm();
  }

  setItemFromSearch($event: any): void {
    this.selectItemEvent.emit($event);
  }

  toggleSubsection() {
    this.subSectionContext.subSectionOpen = !this.subSectionContext.subSectionOpen;
  }

  openFile(event) {
    this.utilsService.openFile(event.value, event.filename, event.filetype);
  }

  deleteFile(event){
    this.deleteFileEvent.emit(event);
  }

  selectedFile(event) {
    this.selectFileEvent.emit(event);
  }

  downloadFile(id, name) {
    const params = {
      attachment_id: id
    }
    debugger;
    this.attachmentsService.getCaseAttachmentUsingGet(params)
      .pipe(
        takeUntil(this.destroy$),
        catchError(err => {
          this.manageError(err);
          throw new Error(err);
        })
      )
      .subscribe(resp => {
        const blobx = new Blob([resp], { type: 'text/plain' });
        const url= URL.createObjectURL(blobx);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      });
  }

  private closeOtherRowInEditMode(): void {
    if (this.itemList?.length) {
      for (const element of this.itemList) {
        if (element.hasOwnProperty('isEditMode')) {
          element.isEditMode = false;
        }
      }
    }
  }

  private initNewItemForm(): void {
    this.itemToSave = {};
  }

  private manageError(err: any): void {
    const errObj = err.error;
    const errCode = errObj.errorCode;

    switch (errCode) {
      default:
        console.error('ErrCode not mapped: ', errCode);
        break;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
