import { Pipe, PipeTransform } from '@angular/core';
import { TranslationsService } from '@core/services/translations/translations.service';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {
  constructor(private ts: TranslationsService) {
  }

  transform(value: 'A' | 'I', ...args: any[]): unknown {
    switch (value) {
      case 'A':
        return this.ts.instant('COMMON.STATUS.ACTIVE');
      case 'I':
        return this.ts.instant('COMMON.STATUS.INACTIVE');
    }
  }
}
