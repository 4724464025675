/**
 * @description Iterfaz del objeto de ordenación que usa el Behavior Subject para devolver al componente arrows si está seteado
 * @export
 * @interface Order
 */
export interface Order {
  id: string;
  type: DirectionEnum;
  selected: boolean;
}

/**
 * @description Enum del tipo de dato dirección (ordenación tabla)
 * @export
 * @enum {number}
 */
export enum DirectionEnum {
  "ASC" = "ASC",
  "DESC" = "DESC",
}

/**
 * @description Enum del encabezado del columnado de la tabla, son los id's q se pasan al servicio de ordenación
 * @export
 * @enum {number}
 */
export enum TableColumnsEnum {
  // EXAMPLE
  // processName = 'process'
  id = "id",
  statusIcon = "statusIcon",
  status = "status",
  startDate = "startDate",
  endDate = "endDate",
  category = "category",
  subcategory = "subcategory",
  section = "section",
  description = "description",
  comment = "comment",
}

/**
 * @description Interfaz de configuración de la tabla custom-table
 * @export
 * @interface ITableConfig
 */
export interface ITableConfig {
  [propName: string]: IColumnTableElement[];
}

/**
 * @description Interfaz de elemento de configuración de la tabla custom-table
 * @export
 * @interface IColumnTableElement
 */
export interface IColumnTableElement {
  value: string;
  action?: TableActionColumnEnum;
  enum?: TableColumnsEnum;
  width?: string;
}

/**
 * @description Enum para los tipos de acciones en la columna de acción (si procede)
 * @export
 * @enum {number}
 */
export enum TableActionColumnEnum {
  EDIT = "EDIT",
  EDIT_CHECK = "EDIT_CHECK",
  SHOW = "SHOW",
  SHOW_CHECK = "SHOW_CHECK",
  CHECK = "CHECK",
  REMOVE = "REMOVE",
  REMOVE_EDIT = "REMOVE_EDIT",
  USER = "USER",
  DELETE = "DELETE",
  DEACTIVATE = "DEACTIVATE",
}

/**
 * @description Datos de configuración de las columnas del custom-table
 * @export
 * @constant {ITableConfig} TABLE_CONFIG
 */
export const TABLE_ACTIONS_CONFIG: ITableConfig = {
  TABLE_EDIT: [{ value: null, action: TableActionColumnEnum.EDIT }],
  TABLE_EDIT_CHECK: [{ value: null, action: TableActionColumnEnum.EDIT_CHECK }],
  TABLE_SHOW: [{ value: null, action: TableActionColumnEnum.SHOW }],
  TABLE_SHOW_CHECK: [{ value: null, action: TableActionColumnEnum.SHOW_CHECK }],
  TABLE_CHECK: [{ value: null, action: TableActionColumnEnum.CHECK }],
  TABLE_REMOVE_EDIT: [
    { value: null, action: TableActionColumnEnum.REMOVE_EDIT },
  ],
  TABLE_REMOVE: [{ value: null, action: TableActionColumnEnum.REMOVE }],
  TABLE_USER: [{ value: null, action: TableActionColumnEnum.USER }],
};
export const LDAP_COLUMNS = [
  // EXAMPLE
  // { value: 'MASTER.TABLE_COLUMN.LDAP_ID', enum: TableColumnsEnum.ldapId },
];

export const TABLE_CONFIG: ITableConfig = {
  // EXAMPLE
  // SYSTEM_COLLECTIVE: [
  //   { value: 'MASTER.TABLE_COLUMN.PROCESS', enum: TableColumnsEnum.processName },
  //   { value: 'MASTER.TABLE_COLUMN.STATUS', enum: TableColumnsEnum.status },
  //   { value: 'MASTER.TABLE_COLUMN.DESCRIPTION', enum: TableColumnsEnum.description },
  //   { value: 'MASTER.TABLE_COLUMN.COMMENTARY', enum: TableColumnsEnum.commentary }
  // ],
  CONSULT_REQUEST: [
    { value: "CTRL_NAMES.RESPOND", enum: TableColumnsEnum.statusIcon, width: "10" },
    { value: "", enum: TableColumnsEnum.statusIcon, width: "0" },    
    { value: "CTRL_NAMES.REQUEST", enum: TableColumnsEnum.id, width: "5" },
    { value: "CTRL_NAMES.STATE", enum: TableColumnsEnum.status, width: "5" },
    {
      value: "CTRL_NAMES.START_DATE",
      enum: TableColumnsEnum.startDate,
      width: "5",
    },
    {
      value: "CTRL_NAMES.END_DATE",
      enum: TableColumnsEnum.endDate,
      width: "5",
    },
    {
      value: "CTRL_NAMES.CATEGORY",
      enum: TableColumnsEnum.category,
      width: "10",
    },
    {
      value: "CTRL_NAMES.SUBCATEGORY",
      enum: TableColumnsEnum.subcategory,
      width: "10",
    },
    {
      value: "CTRL_NAMES.DESCRIPTION",
      enum: TableColumnsEnum.description,
      width: "15",
    },
    {
      value: "CTRL_NAMES.END_COMMENT",
      enum: TableColumnsEnum.comment,
      width: "10",
    }
  ],
};

export interface TableColumn {
  value: string;
  enum: string;
}
