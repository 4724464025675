import { IfilterCtrl } from '../search-filter/search-filter.model';
import { FormGroup } from '@angular/forms';

/**
 * @description Clase que define los estilos de un control de formulario en box-title-form
 * @export
 * @class BoxTitleFormElementStyles
 */
// export class BoxTitleFormElementStyles {
//   icon: string;
//   col: string;
//   innerStyle: string;

//   constructor(
//     icon = '',
//     col = '',
//     innerStyle = ''
//   ) {
//     this.icon = icon;
//     this.col = col;
//     this.innerStyle = innerStyle;
//   }
// }

/**
 * @description Enum para los tipos de controles posibles en el formulario del componente box-title-form
 * @export
 * @enum {string} BoxTitleFormTypesEnum
 */
export enum BoxTitleFormTypesEnum {
  TEXT = 'text',
  NUMBER = 'number',
  DATEPICKER = 'datepicker',
  LINK = 'link',
  DATE = 'date',
  SEARCH = 'search',
  SELECT = 'select',
  TEXTAREA = 'textArea',
  PDF = 'pdf',
  CHECKBOX = 'checkBox',
  ONLY_TEXT = 'onlyText',
  SUB_TITLE = 'subTitle',
  DOCUMENT = 'document'
}

/**
 * @description Clase que define la estructura de datos del componente box-title-form
 * @export
 * @class BoxTitleForm
 */
export class BoxTitleForm {
  title: string;
  subtitle: string;
  footer: string;
  uppercase = false;
  titlecase = false;
  marginBottom = true;
  elements: Array<Array<IfilterCtrl>> = [[]];
  required: boolean = false;

  constructor(
    elements: Array<Array<IfilterCtrl>> = [],
    title = '',
    subtitle = '',
    footer = '') {
    this.elements = elements;
    this.title = title;
    this.subtitle = subtitle;
    this.footer = footer;
  }
}

export interface ItemEvent {
  item?: Item;
  controlName: string;
  value?: any;
  parentName?: FormGroup;
  placeholder?: string;
  required?: boolean;
  type?: string;
}

export interface Item {
  value: string;
  id: number | string | boolean;
  allData: AllData;
}

export interface AllData {
  [key: string]: any;

  id: number;
  name: string;
  description: string;
  status: string;
}
