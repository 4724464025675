import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '@app/core/services/login/login.service';
import { UrlsFaq } from '@config/app-urls.config';
import { I18n } from '@shared/components/input-datepicker/languageDatePikerService';
import { HeaderService } from '@shell/header/header.service';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from '../modal/modal.service';
import { TranslationsService } from '@core/services/translations/translations.service';


@Component({
  selector: 'onehr-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [I18n]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() hideMenu = new EventEmitter();

  openMenu = false;
  tooltipLanguage = false;
  languageDefault: string;
  headerElements = null;
  totalLanguages: any[] = [];
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    public headerService: HeaderService,
    private readonly router: Router,
    private readonly login: LoginService,
    private readonly modal: ModalService,
    private readonly translationsService: TranslationsService,
    private readonly loginService: LoginService
  ) {
  }

  logout(): any {
    return this.modal.triggerConfirmationModal(
      {
        title: 'COMMON.LOGOUT.CONFIRM.TITLE',
        desc: 'COMMON.LOGOUT.CONFIRM.DESC',
        actionOK: () => {
          this.login.logout();
        }
      });
  }

  ngOnInit() {
    this.languageDefault = sessionStorage.getItem("language");
    const getElementsHeader = this.headerService.getElementsHeader();
    const getLanguagesStructure = this.headerService.getLanguagesStructure();

    forkJoin([getElementsHeader, getLanguagesStructure])
      .pipe(takeUntil(this.destroy$))
      .subscribe(results => {
        const header: any = results[0];
        if (header.elementData) {
          this.headerElements = header.elementData;
        }
        const languages = results[1] as any[];
        this.setCurrentLanguage(languages);
        this.totalLanguages = languages;
      });
  }

  private setCurrentLanguage(languages: any[]): void {
    const langSelectedCode = this.translationsService.getCurrentLanguage();
    const langToSetDefault = languages.filter(lang => lang.cmsLanguageCode === langSelectedCode);
    languages.forEach(lang => lang.isDefault = false);
    langToSetDefault[0].isDefault = true;
  }

  setLanguage(lang: string, cmsLanguageCode: string) {
    this.translationsService.setLanguage(lang, cmsLanguageCode);
    const languageSelected = this.totalLanguages.find(lang => lang.cmsLanguageCode === cmsLanguageCode);
    this.totalLanguages.forEach(lang => lang.isDefault = false);
    languageSelected.isDefault = true;
    window.location.reload();
  }

  email() {
    window.open("https://wd3.myworkday.com/santander/d/home.htmld", "_blank");
  }

  goToLink(element) {
    if (element.urlExt) {
      window.open(element.urlExt, "_blank");
    } else if (element.url === UrlsFaq.A10) {
      this.goUrlByCollective();
    } else {
      this.router.navigate([element.url]);
    }
  }

  goUrlByCollective() {
    const collective = this.loginService.getCollective();
    const url = UrlsFaq[collective] || 'faq_A10';
    this.router.navigate([url]);
  }

  goToLinkSubMenu(subMenu, element) {
    element.showSubMenu = !element.showSubMenu;
    if (subMenu.urlExt) {
      window.open(subMenu.urlExt, "_blank");
    } else {
      this.router.navigate([subMenu.linkSB]);
    }

  }

  showSubMenu(element) {
    element.showSubMenu = !element.showSubMenu;
  }

  openTooltipLanguage() {
    this.tooltipLanguage = !this.tooltipLanguage;
  }

  toggleMenu($event) {
    this.openMenu = !this.openMenu;
    // Block body overflow
    if ($event === undefined) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
