import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '@app/core/services/login/login.service';
import { BreadCrumb } from '@app/shared/models/breadcrumb/breadcrumbs.model';
import { BreadcrumbService } from '@core/services/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  // Ejemplo de breadrumb hard-code.
  // *******************************
  // breadcrumb: Array<BreadCrumb> = [
  //   {
  //     h1: 'BREADCRUMBS.SOLICITUDCONTROLHORARIO',
  //     levels: [
  //       { level: 'BREADCRUMBS.INICIO', sref: '/home' },
  //       { level: 'BREADCRUMBS.AUSENCIASHORARIO', sref: '' },
  //       { level: 'BREADCRUMBS.CONTROLHORARIO', sref: '' },
  //       { level: 'BREADCRUMBS.SOLICITUDCONTROLHORARIO', sref: '/controlHorario', class : 'empty' }
  //     ]
  //   }
  // ];
  @Input() breadcrumbInput = null;
  breadcrumbs: BreadCrumb[] = this.breadcrumbService.breadcrumbs;
  ROUTE_DATA_BREADCRUMB = 'breadcrumb';

  @ViewChild('redArrow', { read: ElementRef }) redArrow: ElementRef;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private breadcrumbService: BreadcrumbService,
              public login: LoginService) {
  }

  ngOnInit() {
    if (this.breadcrumbInput) {
      this.breadcrumbService.breadcrumbs = this.breadcrumbInput;
      this.breadcrumbs = this.breadcrumbInput;
    } else {
      this.breadcrumbService.breadcrumbs = this.getBreadcrumbs(this.activatedRoute);
    }
  }

  /**
   * @param route recomended: this.activatedRoute parametro que captura la ruta acual
   */
  getBreadcrumbs(route: ActivatedRoute) {
    this.breadcrumbs = [];
    if (route.snapshot.data.hasOwnProperty(this.ROUTE_DATA_BREADCRUMB)) {
      const breadcrumb: BreadCrumb = route.snapshot.data[this.ROUTE_DATA_BREADCRUMB];
      if (breadcrumb.levels) {
        for (const l of breadcrumb.levels) {
          const i = breadcrumb.levels.indexOf(l);
          if (l.sref === '') {
            breadcrumb.levels[i].class = 'empty';
          } else {
            breadcrumb.levels[i].sref = breadcrumb.levels[i].sref;
          }
        }
      }
      this.breadcrumbs.push(breadcrumb);
    }
    return this.breadcrumbs;
  }

  navigate(level) {
    this.router.navigate(['/' + level]);
  }
}
