import { Inject, Injectable } from '@angular/core';
import { ConfigService } from '@devstack-angular/config';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class EnviromentService {
  
  constructor(@Inject(ConfigService) public configService: ConfigService) {
    this.configService.config('sessionStoreId', '/frontconfig');
  }

  getConfig(): Promise<any> {
    return this.configService.get().catch(err => {
      console.error('ERROR en la prmise de getConfig', err);
      throw err;
    });
  }

  get(): any {
    return environment;
  }
}
