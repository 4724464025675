<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel" *ngIf="modal?.title">{{ modal.title  | translate }}</h5>
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p *ngIf="modal?.text">
    {{ modal.text  | translate }}
  </p>
  <form class="row" [formGroup]="modalForm">
    <div class="col-md-4 col-12">
      <!-- INPUT CALENDAR -->
      <div class="form-group calendar-input" *ngIf="modal?.date">
        <app-input-datepicker [parentForm]="modalForm" [controlName]="modal.date.controlName" [label]="modal.date.label"
          [required]="modal.date.required">
        </app-input-datepicker>
      </div>
    </div>
    <div class="col-md-4 col-12">
      <!-- INPUT TEXT -->
      <div class="form-group calendar-input" *ngIf="modal?.inputText">
        <label [ngClass]="{'mandatory-field': modal.inputText.required}"
          [for]="modal.inputText.controlName">{{modal.inputText.label  | translate}}</label>
        <input type="text" class="form-control" [formControlName]="modal.inputText.controlName"
          [id]="modal.inputText.controlName" />
      </div>
    </div>
    <div class="col-md-12 col-12" *ngIf="modal?.textArea">
      <div class="form-group">
        <label [ngClass]="{'mandatory-field': modal?.textArea.required}"
          [for]="modal.textArea.controlName">{{modal.textArea.label  | translate}}</label>
        <textarea class="form-control" [formControlName]="modal.textArea.controlName" [id]="modal.textArea.controlName"
          [placeholder]="modal.textArea.placeholder | translate" rows="1"></textarea>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer" *ngIf="modal?.buttons">
  <button type="button" class="btn btn-secondary btn-lg"
  (click)="save()">{{modal.buttons.secondary?.value  | translate}}</button>
  <button type="button" [disabled]="!modalForm.valid" class="btn btn-primary btn-lg"
  (click)="cancel()">{{modal.buttons.primary?.value  | translate}}</button>
</div>