<ng-container *ngIf="login.loginStatus | async">
<div class="breadcrumb-container py-2" *ngFor="let breadcrumb of breadcrumbs">
  <div class="d-inline-flex justify-content-center m-spacing">
      <div class="breadcrumb-section" *ngFor="let level of breadcrumb.levels; let last = last">
        <a *ngIf="level.sref" (click)="navigate(level.sref)">{{level.level | translate}} </a>
        <a *ngIf="!level.sref">{{level.level | translate}} </a>
        <i class="icn-right" *ngIf="!last"></i>
      </div>
  </div>
  <!-- <div style="margin-top:20px" *ngIf="breadcrumb.h1" >
    <app-page-title [title]="breadcrumb.h1 | translate"></app-page-title>
  </div> -->
</div>
</ng-container>