<div class="my-4">
  <div class="title-container mb-2">
    <h3 *ngIf="title">{{title | translate}}</h3>
  </div>

  <ng-container *ngIf="isNewMode">
    <ng-container *ngTemplateOutlet="customItemTemplate ? customItemTemplate: itemTemplate;context: itemCtxForTemplate">
    </ng-container>
  </ng-container>

  <ng-template #itemTemplate let-dataFormFromCtx="dataForm">
    <div class="box-form">
      <div class="box-info">
        <ng-container *ngIf="!isArray; else arrayDataForm">
          <esda-box-title-form [boxBorderForm]="false" [dataForm]="dataFormFromCtx" [parentForm]="itemFormGroup"
                               (valueSelected)="setItemFromSearch($event)" (fileSelected)="selectedFile($event)" (fileDeleted)="deleteFile($event)">
          </esda-box-title-form>
        </ng-container>

        <ng-template #arrayDataForm>
          <ng-container *ngFor="let dataForm of dataFormFromCtx">
            <esda-box-title-form [boxBorderForm]="false" [dataForm]="dataForm" [parentForm]="itemFormGroup"
                                 (valueSelected)="setItemFromSearch($event)">
            </esda-box-title-form>
          </ng-container>
        </ng-template>

        <div *ngIf="subList">
          <ng-container *ngTemplateOutlet="subsection; context: subSectionContext">
          </ng-container>
        </div>

        <div class="btn-block justify-content-center pb-3">
          <button type="button" class="btn btn-secondary btn-lg" (click)="cancelItem()">
            {{'COMMON.BUTTONS.CANCEL' | translate}}
          </button>

          <button type="button" class="btn btn-primary" [disabled]="!itemFormGroup.valid"
                  (click)="saveEdit()">
            {{'COMMON.BUTTONS.SAVE' | translate}}
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-container *ngFor="let row of itemList; let i=index">
    <div class="box-form" *ngIf="!row.isEditMode">
      <div class="box-info">
        <div class="row">
          <div class="col-md-11">
            <div class="row">
              <ng-container *ngFor="let field of row.box">
                <div *ngIf="!field.isHidden"
                     [ngClass]="field.bootstrapClass ? field.bootstrapClass : 'col-md-2'">
                  <div class="label-txt">
                    {{ field.label | translate}}
                  </div>
                  <div *ngIf="(field.value !== '' || field.value !== undefined) && field.slug !== BoxSlug.document"
                  [ngClass]="field.class ? field.class : null" style="white-space: pre-line">
                      {{ field.value }}
                  </div>
                  <div *ngIf="(field.value !== '' || field.value !== undefined) && field.slug === BoxSlug.document"
                    [ngClass]="field.class ? field.class : null">
                    <div *ngFor="let name of field.value; let j=index">
                      <i class="icn-pdf"></i>
                      <span class="span-cursor" (click)="downloadFile(field.id[j], field.value[j])">
                        {{ name }}
                      </span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col-md-1 d-flex align-items-center justify-content-center">
            <ng-container *ngFor="let action of row.actions">
              <i [class]="action.icon" [ngClass]="isDisable ? 'add-disable' : ''" [title]="action.title | translate"
                 (click)="actionFun(action, row, i)"></i>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="row.isEditMode">
      <ng-container
        *ngTemplateOutlet="customItemTemplate ? customItemTemplate : itemTemplate; context: getItemCtx()">
      </ng-container>
    </div>
  </ng-container>

  <div class="new-item-row">
    <span class="add-new-ctn" [ngClass]="isDisable ? 'add-disable' : ''" *ngIf="isNewItemEnabled && !isNewMode"
          (click)="addNewItem()">
      <i class="icn-plus"></i>
      {{'COMMON.LABELS.ADD_ITEM' | translate}} {{ itemName | translate }}
    </span>
  </div>
</div>

<ng-template #subsection let-open="subSectionOpen">
  <div class="filter-dropdown">
    <div class="filter-dropdown-title" [ngClass]="{'open-filter': open}" (click)="toggleSubsection()">
      <h2>{{subList.itemName | translate}}</h2>
      <i class="icn-dropdown"></i>
    </div>
    <div [ngClass]="open ? 'showContent' : 'hideContent'">
      <onehr-list-item [listConfig]="subList"
                       (newItemEvent)="subList.newItemEvent()"
                       (saveItemEvent)="subList.saveItemEvent()"
                       (editItemEvent)="subList.editItemEvent($event)"
                       (deleteItemEvent)="subList.deleteItemEvent($event)"
                       (selectItemEvent)="subList.selectItemEvent($event)">
      </onehr-list-item>
    </div>
  </div>
</ng-template>
