import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root' // Enables tree-shaking. Use 'root' for app-wide availability
                     // Use custom module identifier otherwise
                     // (example: providedIn: CustomModule) - Not recommended unless only used on specific module
})
export class LoaderService {
  public isLoading$ = new BehaviorSubject(false);
}
