import { Injectable } from '@angular/core';
import { CompAccess } from '../routeDinamyc/mbox.model';
import { OrderPipe } from '@app/shared/pipes/order/order.pipe';
import { CompAccessI } from '@core/services/routeDinamyc/compAccess.model';

@Injectable({
  providedIn: 'root'
})
export class FindChildrenService {
  allRoutes: Array<CompAccess>;
  itemCurrent: CompAccess = {} as CompAccess;
  childRoutes: Array<CompAccess>;
  parentRoutes: Array<CompAccess>;

  constructor(
    private orderPipe: OrderPipe
  ) {
  }

  getChildren(currentRoute: string) {
    const itemParent: Array<CompAccess> = this.allRoutes.filter((item: CompAccess) => item.linkItem === currentRoute.slice(1));
    let itemChildren: Array<CompAccess> = this.allRoutes.filter((item: CompAccess) => item.parentName === itemParent[0].accessItem);
    this.itemCurrent = itemParent[0];
    itemChildren = this.orderPipe.transform(itemChildren, 'accSeqnum');
    return itemChildren;
  }

  setChildren(children: Array<CompAccess>) {
    this.allRoutes = children;
  }

  getAllRoutes(): Array<CompAccess> {
    this.allRoutes = this.orderPipe.transform(this.allRoutes, 'accSeqnum');
    return this.allRoutes;
  }

  getAccessData(): Array<CompAccessI> {
    return this.itemCurrent.compContAccess;
  }

  getItemcurrent() {
    return this.itemCurrent;
  }

  getModulos(): Array<CompAccess> {
    return this.parentRoutes;
  }

  getNivel1(): Array<CompAccess> {
    return this.childRoutes.filter(
      (item: CompAccess) => item.accessType === 'PRC1' || item.accessType === 'INF1' || item.accessType === 'VIDT'
    );
  }

  getNivel2(): Array<CompAccess> {
    return this.childRoutes.filter((item: CompAccess) => item.accessType === 'PRC2' || item.accessType === 'INF2');
  }

  getNivel3(): Array<CompAccess> {
    return this.childRoutes.filter((item: CompAccess) => item.accessType === 'PRC3' || item.accessType === 'INF3');
  }

  getNivel4(): Array<CompAccess> {
    return this.childRoutes.filter((item: CompAccess) => item.accessType === 'PRC4' || item.accessType === 'INF4');
  }

  findParent(access: CompAccess, parentRoutes: Array<CompAccess>): Array<CompAccess> {
    return parentRoutes.filter((item: CompAccess) => item.accessItem === access.parentName);
  }

  /**
   * Metodo que filtra de todos los accesos que vienen de PS los que no tienen permisos
   */
  filterPermisionsRoutes() {
    let arrayFiltrado: CompAccess[] = [];
    const allRoutes = this.getAllRoutes();
    const moduleArray = this.getModulos();
    let nivel1Array = this.getNivel1();
    let nivel2Array = this.getNivel2();
    let nivel3Array = this.getNivel3();
    let nivel4Array = this.getNivel4();

    let nivel1ArrayBorrados: CompAccess[] = [];
    let nivel2ArrayBorrados: CompAccess[] = [];
    let nivel3ArrayBorrados: CompAccess[] = [];
    let nivel4ArrayBorrados: CompAccess[] = [];

    console.log('MOLULOS: ', moduleArray);
    console.log('NIVEL1: ', nivel1Array);
    console.log('NIVEL2: ', nivel2Array);
    console.log('NIVEL3: ', nivel3Array);
    console.log('NIVEL4: ', nivel4Array);
    console.log('ALLROUTES: ', allRoutes);
    console.log('CONCAT_NIVELES: ', moduleArray.concat(nivel1Array, nivel2Array, nivel3Array, nivel4Array));

    console.log('****************NIVEL1*********************');
    nivel1ArrayBorrados = nivel1Array.filter(nivel1 => {
      return this.findParent(nivel1, moduleArray).length === 0;
    });
    console.log('nivel1ArrayBorrados: ', nivel1ArrayBorrados);

    nivel1Array = nivel1Array.filter(nivel1 => {
      return this.findParent(nivel1, moduleArray).length !== 0;
    });
    console.log('nivel1Array: ', nivel1Array);

    console.log('****************NIVEL2*********************');

    nivel2ArrayBorrados = nivel2Array.filter(nivel2 => {
      return this.findParent(nivel2, nivel1Array).length === 0;
    });
    console.log('nivel2ArrayBorrados: ', nivel2ArrayBorrados);

    nivel2Array = nivel2Array.filter(nivel2 => {
      return this.findParent(nivel2, nivel1Array).length !== 0;
    });
    console.log('nivel2Array: ', nivel2Array);

    console.log('****************NIVEL3*********************');

    nivel3ArrayBorrados = nivel3Array.filter(nivel3 => {
      return this.findParent(nivel3, nivel2Array).length === 0;
    });
    console.log('nivel3ArrayBorrados: ', nivel3ArrayBorrados);

    nivel3Array = nivel3Array.filter(nivel3 => {
      return this.findParent(nivel3, nivel2Array).length !== 0;
    });
    console.log('nivel3Array: ', nivel3Array);

    console.log('****************NIVEL4*********************');

    nivel4ArrayBorrados = nivel4Array.filter(nivel4 => {
      return this.findParent(nivel4, nivel3Array).length === 0;
    });
    console.log('nivel4ArrayBorrados: ', nivel4ArrayBorrados);
    nivel4Array = nivel4Array.filter(nivel4 => {
      return this.findParent(nivel4, nivel3Array).length !== 0;
    });
    console.log('nivel4Array: ', nivel4Array);

    arrayFiltrado = moduleArray.concat(nivel1Array, nivel2Array, nivel3Array, nivel4Array);
    console.log('*************************************');
    console.log('*************************************');
    console.log(
      'ELIMINADOS: ',
      nivel1ArrayBorrados.concat(nivel2ArrayBorrados, nivel3ArrayBorrados, nivel4ArrayBorrados)
    );
    console.log('CONCAT_NIVELES_FINAL: ', arrayFiltrado);

    return arrayFiltrado;
  }
}
