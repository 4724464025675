/* tslint:disable */
import { Injectable } from '@angular/core';
import { SilverBaseService } from '../silver-base-service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { SilverRequestBuilder } from '../silver-request-builder';
import { HttpService } from '@core/http/http.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Pdf } from '../models/pdf';


/**
 * Seniority and mutuality certificates.
 */
@Injectable({
  providedIn: 'root',
})
export class CertificatesService extends SilverBaseService {
  constructor(
    config: EnviromentService,
    http: HttpService
  ) {
    super(config, http);
  }

  /**
   * Path part for operation downloadSeniorityAndMutualityCertificates
   */
  static readonly DownloadSeniorityAndMutualityCertificatesPath = '/certificates';

  /**
   * Download seniority and mutuality certificates.
   *
   * Returns pdf
   *
   * This method provides customization options.
   * To access only the response body, use `downloadSeniorityAndMutualityCertificates()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadSeniorityAndMutualityCertificates$Response(params?: {
    seniorityCertificate?: boolean;
    mutualityCertificate?: boolean;

  }): Observable<Pdf> {

    const req = new SilverRequestBuilder(CertificatesService.DownloadSeniorityAndMutualityCertificatesPath, 'get');
    if (params) {
      req.query('seniorityCertificate', params.seniorityCertificate, {});
      req.query('mutualityCertificate', params.mutualityCertificate, {});
    }

    const sendReq = req.build({
      responseType: 'json',
      accept: 'application/json'
    });

    return this.http.request<any>(
      sendReq.method,
      sendReq.url,
      sendReq.body,
      sendReq.options
    ).pipe(
      map(r => {
        return r as Pdf;
      })
    );
  }

  /**
   * Download seniority and mutuality certificates.
   *
   * Returns pdf
   *
   * This method provides access to only to the response body (usually preferred).
   * For request customization, `downloadSeniorityAndMutualityCertificates$Response()` use instead.
   *
   * This method doesn't expect any request body.
   */
  downloadSeniorityAndMutualityCertificates(params?: {
    seniorityCertificate?: boolean;
    mutualityCertificate?: boolean;

  }): Observable<Pdf> {

    return this.downloadSeniorityAndMutualityCertificates$Response(params).pipe(
      map(r => r as Pdf)
    );
  }

}
