import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import {
  ActionLogDto,
  ContentProviderResponse,
  WebContentResponse,
} from "@app/core/api/SILVER/models";
import { ActionLogService, ContentService } from "@app/core/api/SILVER/services";
import { CompAccess } from "@app/core/services/routeDinamyc/mbox.model";
import { TranslationsService } from "@app/core/services/translations/translations.service";
import { NgbCarouselConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { VoidExpression } from "typescript";

@Component({
  selector: "silver-carousel",
  templateUrl: "./carousel.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent {
  @Input() carousel: CompAccess[];

  urlVideo: string = ""; // "https://player.vimeo.com/video/774797500?h=78d416ac67&amp;app_id=122963";
  mainTemplates: string[] = ["DST", "DDB"];

  private readonly destroy$: Subject<void> = new Subject<void>();

  webcontents: WebContentResponse[] = [];
  title: string = '';

  constructor(
    private readonly config: NgbCarouselConfig,
    private readonly modalService: NgbModal,
    private readonly router: Router,
    private readonly contentService: ContentService,
    private readonly translationsService: TranslationsService,
    private readonly actionLogService: ActionLogService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    
    config.interval = 5000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
  }
  ngOnInit(): void {
    this.carousel.map((item: CompAccess) => {
      this.getContentInfoSubs(item.compContAccess[0].idLiferay)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: ContentProviderResponse) => {
            item["webcontent"] = response.webcontents[0];
          },
          error: () => {},
        });
    });
    console.log(this.carousel);
  }

  openModal(content, slide) {
    //"https://vimeo.com/774797500/78d416ac67"
    this.title = slide.descrItem;
    const urlIdArray = slide.urlId.split("/");
    this.setActionLog(slide);
    this.urlVideo = `https://player.vimeo.com/video/${urlIdArray[3]}?h=${urlIdArray[4]}&amp;app_id=122963`;
    this.modalService.open(content, { centered: true, size: "sm" });
  }

  goTo(slide): void {
    this.setActionLog(slide); 
    if (slide.sNavega !== "") {     
      this.router.navigate([slide.sNavega]);
    } else if (slide.urlId !== "") {
      this.document.defaultView.open(slide.urlId); // openPdf
    }
  }

  getContentInfoSubs(id: string): Observable<ContentProviderResponse> {
    const params = {
      contentId: id,
      language: this.translationsService.getCurrentLanguage(),
    };
    return this.contentService.getContent(params);
  }

  setActionLog(slice: any):void {
    const urlLength = slice.accessItem?.length;    
    const actionLog: ActionLogDto = {
      action: 'CARRUSEL',
      certificateType: '',
      emplid: 'EMPTY',
      createdAt: new Date()
    }
    if(urlLength > 46){
      actionLog.certificateType = `CARR:${slice.accessItem.slice(0,46)}`;
    } else {
      actionLog.certificateType = `CARR:${slice.accessItem}`;
    }    
    this.actionLogService.saveActionLog({body: actionLog}).subscribe()
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
