<div class="box-container">
  <div class="box" *ngFor="let item of role" (click)="goToPath(item)">
    <div class="box-icon">
      <i *ngIf="item.iconItem" [ngClass]="item.iconItem"></i>
    </div>
    <div class="box-info">
      <div class="box-section">{{ item.descrItem }}</div>
      <div class="section-description"> {{ item.helpTextItem}} </div>
    </div>
  </div>
</div>

