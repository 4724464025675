<fieldset [disabled]="allDisabled" *ngIf="parentForm && dataForm">
  <legend></legend>
  <div [formGroup]="parentForm" [ngClass]="{'mb-4': dataForm.marginBottom}">
    <h3 *ngIf="dataForm.title">{{dataForm.title | translate}}</h3>
    <p [ngClass]="dataForm.title? 'mt-4' : 'mt-3'" *ngIf="dataForm.subtitle && dataForm.titlecase">
      <strong>{{dataForm.subtitle | titlecase }}</strong></p>
    <p [ngClass]="dataForm.title? 'mt-4' : 'mt-3'" *ngIf="dataForm.subtitle && dataForm.uppercase">
      <strong>{{dataForm.subtitle | uppercase }}</strong></p>
    <p [ngClass]="dataForm.title? 'mt-4' : 'mt-3'"
       *ngIf="dataForm.subtitle && !dataForm.titlecase && !dataForm.uppercase">
      <strong>{{dataForm.subtitle | translate }}</strong></p>
    
    <div [ngClass]="{'box-form': boxBorderForm}">
      <div class="row elements-row" *ngFor="let row of dataForm.elements; let i = index">
        <div class="col" *ngFor="let col of row; let j = index" [ngClass]="[
                    (col.type === BoxTitleFormTypesEnum.ONLY_TEXT || col.type === BoxTitleFormTypesEnum.LINK || col.type === BoxTitleFormTypesEnum.PDF) ? 'd-flex align-content-center flex-wrap': '',
                    (col.bootstrapClass && col.bootstrapClass !== '')? col.bootstrapClass : '']"
             [hidden]="col.isHidden">
          <div *ngIf="col.type === BoxTitleFormTypesEnum.SUB_TITLE">
            <h3>{{ col.value | translate}}</h3>
          </div>

          <div class="form-group" [ngClass]="{'calendar-input': col.type === BoxTitleFormTypesEnum.DATE }"
               *ngIf="(col.type !== BoxTitleFormTypesEnum.ONLY_TEXT && col.type !== BoxTitleFormTypesEnum.LINK && col.type !== BoxTitleFormTypesEnum.PDF
                                && col.type !== BoxTitleFormTypesEnum.DATEPICKER && col.type !== BoxTitleFormTypesEnum.CHECKBOX && col.type !== BoxTitleFormTypesEnum.SUB_TITLE )">
            <label *ngIf="col.type !== BoxTitleFormTypesEnum.ONLY_TEXT && col.type !== BoxTitleFormTypesEnum.LINK"
                   [for]="col.controlName" [ngClass]="{ 'mandatory-field': col.required }">
              {{col.name | translate}} <span [title]="'COMMON.BUTTONS.UPDATE'| translate" *ngIf="col.extraData?.editLink" class="updateLink" (click)="goToLink(col.name)">{{ 'COMMON.BUTTONS.UPDATE' | translate}} {{ col.name | translate}} <i class="icn-edit" [title]="'COMMON.BUTTONS.UPDATE'| translate"></i></span>
              <span class="message" *ngIf="col.extraData?.message"> {{ col.extraData?.message | translate }}</span>
            </label>
              
            <div *ngIf="col.type !== BoxTitleFormTypesEnum.ONLY_TEXT && col.type !== BoxTitleFormTypesEnum.SEARCH && col.type !== BoxTitleFormTypesEnum.SELECT
                                    && col.type !== BoxTitleFormTypesEnum.TEXTAREA">
              <input (keyup)="setValue(col, 'text')"
                     *ngIf="col.type === BoxTitleFormTypesEnum.TEXT" [attr.aria-describedby]="col.name | translate"
                     [formControlName]="col.controlName" [id]="col.controlName"
                     [maxLength]="col.maxLength ? col.maxLength : maxLength"
                     [minLength]="col.minLength ? col.minLength : null"
                     [ngClass]="{'input-translate': col.extraData?.translate }"
                     [placeholder]="col.placeholder | translate" [required]="col.required"
                     [type]="BoxTitleFormTypesEnum.TEXT" [value]="col.value" class="form-control"
                     novalidate>

              <input [type]="BoxTitleFormTypesEnum.NUMBER" *ngIf="col.type === BoxTitleFormTypesEnum.NUMBER"
                     class="form-control" [ngClass]="{'input-translate': col.extraData?.translate }"
                     [id]="col.controlName" [attr.aria-describedby]="col.name" [formControlName]="col.controlName"
                     [value]="col.value" min="1" [placeholder]="col.placeholder | translate" [required]="col.required"
                     (input)="preventIllegalChars($event)" (change)="setValue(col, 'text')" novalidate
                     [maxLength]="col.maxLength ? col.maxLength : maxLength">

              <i class="icon-calendar-ds" *ngIf="col.type === BoxTitleFormTypesEnum.DATE"></i>
              <div class="alert-msg" *ngIf="col.extraData?.emptyWarning">
                <i class="icn-alert"></i>
                <div>
                  {{ col.extraData.emptyWarning | translate}}
                </div>
              </div>
              <div class="input-feedback" *ngIf="col.extraData?.errorMsg">
                {{ col.extraData?.errorMsg | translate }}
              </div>
            </div>
            <div class="box-title-form-search"
                 *ngIf="col.type === BoxTitleFormTypesEnum.SEARCH || col.type === BoxTitleFormTypesEnum.SELECT">
              <app-search-input [parentForm]="parentForm" [controlName]="col.controlName"
                                [placeholder]="col.placeholder | translate" [minLength]="col.minLength"
                                [results]="col.results" [required]="col.required" [value]="col.value"
                                [initialLoad]="col.initialLoad"
                                [selectType]="col.type === BoxTitleFormTypesEnum.SELECT"
                                [dependency]="col.dependency"
                                [dependency2]="col.dependency2"
                                [filter]="col.filter"
                                [disabled]="allDisabled || col.disableInput" [inputList]="dataForm.elements"
                                [strictDependency]="col.strictDependency"
                                [icnClose]="col.btnBorrar"
                                [catalog]="col.catalog"
                                [maximHeight]="col.maximHeight"
                                (search)="searchInput($event, col.name)" (setValue)="setValue($event)">
              </app-search-input>
            </div>
            <textarea class="form-control" rows="3" *ngIf="col.type === BoxTitleFormTypesEnum.TEXTAREA"
                      [formControlName]="col.controlName" [value]="col.value"
                      [placeholder]="col.placeholder  | translate"
                      [required]="col.extraData?.required" (input)="setValue(col, 'textArea')"
                      [ngClass]="{'input-translate': col.extraData?.translate }" novalidate
                      [maxLength]="col.maxLength ? col.maxLength : maxLength">
                        </textarea>
          </div>
          <app-input-datepicker *ngIf="col.type === BoxTitleFormTypesEnum.DATEPICKER" [parentForm]="parentForm"
                                [controlName]="col.controlName" [minDateSelected]="col.extraData?.minDay"
                                [maxDateSelected]="col.extraData?.maxDay"
                                [label]="col.name" [btnBorrar]="col.extraData?.btnBorrar"
                                [readonly]="true" [required]="col.required"
                                [placeholder]="col.placeholder  | translate"
                                [inputValue]="col.value"
                                [disableInput]="allDisabled || col.disableInput" (dateSelected)="setValue($event)">
          </app-input-datepicker>
          <div class="input-feedback" *ngIf="col.extraData?.errorMsg && col.type !== BoxTitleFormTypesEnum.TEXT && col.type !== BoxTitleFormTypesEnum.NUMBER">
            {{ col.extraData?.errorMsg | translate }}
          </div>
          <div class="icon-red-txt" *ngIf="col.type === BoxTitleFormTypesEnum.PDF">
            <i class="icn-pdf"></i>
            <span class>{{col.value}}</span>
          </div>
          <div class="box-title-form_only-text" *ngIf="col.type === BoxTitleFormTypesEnum.ONLY_TEXT">
            <span [innerHTML]=" col.value | translate"></span></div>
          <div class="box-title-form_link icon-red-txt" *ngIf="col.type === BoxTitleFormTypesEnum.LINK">
            {{col.value}}</div>
            

          <app-input-checkbox *ngIf="col.type === BoxTitleFormTypesEnum.CHECKBOX"
                              [label]="col.name"
                              [parentForm]="parentForm"
                              [controlName]="col.controlName"
                              [placeholder]="col.placeholder"
                              [minLength]="col.minLength"
                              [required]="col.required"
                              [value]="col.value"
                              (search)="setValue($event)">
          </app-input-checkbox>
          <div *ngIf="col.type === BoxTitleFormTypesEnum.DOCUMENT">
            <app-upload-file  [parentForm]="parentForm"
                              [controlName]="'documento'"
                              [multipleFiles]="multipleFiles"
                              (openFile)="openFile($event)"
                              (deleteFile)="deleteFile($event)"
                              [setValue]="fileAttach"
                              (files)="setFiles($event)">
                      </app-upload-file>
          </div>
        </div>
      </div>
      <p *ngIf="dataForm.footer">{{dataForm.footer | translate}}</p>
      <div *ngIf="dataForm.required" class="btn-block justify-content-center pb-3">
        <span class="mandatory-field-container"><span class="t-red">*</span>{{'COMMON.LABELS.MANDATORY_FIELD' | translate}} </span>
      </div>
    </div>
  </div>
</fieldset>
