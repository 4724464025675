import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FindChildrenService } from '@app/core/services/findChildren/find-children.service';
import { CompAccess } from '@app/core/services/routeDinamyc/mbox.model';
import { SETTINGS } from '@app/shared/constants/settings/settings.constants';
import { MenuItem } from './menu.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Output() toggleMenu: EventEmitter<any> = new EventEmitter();

  public sectionList: Array<MenuItem> = [];
  public arrayChildren: Array<CompAccess> = [];
  public url: string;

  constructor(
    private findChildren: FindChildrenService,
    private router: Router,
  ) {
    this.url = this.router.url;
  }

  public moreOptions = false;

  ngOnInit() {
    let objeto: MenuItem;
    if (this.findChildren.getAllRoutes()) {
      this.findChildren.getAllRoutes().forEach((itemRoute: CompAccess) => {
        objeto = {} as MenuItem;
        this.structure(itemRoute, objeto);
      });
      this.sort();
      this.childrens();
    }
  }

  private sort() {
    this.sectionList = this.sectionList.sort((a, b) => a.parent.accSeqnum > b.parent.accSeqnum
      ? 1
      : a.parent.accSeqnum === b.parent.accSeqnum
        ? 0
        : -1
    );
  }

  private structure(itemRoute: CompAccess, objeto: MenuItem) {
    if (itemRoute && itemRoute.accessType === 'MOD') {
      objeto.parent = itemRoute;
      objeto.children = [];
      this.sectionList.push(objeto);
    }
    if (itemRoute &&
      (itemRoute.accessType === 'PRC1' || itemRoute.accessType === 'INF1')) {
      this.arrayChildren.push(itemRoute);
    }
  }

  private childrens() {
    this.sectionList.forEach((item: MenuItem) => {
      this.arrayChildren.forEach((element: CompAccess) => {
        if (item.parent.accessItem === element.parentName) {
          item.children.push(element);
        }
      });
      item.children = item.children.sort((a, b) => a.accSeqnum > b.accSeqnum ? 1 : a.accSeqnum === b.accSeqnum ? 0 : -1
      );
      item.limitShow = SETTINGS.MENU.limitShow;
      item.children.forEach((element: CompAccess, index) => {
        if (element.accessType === 'INF1') {
          item.children.splice(index, 1);
          item.children.unshift(element);
        }
      });
    });
  }

  public desplegar(array: MenuItem) {
    array.limitShow = array.children.length;
  }

  public goToLink(seccion) {
    if (seccion.linkItem) {
      this.router.navigate([seccion.linkItem]);
    } else if (seccion.urlId) {
      // TAG-Manager Event
      window.open(seccion.urlId, '_blank');
    } else if (seccion.sNavega) {
      this.router.navigate([seccion.sNavega]);
    }
    this.toggleMenu.emit(false);

  }

  hideMenu() {
    this.toggleMenu.emit(false);
  }

}
