<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel" *ngIf="title">{{ title  | translate }}</h5>
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
<div class="modal-body">
    <p *ngIf="text">
    {{ text | translate }}
    </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close('Ok click')">Ok</button>
</div>
