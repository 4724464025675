import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  AddressesTypesService,
  CountriesService,
  MunicipalitiesService, StatesService, TicketsService
} from '@app/core/api/SILVER/services';
import { PayrollService } from '@core/api/SILVER/services/payroll.service';
import { WithholdingService } from '@core/api/SILVER/services/withholding.service';
import { EnviromentService } from '@core/services/environment/environment.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CustomTableService } from '../custom-table/custom-table.service';
import {
  FilterCtrlEnum,
  FIlterSendData,
  UserRoleTypeEnum
} from '../search-filter/search-filter.model';
import {
  FilterTableConfigEnum, FILTER_TABLE_CONFIG, IFilterTableConfig,
  PageTypeEnum
} from './filter-table.model';

@Injectable({
  providedIn: 'root',
})
export class FilterTableService {
  filterTableType: FilterTableConfigEnum = null;
  moreResultThanSize: boolean = false;
  // Parámetros base que siempre tienen formar parte de la llamada de datos del filtro
  private readonly BASE_PARAMS = {
    Authorization: '',
    'X-USER-ROL': '',
  };
  // Datos almacenados por el servicio
  private readonly cache = {
    FILTER_TABLE_CONFIG: null,
    OBJECT_FILTER_REQUEST: null,
    BODY_REQUEST_OLD: null,
    FILTER_GROUP: null,
  };
  // Parámetros del filtro
  private bodyParams: any = {};
  private searchParams: FIlterSendData = new FIlterSendData();
  private pageType: PageTypeEnum = null;
  private userRoleType: UserRoleTypeEnum = null;
  private readonly inputSizeSearchLimit: number;

  constructor(
    private readonly customTableService: CustomTableService,
    private readonly environmentService: EnviromentService,
    private readonly withholdingService: WithholdingService,
    private readonly payrollService: PayrollService,
    private readonly ticketsService: TicketsService,
    private readonly addressesTypesService: AddressesTypesService,
    private readonly municipalitiesService: MunicipalitiesService,
    private readonly countriesService: CountriesService,
    private readonly statesService: StatesService
  ) {
    this.inputSizeSearchLimit =
      this.environmentService.get().app.properties.inputSizeSearchLimit;
  }

  /**
   * @description Método para obtener la configuración de componente FilterTable
   * @param type Tipo de configuración
   * @returns Devuelve la configuración completa IFilterTableConfig
   */
  getFilterTableConfig(type?: FilterTableConfigEnum): IFilterTableConfig {
    if (
      !this.cache.FILTER_TABLE_CONFIG ||
      this.cache.FILTER_TABLE_CONFIG.length === 0
    ) {
      this.cache.FILTER_TABLE_CONFIG = JSON.parse(
        JSON.stringify(
          FILTER_TABLE_CONFIG.filter(
            (element) =>
              element.filterTableConfig === FilterTableConfigEnum[type]
          )[0]
        )
      );
    }
    const pageType = 'pageType';
    this.pageType = this.cache.FILTER_TABLE_CONFIG[pageType];
    const userRoleType = 'userRoleType';
    this.userRoleType = this.cache.FILTER_TABLE_CONFIG[userRoleType];
    return this.cache.FILTER_TABLE_CONFIG;
  }

  /**
   * @description Método para setear la configuración de componente FilterTable
   * @param filterTableType Tipo de configuración
   * @returns Devuelve la configuración completa IFilterTableConfig
   */
  setFilterTableConfig(
    filterTableType: FilterTableConfigEnum
  ): IFilterTableConfig {
    this.filterTableType = filterTableType;
    this.getFilterTableConfig(this.filterTableType);
    return this.cache.FILTER_TABLE_CONFIG;
  }

  /**
   *
   * @description Método para setear un parámetro de la configuración de los controles FilterTableConfig
   * @param controlName Nombre del control
   * @param propertyRef Propiedad a modificar
   * @param value Valor de la propiedad a modificar
   * @returns Devuelve la configuración completa IFilterTableConfig
   */
  setFilterTableCtrlsConfigParam(
    controlName: string,
    propertyRef: string,
    value: any
  ): IFilterTableConfig {
    const ctrlToModified =
      this.cache.FILTER_TABLE_CONFIG.filterCtrlsConfig.find(
        (element) => element.controlName === controlName
      );
    Object.keys(ctrlToModified).forEach((prop) => {
      if (prop.hasOwnProperty(propertyRef)) {
        prop[propertyRef] = value;
      }
    });
    return this.getFilterTableConfig(
      this.cache.FILTER_TABLE_CONFIG.filterTableConfig
    );
  }

  removeFormControlValue(controlName: string): void {
    const ctrlToModified =
      this.cache.FILTER_TABLE_CONFIG.filterCtrlsConfig.find(
        (element) => element.controlName === controlName
      );
    const controlToRemove: FormControl =
      ctrlToModified.parentName.get(controlName);
    controlToRemove.setValue('');
  }

  /**
   * @description Método para obtener el tipo de configuración del FilterTable
   */
  getFilterTableType(): FilterTableConfigEnum {
    return this.filterTableType;
  }

  /**
   * @description Método para guardar el rol del usuario
   * @param userRoleType Tipo de rol del usuario
   */
  setUserRoleType(userRoleType: UserRoleTypeEnum) {
    this.userRoleType = userRoleType;
  }

  /**
   * @description Método que inicia los parámetros a enviar al servicio del filtro
   */
  initRequestParams() {
    this.cache.OBJECT_FILTER_REQUEST = this.searchParams;
  }

  /**
   * @description Método para setear un parámetro de la caché de datos a enviar al servicio (searchParams)
   * @param params parámetro o objeto con estructura (propName: value) de varios parámetros a asignar.
   * @param value Valor del parámetro
   */
  setParam(params: any, value?: any) {
    if (typeof params === 'object') {
      Object.keys(params).forEach((paramRef: any) => {
        this.searchParams[paramRef] = params[paramRef];
      });
    } else {
      this.searchParams[params] = value;
    }
  }

  /**
   * @description Método para setear los parámetros del body a enviar al servicio (bodyParams)
   * @param params parámetros a asignar
   * @param filterGroup Si el filtro pertenece a un grupo de filtros
   */
  setBodyParams(params: any, filterGroup?: boolean) {
    this.bodyParams = params;
    if (filterGroup) {
      // Guardamos en la variable this.cache.FILTER_GROUP todos los filtros de todos los desplegables
      this.cache.FILTER_GROUP
        ? (this.bodyParams = this.mergeObject(this.cache.FILTER_GROUP, params))
        : (this.bodyParams = params);
      this.cache.FILTER_GROUP = this.bodyParams;
    }
  }

  /**
   * @description  Método de mergeo específico para FilterGroup.
   * Object.assign() no se puede usar pq sobreescribe propiedades iguales entre los objetos
   * @param {*} A Primer objeto a fusinar
   * @param {*} B Segundo objeto a fusinar
   * @returns {object}
   * @memberof FilterTableService
   */
  mergeObject(A: any, B: any): object {
    const res = {};
    Object.keys({ ...A, ...B }).forEach((key) => {
      res[key] = { ...A[key], ...B[key] };
      Object.keys({ ...res[key] }).forEach((subkeys) => {
        if (res[key][subkeys] === '' || res[key][subkeys] === undefined) {
          delete res[key][subkeys];
        }
      });
    });
    return res;
  }

  /**
   * Método para obtener la última búsqueda del filtro
   * @returns
   */
  getOldBodyParams() {
    return this.cache.BODY_REQUEST_OLD;
  }

  // Parámetros base de las llamadas
  /**
   * @description Método para obtener los parámetros básicos y comunes en las llamadas a los servicios
   * @returns
   */
  getBaseParams() {
    return this.BASE_PARAMS;
  }

  /**
   * @description Llamadas a servicios para obtener datos e introducirlos en el desplegable del search-input
   * @param {*} ctrl Control que lanza el evento de buscar datos
   * @param {*} [dependency] Dependencia del valor de otro componente para filtrar la llamada de obtención de datos
   * @param {boolean} [initial] Si es la llamada inicial (propiedad 'initialLoad' de la configuración del control)
   * @returns {Observable<any>}
   */
  //  searchInput(ctrl: any, dependency?: any, dependency2?: any, initial?: boolean, catalog?: CatalogField): Observable<any> {
  searchInput(
    ctrl: any,
    dependency?: any,
    dependency2?: any,
    initial?: boolean,
    catalog?: any
  ): Observable<any> {
    let $ObservableData: Observable<any> = null;

    $ObservableData = this.getMasterData(
      ctrl,
      dependency,
      dependency2,
      initial,
      catalog
    );
    if (ctrl.name.includes('fecha')) {
      const dateSelected = ctrl.value
        ? ctrl.value
        : { item: undefined, controlName: ctrl.name };
    }
    return $ObservableData;
  }

  /**
   * @description Método que obtiene los datos para un componente search-input desde servicios Back
   * @param {*} ctrl Control que lanza el evento de buscar datos
   * @param dependency
   * @param dependency2
   * @param dependency3
   * @param initial
   * @param catalog
   * @returns
   */

  getMasterData(
    ctrl,
    dependency?: any,
    dependency2?: any,
    initial?: boolean,
    catalog?: any
  ) {
    let $ObservableData: Observable<any> = null;

    switch (ctrl.name) {
      case FilterCtrlEnum.WITHHOLDING_CERTIFICATE_YEAR:
        $ObservableData = this.withholdingService
          .getYearsWithholdingCertificate({ size: 9999 })
          .pipe(
            map((res: any) => {
              return this.mapResultsByKey(
                res.content ? res.content : res,
                ctrl
              );
            })
          );
        break;
      case FilterCtrlEnum.YEAR:
        $ObservableData = this.payrollService
          .getYearslLoggedUser({ size: 9999 })
          .pipe(
            map((res: any) => {
              return this.mapResultsByKey(
                res.content ? res.content : res,
                ctrl
              );
            })
          );
        break;
      case FilterCtrlEnum.ADDRESS_TYPE:
        $ObservableData = this.addressesTypesService.getAllAddressesTypesUsingGet({size: this.inputSizeSearchLimit, 'description.contains': ctrl.value})
        .pipe(
          tap((response) => this.checkResultSize(response)),
          map((res: any) => {
            return this.mapResultsByKey(res.content, ctrl);
          })
        );
        break;
      case FilterCtrlEnum.ADDR_MUNICIPALITY:
        const params = {
          size: this.inputSizeSearchLimit,
          'countryId.equals': dependency.key,
          'stateId.equals': dependency2.key,
          'municipalityDesc.contains': ctrl.value
        };
       
       $ObservableData = this.municipalitiesService.getAllMunicipalitiesUsingGet(params).pipe(
          tap((response) => this.checkResultSize(response)),
          map((res: any) => {
            return this.mapResultsByKey(res.content, ctrl);
          })
        );
        break;
      case FilterCtrlEnum.COUNTRY:
        $ObservableData = this.countriesService.getAllCountriesUsingGet({size: this.inputSizeSearchLimit, 'description.contains': ctrl.value})
       .pipe(
          tap((response) => this.checkResultSize(response)),
          map((res: any) => {
            return this.mapResultsByKey(res.content, ctrl);
          })
        );
        break;    
      case FilterCtrlEnum.ADDR_STATE:
        $ObservableData = this.statesService
          .getAllStatesUsingGet({
            size: this.inputSizeSearchLimit,
            "stateDesc.contains": ctrl.value,
            "countryId.equals": dependency.key,
            "stateId.equals": dependency2.key,
          })
          .pipe(
            tap((response) => this.checkResultSize(response)),
            map((res: any) => {
              return this.mapResultsByKey(res.content, ctrl);
            })
          );
        break;    
    }

    return $ObservableData;
  }

  /**
   * @description Método para mapear los resultados de los servicios para los controles la vista vista del filtro
   * TODO: Mapeo de datos según el proyecto de PODERES. Los valores necesarios mínimos para el desplegable de los serachInput son: value, id
   * En la propiedad allData se almacenan todos los datos obtenidos del servicio back
   * @param {*} res Valores a mapear
   * @param {string} valueName Nombre de la propiedad q almacena el value
   * @param {string} idName Nombre de la propiedad que almacena el id.
   * @returns
   */
  mapResults(
    res: any[],
    valueName: string,
    idName: string,
    secondIdName?: string,
    secondValueName?: string,
    concat?: boolean
  ) {
    const resValue = [];
    res.forEach((element, index) => {
      resValue.push(
        secondIdName
          ? {
              value: element[valueName],
              id: element[idName],
              [secondIdName]: element[secondIdName],
              allData: res[index],
            }
          : {
              value:
                concat && element[secondValueName]
                  ? element[valueName] + ' - ' + element[secondValueName]
                  : element[valueName],
              id: element[idName],
              allData: res[index],
            }
      );
    });
    return resValue;
  }

  /**
   * @description Método para lanzar el servicio del filtro. Se recogen los parámetros y se envía.
   * @param {boolean} [button=true] Si se ejecuta desde el botón del filtro (true) o por ejemplo desde ordenación o paginación (false).
   * @param {boolean} [initialCall] Primera llamada del filtro (usualmente sin parámetros), al cargar el filter-table.
   * @param {boolean} [guia] Si es para la guía.
   * @returns {Observable<any>}
   * @memberof FilterTableService
   */
  searchFilterData(
    button = true,
    initialCall?: boolean,
    guia?: boolean
  ): Observable<any> {
    let $ObservableData: Observable<any> = null;
    if (button) {
      // reiniciamos la página después de una búsqueda del filtro
      if (!this.cache.OBJECT_FILTER_REQUEST) {
        this.initRequestParams();
      }
      this.cache.OBJECT_FILTER_REQUEST.page = 0;

      if (this.customTableService.getCheckboxArray().length > 0) {
        // Borramos el Array de checkboxes seleccionados si procede
        this.customTableService.emptyCheckBoxArray();
      }

      // Guardamos la última búsqueda para la paginación y ordenación a través de servicio back
      this.cache.BODY_REQUEST_OLD = this.bodyParams;
      this.cache.OBJECT_FILTER_REQUEST.body = this.bodyParams;
    } else {
      this.cache.OBJECT_FILTER_REQUEST.body = this.cache.BODY_REQUEST_OLD;
    }
    if (this.cache.FILTER_TABLE_CONFIG) {
      const params = {
        ...this.cache.OBJECT_FILTER_REQUEST,
        ...this.cache.OBJECT_FILTER_REQUEST.body,
      };
      switch (this.pageType) {
        case PageTypeEnum.CONSULT_REQUEST:
          switch (params.sort) {
            case 'category,ASC':
              params.sort = 'category.category,ASC';
              break;
            case 'category,DESC':
              params.sort = 'category.category,DESC';
              break;
            case 'subcategory,ASC':
              params.sort = 'category.subcategory,ASC';
              break;
            case 'subcategory,DESC':
              params.sort = 'category.subcategory,DESC';
              break;
            default:
              break;
          }
          if (!params.sort) {
            params.sort = 'startDate,DESC';
          }
          $ObservableData = this.ticketsService.getAllTicketsUsingGet(params);
          break;
        default:
          break;
      }
    }
    return $ObservableData;
  }

  /**
   * @description Método para la obtención de un archivo desde un servicio
   * @param type tipo de archivo
   * @returns Retorna un observable con el archivo
   */
  downloadFile(type?: string): Observable<any> {
    const $ObservableData: Observable<any> = null;

    // parámetros para el servicio de obtención del archivo
    const objectRequest = {
      ...this.BASE_PARAMS,
      ...this.cache.BODY_REQUEST_OLD,
    };
    switch (
      this.pageType
      // EXAMPLE
      // case PageTypeEnum.SYSTEM_COLLECTIVE:
      //   throw new Error('Missing API call');
      // $ObservableData = this.processesService.getHrServiceReport(objectRequest);
      // break;
    ) {
    }

    return $ObservableData;
  }

  /**
   * @description Método para borrar un elemento de la caché del servicio
   * @param elementKey Elemento a borrar.
   */
  removeElementCacheData(elementKey: string): void {
    if (
      elementKey === 'BODY_REQUEST_OLD' ||
      elementKey === 'OBJECT_FILTER_REQUEST'
    ) {
      this.searchParams = new FIlterSendData();
      this.bodyParams = null;
    }
    Object.keys(this.cache).forEach((elementRef: string) => {
      if (elementKey === elementRef) {
        if (Array.isArray(this.cache[elementRef])) {
          this.cache[elementRef] = [];
        } else {
          this.cache[elementRef] = null;
        }
      }
    });
  }

  /**
   * @description Método para cargar la tabla con los datos conservando el filtro estado.
   * @param elementKey Elemento a borrar.
   */
  removeCacheDataPreservingField(
    elementKey: string,
    fieldToPreserve: string
  ): void {
    Object.keys(this.cache).forEach((elementRef: string) => {
      if (elementKey === elementRef) {
        if (Array.isArray(this.cache[elementRef])) {
          this.cache[elementRef] = [];
        } else {
          const statusBackup = this.preserveStatus(
            this.cache[elementRef],
            elementRef,
            fieldToPreserve
          );
          this.cache[elementRef] = null;
          if (elementRef === 'OBJECT_FILTER_REQUEST') {
            this.cache[elementRef] = statusBackup;
          }
        }
      }
    });
  }

  /**
   * @description Método para borrar todos los datos de la caché del servicio
   */
  removeAllCacheData(): void {
    Object.keys(this.cache).forEach((elementRef: string) => {
      if (Array.isArray(this.cache[elementRef])) {
        this.cache[elementRef] = [];
      } else {
        this.cache[elementRef] = null;
      }
    });
  }

  /**
   * @description Método para borrar todos los datos del servicio
   */
  removeAllServiceData() {
    this.pageType = null;
    this.userRoleType = null;
    this.bodyParams = {};
    this.searchParams = new FIlterSendData();
    this.removeAllCacheData();
  }

  /**
   * @description Método para mapear los resltados de los servicios para los controles la vista vista del filtro.
   * La configuración del mapeo viene de la propiedad 'resultsMapKey' del control.
   * Es previo a mapResults donde se controla que elemento de la llamada REST contiene el array de datos a mapear.
   * @private
   * @param {*} val Valores a mapear
   * @param {*} ctrl Control de donde obtener los valores de mapeo (key, value).
   * @returns
   * res: any[], valueName: string, idName: string, secondIdName?: string, secondValueName?: string, concat?: boolean
   */
  private mapResultsByKey(
    val: any,
    ctrl: any,
    secondValueName?: string,
    concat?: boolean
  ) {
    const mock = JSON.parse(JSON.stringify(val));
    let result = [];
    // SI vienen más de una key se pasa el
    if (ctrl.keysMap.keys.length > 1) {
      result = this.mapResults(
        mock,
        ctrl.keysMap.value,
        ctrl.keysMap.keys[0],
        ctrl.keysMap.keys[1]
      );
    } else {
      result = this.mapResults(
        mock,
        ctrl.keysMap.value,
        ctrl.keysMap.keys.length > 0 ? ctrl.keysMap.keys[0] : 'id',
        '',
        secondValueName,
        concat
      );
    }
    return result;
  }

  /**
   * @description preserve status filter and delete the rest of filters
   */

  private preserveStatus(
    cacheElement: any,
    elementRef: string,
    fieldToPreserve: string
  ) {
    if (elementRef === 'OBJECT_FILTER_REQUEST') {
      const bodyWithOnlyStatus = cacheElement.hasOwnProperty('body')
        ? cacheElement.body
        : null;
      Object.keys(bodyWithOnlyStatus).forEach((property) => {
        if (property !== 'status.equals') {
          delete bodyWithOnlyStatus[property];
        }
      });
      return { body: bodyWithOnlyStatus };
    }
  }

  private checkResultSize(response: any): void {
    this.moreResultThanSize =
      response.totalElements > this.inputSizeSearchLimit;
  }
}
