import { Injectable, Injector } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { UtilsService } from '../services/utils/utils.service';
import { environment } from '@environments/environment';
import { StorageService } from '../services/storage/storage.service';



@Injectable()
export class HttpMockRequestInterceptor implements HttpInterceptor {
    private mocksEnabled = false;
    constructor(
        private utils: UtilsService,
        private storage: StorageService
        ) {
            this.mocksEnabled = environment.mock === true || (this.storage.get('enableMocks') === true &&  document.location.hostname === 'localhost');
        }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const reqConfig = this.utils.matchRequestEndpoint(request) || {name: 'notRecognized'};


        if (this.mocksEnabled) {
            let delayRef = 1500;
            let prefix = '';
            if(request.url === '/scg/cfg/frontconfig'){
                const fileName = 'frontconfig';
                this.viewCall(request, reqConfig, fileName);
                request = new HttpRequest('GET', 'assets/config.json');
                delayRef = 0;
            }   else {
                if(reqConfig.name==='notRecognized'){
                    const splitted = request.url.split('/');
                    const addName = splitted[(splitted.length-1)].split('?');
                    reqConfig.name = addName[0];
                    console.warn('------> Rest NOT RECOGNIZED, splitting URL <------', request.url, reqConfig.name);
                    delayRef = 0;
                    prefix = 'ext_'
                }
                const fileName = request.headers.get('forceFile') || reqConfig.name;
                this.viewCall(request, reqConfig, fileName);
                request = new HttpRequest('GET', 'assets/mocks/' + prefix + fileName + '.json');
            }
            return next.handle(request).pipe(delay(delayRef), catchError((err)=>{
                console.info('------> Rest Name Fail <------', reqConfig.name);
                return of({});
            }));
        } else {
        }
        return next.handle(request);
      }

      viewCall(request: HttpRequest<any>, reqConfig, reqName){
        const consoleRef = console.log;
        consoleRef('------> Rest Name <------', reqName);
        consoleRef('------> Rest Config <------', reqConfig);
        consoleRef('------> Rest Body <------', request.body);
        consoleRef('------> Rest Config enabled <------', this.mocksEnabled);
      }
}
