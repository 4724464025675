export interface TagManagerPage {
    entidad?: string;
    primer_nivel_organizativo?: string;
    site_id?: string;
    page_name: string;
    page_title: string;
    page_type: string;
    page_category?: string;
    lang?: string;
    entorno?: string;
    colectivo?: string;
}

export interface TagManagerEvent {
    action: string;
    category: string;
    label: string;
    url: string;
}

export enum TypeEventExternal {
    MENU,
    LINK,
    DOC,
    VIDEO,
    SEARCH
}