import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from '@core/services/utils/utils.service';
import { AppUrls } from '@config/app-urls.config';

export interface CustomHeaders {
  [name: string]: string;
}

export interface HttpCustomOptions {
  baseUrl?: string;
  forceHttps?: boolean;
  customHeaders?: CustomHeaders;
}

/**
 * Interceptor for customizing http requests.
 * It will take into account the environment's endpoint-specific headers and http protocol config
 */
@Injectable()
export class HttpCustomInterceptor implements HttpInterceptor {

  private customHeaders: CustomHeaders;
  private interceptorConfig: HttpCustomOptions;

  constructor(
    private readonly utils: UtilsService
  ) {
  }

  /**
   * Helper function to handle setting request url considering the baseUrl provided by config
   * and the protocol (https) in case the option 'forceHttps' is enabled for that specific endpoint.
   *
   * If the request url already contains protocol (http or https) it will ignore the baseUrl
   *
   * @param interceptorConfig: HttpCustomOptions
   * @param requestUrl: string
   * @param requestEndpoint: any
   */
  setUrl(interceptorConfig: HttpCustomOptions, requestUrl: string, requestEndpoint: any): string {
    requestUrl = requestUrl.toLowerCase().startsWith('http') ? requestUrl : requestEndpoint.baseUrl + requestUrl;
    return interceptorConfig.forceHttps ? requestUrl.replace('http://', 'https://') : requestUrl;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isConfigEndpoint = request.url.indexOf(AppUrls.Config) !== -1;

    if (!isConfigEndpoint) {
      const requestEndpoint = this.utils.matchRequestEndpoint(request);
      this.interceptorConfig = this.utils.getInterceptorConfig(requestEndpoint, 'HttpCustom') || {};
      this.customHeaders = this.interceptorConfig.customHeaders;
      // this.logger.log(`HttpCustomConfig for url: ${request.url} \n is: ${JSON.stringify(this.interceptorConfig)}`);
      request = request.clone({
        setHeaders: this.customHeaders,
        url: this.setUrl(this.interceptorConfig, request.url, requestEndpoint)
      });
    }
    return next.handle(request);
  }
}
