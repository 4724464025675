import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Modal } from '@app/shared/models/modal/modal.model';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() modal: Modal = null;
  modalForm: FormGroup = new FormGroup({});

  constructor(config: NgbModalConfig, public activeModal: NgbActiveModal) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    if (this.modal?.inputText){
      this.modalForm.addControl(
        this.modal.inputText.controlName,
        new FormControl({ value: this.modal.inputText.value, disabled: this.modal.inputText.disabled },
          this.modal.inputText.required ? Validators.required : null));
    }
    if (this.modal?.textArea){
      this.modalForm.addControl(
        this.modal.textArea.controlName,
        new FormControl('', this.modal.textArea.required ? Validators.required : null));
    }
  }

  save(value?: string) {
    if (this.modal?.date || this.modal?.textArea || this.modal?.inputText){
      this.activeModal.close(this.modalForm.value);
    }
    else {
      this.activeModal.close(this.modal);
    }
  }

  cancel(){
    this.activeModal.dismiss('Cancelar');
  }

  close(){
    this.activeModal.dismiss('Close');
  }

  finish(){
    this.modal.finish = true;
    this.activeModal.close(this.modal);
  }
}
