import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'onehr-item-translation',
  templateUrl: './item-translation.component.html',
  styleUrls: ['./item-translation.component.scss']
})
export class ItemTranslationComponent {
  @Input() dataFormTranslation: any;
  @Input() parentFormTranslation: FormGroup;
  @Output() valueSelected: EventEmitter<any> = new EventEmitter<any>();

  openDropdown: boolean;

  emitValueSelected($event: any): void {
    this.valueSelected.emit($event);
  }
}
