import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'onehr-item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss']
})
export class ItemDetailComponent{
  @Input() dataForm: any;
  @Input() parentForm: FormGroup;
  @Input() dataFormTranslation: any;
  @Input() parentFormTranslation: FormGroup;
  @Output() cancelEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() saveEvent: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  saveEdit(): void {
    this.saveEvent.emit(this.parentForm.value);
  }

  exitEditView(): void {
    this.parentForm.reset();
    this.cancelEvent.emit();
  }
}
