import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { HttpService } from '@app/core/http/http.service';
import { EnviromentService } from '@app/core/services/environment/environment.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ElementsHeaderWs, StructureGetResponse } from './header.model';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  isLogoVisible: boolean = true;
  showCloseButton: boolean = false;
  titleInHeader: string = '';
  callbackRoute: string = null;
  avoidHeaderRestore: boolean = false;
  isPublicMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  isHeaderVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _endNavigationInterception: Subject<void> = new Subject<void>();

  constructor(private readonly router: Router,
              private readonly config: EnviromentService,
              private readonly http: HttpService) {
  }

  hideLogo(): void {
    this.isLogoVisible = false;
    this.interceptBackNavigationEvents();
  }

  showLogo(): void {
    this.isLogoVisible = true;
  }

  showCloseButtonInHeader(callbackRoute: string): void {
    this.showCloseButton = true;
    this.callbackRoute = callbackRoute;
  }

  goToCallbackRoute(): void {
    this.router.navigate([this.callbackRoute], { replaceUrl: true })
      .then(() => {
        this.avoidHeaderRestore ? this.avoidHeaderRestore = false : this.restoreDefaultHeader();
      });
  }

  restoreDefaultHeader(): void {
    this.showCloseButton = false;
    this.showLogo();
    this.titleInHeader = '';
    this._endNavigationInterception.next();
    this._endNavigationInterception.complete();
  }

  setTitleInHeader(title: string): void {
    this.hideLogo();
    this.titleInHeader = title;
  }

  interceptBackNavigationEvents() {
    this.router.events
      .pipe(
        takeUntil(this._endNavigationInterception),
        filter(event => event instanceof NavigationStart)
      )
      .subscribe(
        (event: NavigationStart) => {
          if (event.restoredState) {
            this.restoreDefaultHeader();
          }
        }
      );
  }


  getElementsHeader() {
    const URLgetElementsHeader: string = this.config.get().app.rest.PS.baseUrl + this.config.get().app.rest.PS.endpoints.ElementsHeader.url;
    const wsRequest = {
      languageId: sessionStorage.getItem('language'),
      hrStructureId: Number(sessionStorage.getItem('hrStructureId'))
    };
    const params = {
      languageId: sessionStorage.getItem('language'),
      hrStructureId: Number(sessionStorage.getItem('hrStructureId'))
    };
    return this.http.request('post', URLgetElementsHeader, params).pipe(
      map((header: ElementsHeaderWs) => {
        if (header.elementData) {
          header.elementData.forEach((el: any) => {
            if (el.xlatElementType === "S") {
              el.subMenu = header.subMenuData.filter(
                element => element.submenu === el.elementCD
              );
              el.showSubMenu = false;
            }
          });
        }
        return header;
      })
    );
  }


  public getStructures(structure_id: number): Observable<StructureGetResponse> {
    let URLgetStructures: string = this.config.get().app.rest.PS.baseUrl + this.config.get().app.rest.PS.endpoints.Structures.url;
    const httpHeaders = new HttpHeaders()
      .append('X-USER-ROL', '');
    const id: string = structure_id.toString();
    URLgetStructures = URLgetStructures.replace('{structure_id}', id);

    return this.http.request('get', URLgetStructures, { headers: httpHeaders }).pipe(
      map(r => {
        return r as StructureGetResponse;
      })
    );
  }

  getLanguagesStructure(): Observable<any> {
    const hrStructureId = sessionStorage.getItem('hrStructureId');
    if (hrStructureId) {
      return this.getStructures(Number(hrStructureId)).pipe(map((resp: StructureGetResponse) => {
        return resp.languages;
      }));
    } else {
      return of([]);
    }
  }


  setLanguage(language, cmsLanguageCode) {
    sessionStorage.setItem('language', language);
    sessionStorage.setItem("languageContId", cmsLanguageCode);
  }
}
