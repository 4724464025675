<div class="mb-4" [ngClass]="{'box-form': showBoxBorder}">

    <form class="row" [formGroup]="searchFormGroup">
        <div *ngFor="let input of inputList" class="col-12"
            [ngClass]="input.bootstrapClass ? input.bootstrapClass : 'col-md-4 '">
            <div class="form-group" *ngIf="input.type === 'search' || input.type === 'select'">
                <div>
                    <label [for]="input.name">{{ input.name | translate }}</label>
                    <app-search-input
                        [inputList]="inputList"
                        [parentForm]="searchFormGroup"
                        [dependency]="input.dependency"
                        [dependency2]="input.dependency2"
                        [controlName]="input.controlName"
                        [placeholder]="input.placeholder"
                        [minLength]="input.minLength"
                        [results]="input.results"
                        [required]="input.required"
                        [dropdown]="input.dropdown"
                        [value]="input.value"
                        [selectType]= "input.selectType"
                        [initialLoad]="input.initialLoad"
                        [filter]="input.filter"
                        [maximHeight]="input.maximHeight"
                        (setValue)="valueSelected($event)">
                    </app-search-input>
                </div>
            </div>
            <app-input-text *ngIf="input.type === 'text'"
                [label]="input.name"
                [parentForm]="searchFormGroup"
                [controlName]="input.controlName"
                [placeholder]="input.placeholder"
                [minLength]="input.minLength"
                [required]="input.required"
                [value]="input.value"
                [disabled]="input.disableInput"
                (search)="valueSelected($event)">
            </app-input-text>
          <onehr-input-number *ngIf="input.type === 'number'"
                              [label]="input.name"
                              [parentForm]="searchFormGroup"
                              [controlName]="input.controlName"
                              [placeholder]="input.placeholder"
                              [min]="input.minValue"
                              [max]="input.maxValue"
                              [value]="input.value"
                              (search)="valueSelected($event)">
          </onehr-input-number>
          <app-input-datepicker *ngIf="input.type === 'date'"
                [parentForm]="searchFormGroup"
                [controlName]="input.controlName"
                [minDateSelected]="input.minDay"
                [maxDateSelected]="input.maxDay"
                [label]="input.name | translate"
                [btnBorrar]="input.btnBorrar"
                [startDay]="input.startDay"
                [readonly]="true"
                (dateSelected)="valueSelected($event)">
            </app-input-datepicker>
            <app-input-checkbox *ngIf="input.type === 'checkbox'"
                [label]="input.name"
                [parentForm]="searchFormGroup"
                [controlName]="input.controlName"
                [placeholder]="input.placeholder"
                [minLength]="input.minLength"
                [required]="input.required"
                [value]="input.value"
                [disabled]="input.disableInput"
                (search)="valueSelected($event)">
            </app-input-checkbox>
        </div>
        <div class="col-md-12 p-0" *ngIf="showButton">
            <div class="btn-block border-top pb-3" [ngClass]="{'d-flex justify-content-center': !textWarning}">
                <div class="search-warning text-right pr-4" *ngIf="textWarning">
                    {{ 'COMMON.FILTER.SEARCH_WARNING' | translate }}
                </div>
                <button type="button" class="btn btn-primary" (click)="doSearch()" [disabled]="disabledSearch">
                    {{'COMMON.FILTER.SEARCH' | translate}}
                </button>
            </div>
        </div>
    </form>
</div>
