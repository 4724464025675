<div class="my-4">
  <div class="box-form">
    <div class="box-info">
      <esda-box-title-form [boxBorderForm]="false"
                           [dataForm]="dataForm"
                           [parentForm]="parentForm"></esda-box-title-form>
      <onehr-item-translation
        [dataFormTranslation]="dataFormTranslation"
        [parentFormTranslation]="parentFormTranslation"></onehr-item-translation>

      <div class="btn-block justify-content-center pb-3">
        <button type="button" class="btn btn-secondary btn-lg" (click)="exitEditView()">
          {{'COMMON.BUTTONS.CANCEL' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="saveEdit()">
          {{'COMMON.BUTTONS.SAVE' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
