import { Injectable } from '@angular/core';

/**
 * Se encarga de realizar todos los almacenamientos internos de la página asi recuperarlos
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  /**
   * Prefijo con el cual se van a almacenar todos los storages
   */
  prefix: string;
  constructor(
  ) {
    this.prefix = 'one_';
  }
  /**
   * Cambia el prefijo. Esto replicará para todas sus invocaciones.
   * @param prefixRef Prefijo con el cual se van a almacenar todos los storages
   */
  setPrefix(prefixRef: string) {
    this.prefix = prefixRef;
  }

  /**
   * Añade o modifica un valor al locastorage
   * @param key id del valor
   * @param value contenido del valor
   */
  set(key, value) {
    sessionStorage.setItem(this.prefix + key, JSON.stringify(value));
  }

  /**
   * Recupera un valor del storage, Si este valor no esta definido devolverá null
   * @param key id del contenido a recuperar
   */
  get(key) {
    try {
      return JSON.parse(sessionStorage.getItem(this.prefix + key));
    } catch (error) {
      return sessionStorage.getItem(this.prefix + key);
    }
  }

  /**
   * Elimina un contenido del storage
   * @param key id del contenido a eliminar
   */
  remove(key) {
    sessionStorage.removeItem(key);
  }

  /**
   * Añade o modifica un valor al locastorage
   * @param key id del valor
   * @param value contenido del valor
   */
  setLocal(key, value) {
    localStorage.setItem(this.prefix + key, JSON.stringify(value));
  }

  /**
   * Recupera un valor del storage, Si este valor no esta definido devolverá null
   * @param key id del contenido a recuperar
   */
  getLocal(key) {
    try {
      return JSON.parse(localStorage.getItem(this.prefix + key));
    } catch (error) {
      console.log('No se pudo recuperar el objeto del navegador');
      return localStorage.getItem(this.prefix + key);
    }
  }

  /**
   * Elimina un contenido del storage
   * @param key id del contenido a eliminar
   */
  removeLocal(key) {
    localStorage.removeItem(key);
  }
}
