import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadCrumb } from '@app/shared/models/breadcrumb/breadcrumbs.model';
import { Buttons } from '@app/shared/models/modal/modal.model';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {
  @Input() title;
  @Input() button: Buttons = null;
  @Input() disableButton = false;
  pageTitle = 'error';
  ROUTE_DATA_BREADCRUMB = 'breadcrumb';

  constructor(public activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    if (this.title) {
      this.pageTitle = this.title;
    } else {
      this.getPageTitle(this.activatedRoute);
    }
  }

  /**
   * @param route recomended: this.activatedRoute parametro que captura la ruta acual
   */
  public getPageTitle(route: ActivatedRoute) {
    if (route.snapshot.data.hasOwnProperty(this.ROUTE_DATA_BREADCRUMB)) {
      const data: BreadCrumb = route.snapshot.data[this.ROUTE_DATA_BREADCRUMB];

      if (data) {
        this.pageTitle = data.item.titulo;
      }
    }
  }

  actionButton(button) {
    button.onActionExecute();
  }
}
