import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FilterCtrlEnum, IdependencyCtrl, IfilterCtrl } from '../search-filter/search-filter.model';
import { FilterTableService } from '../filter-table/filter-table.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnviromentService } from '@core/services/environment/environment.service';
import { TranslationsService } from '@core/services/translations/translations.service';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() dependency: IdependencyCtrl = null;
  @Input() dependency2: IdependencyCtrl = null;
  @Input() initialLoad = false;
  @Input() filter = null;
  @Input() parentForm: FormGroup;
  @Input() value = null;
  @Input() placeholder = '';
  @Input() controlName: string;
  @Input() selectType: boolean = false;
  @Input() results: Array<any> = [];
  @Input() required = false;
  @Input() minLength: number;
  @Input() dropdown = false;
  @Input() notMinLength = false;
  @Input() disabled = false;
  @Input() strictDependency = false;
  @Input() inputList: IfilterCtrl[] = null;
  @Input() id = '';
  @Input() icnClose: boolean = true;
  @Input() maximHeight: number;
  // @Input() catalog: CatalogField;
  @Input() catalog: any;

  @Output() setValue = new EventEmitter();

  originalPlaceholder: string = '';
  showAlert = false;
  showResults = false;
  selectValueResults = false;
  inputValue = null;
  configSetValue = false;
  inputCtrl: AbstractControl;
  inputCrlConfig: IfilterCtrl;
  selectIdCtrl: AbstractControl;
  dependencyCtrl: AbstractControl;
  dependencyCtrl2: AbstractControl;
  controlResults = false; // Es un flag para controlar si los results vienen seteado desde la configuración del search-filter.model
  showDescription = false;
  inputSizeSearchLimit: number = 50;
  resultOversize: boolean = false;
  extraFieldToShow: string[] = [];
  private dependencyInitData: string | number;
  private dependencyInitData2: string | number;
  private dependencyData: any;
  private dependencyData2: any;
  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly translateService: TranslationsService,
    private readonly filterTableService: FilterTableService,
    private readonly environmentService: EnviromentService
  ) {
  }

  ngAfterViewInit(): void {
    this.inputSizeSearchLimit = this.environmentService.get().app.properties.inputSizeSearchLimit;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getInputCtrlConfig();
    this.placeholderNoData(changes);
    this.createControl();
    this.controlLoadResults(changes);
    if (changes.dependency && changes.dependency.currentValue && changes.dependency.currentValue.dependencyData) {
      this.dependencyData = changes.dependency.currentValue.dependencyData;
    }
    this.setCtrlValue(changes);
  }

  ngOnInit() {
    // Si en la configuración existe la propiedad "required", setea el validador y el minLength
    if (this.required) {
      if (this.minLength) {
        const validatorsArray: ValidatorFn[] = [Validators.minLength(this.minLength), Validators.required];
        this.parentForm.controls[this.controlName].setValidators(Validators.compose(validatorsArray));
      } else {
        this.parentForm.controls[this.controlName].setValidators(Validators.required);
      }
    } else if (this.minLength) {
      this.parentForm.controls[this.controlName].setValidators(Validators.minLength(this.minLength));
    }

    // Si existe un filtro de inicio en la configuración del ctrl. Dicho filtro se envía al servicio para que devuelva
    // los valores iniciales del select.
    if (this.selectType && this.initialLoad && this.filter) {
      this.parentForm.controls[this.controlName].setValue(this.filter);
      this.value = this.filter;
    }

    // Si el control es un select y en la configuración del ctrl existe el "initialLoad",
    // realiza una llamada para obrener los datos iniciales
    if (this.selectType && this.initialLoad) {
      this.searchData(true);
    }

    // switch (this.controlName) {
    //   case FilterCtrlEnum.EMPLOYEE_SEARCH:
    //     this.extraFieldToShow = ['name'];
    //     break;
    //   case FilterCtrlEnum.COMPANY:
    //     this.extraFieldToShow = ['description'];
    //     break;
    // }

    this.originalPlaceholder = this.placeholder;
    this.subscribeToDependencyEvents();
  }

  getInputCtrlConfig() {
    if (!this.inputCrlConfig) {
      this.inputList.forEach(inputListElement => {
        if (Array.isArray(inputListElement)) {
          if (inputListElement.filter(element => element.controlName === this.controlName)[0]) {
            this.inputCrlConfig = inputListElement.filter(element => element.controlName === this.controlName)[0];
          }
        } else {
          this.inputCrlConfig = this.inputList.filter(element => element.controlName === this.controlName)[0];
        }
      });
    }
  }

  /**
   * @description Método que evalúa si no hay resultados para setear un placeholder con el mensaje "no hay datos"
   */
  placeholderNoData(changes) {
    if (changes.results && changes.results.previousValue && changes.results.currentValue.length === 0) {
      this.inputCtrl.setValue('');
      this.placeholder = 'COMMON.SEARCH_INPUT.NO_DATA';
    }
  }

  /**
   * @description Método que crea el control del formulario parentForm
   */
  createControl() {
    if (!this.inputCtrl) {
      this.parentForm.addControl(
        this.controlName,
        new FormControl('', Validators.minLength(this.minLength))
      );

      // si es un select debemos setar el control que almacena el id ya q no trabajamos con el value del input
      // así reproducimos la manera de funcionar de un elemento html select, q muestra un valor en vista pero almacena otro
      if (this.selectType) {
        const selectTypeNameCtrl = `${ this.controlName }_id`;
        this.parentForm.addControl(
          selectTypeNameCtrl,
          new FormControl('')
        );
        // Almacenamos el input del id del select para que nos sea más facil trabajar con él
        this.selectIdCtrl = this.parentForm.get(selectTypeNameCtrl);
      }
    }

    // Almacenamos el input para que nos sea más facil trabajar con él
    this.inputCtrl = this.parentForm.get(this.controlName);
  }

  /**
   * @description Método que setea los valores del ctrl
   */
  // tslint:disable-next-line: cyclomatic-complexity
  setCtrlValue(changes: SimpleChanges) {
    // Si vienen datos a través del input "value"
    if (changes.value && changes.value.currentValue !== undefined && this.inputCtrl) {
      this.setChanges(changes);
    }
    if (this.value?.value) {
      if (this.dependency) {
        this.checkdependencyInitValue();
      }
    } else {
      this.value ? this.value.id = null : null;
    }
  }

  private setChanges(changes: SimpleChanges): void {
    if (this.selectType) {
      this.selectIdCtrl.setValue(changes.value.currentValue.value === '' ? '' : changes.value.currentValue.id);
    }
    this.inputCtrl.setValue(changes.value.currentValue.value);
    this.inputValue = changes.value.currentValue.value;

    if (changes.value &&
      changes.value.firstChange &&
      this.inputCrlConfig.dependency &&
      this.inputCrlConfig.dependency.dependencyInitialData) {

      this.configSetValue = true;
    }
    this.selectValue(Object.assign(changes.value.currentValue, {
      controlName: this.controlName,
      initial: this.configSetValue
    }));
    this.configSetValue = false;
  }

  /**
   * @description Método que evalúa si existe dependencia de otro control para setear el valor de este
   */
  checkdependencyInitValue() {
    if (!this.dependencyCtrl) {
      this.dependencyCtrl = this.parentForm.get(this.dependency.controlName);
      if (this.dependency2) {
        this.dependencyCtrl2 = this.parentForm.get(this.dependency2.controlName);
      }
    }

    if (this.dependencyInitData === undefined && this.dependency.dependencyInitialData) {
      this.dependencyInitData = this.dependency.dependencyInitialData;
      if (this.dependency2) {
        this.dependencyInitData2 = this.dependency2.dependencyInitialData;
      }
    }
  }

  /**
   * @description Método que lanza el evento de selección de un valor en el deplegable
   */
  selectValue(item) {
    this.selectValueResults = true;
    this.setValue.emit({
      item,
      controlName: this.controlName,
      initial: this.initialLoad,
      ctrlType: this.inputCrlConfig.type || item.initial
    });
    this.setValueByType(item);
    if (this.dependency) {
      if (this.selectType) {
        this.selectIdCtrl.setValue(item.id);
      }
    }
    if (this.selectType) {
      this.showResults = false;
    }
  }

  private setValueByType(item): void {
    if (this.extraFieldToShow.length > 0 && !this.inputCrlConfig.extraParamOnlyInDropdown) {
      let finalValue = item.value && item.value !== '' ? this.translateService.instant(item.value) : '';

      for (let i = 0; i < this.extraFieldToShow.length; i++) {
        finalValue += ' - ' + item[this.extraFieldToShow[i]];
      }
      this.inputCtrl.setValue(finalValue);
    } else if (isNaN(item.value)) {
      this.inputCtrl.setValue(item.value && item.value !== '' ? this.translateService.instant(item.value) : '');
    } else {
      this.inputCtrl.setValue(item.value);
    }
  }

  /**
   * @description Método que lanza la búsqueda (lupa) del search-input (no selectType)
   */
  iconSearch(event: any, controlName: string) {
    event.preventDefault();
    const control = this.parentForm.controls[controlName];
    this.showAlert = false;
    if (!this.disabled) {
      if (this.minLength) {
        if (this.required) {
          this.showAlert = control.invalid;
        } else {
          this.showAlert = control.errors && Object.keys(control.errors?.minlength).length > 0;
        }
      }
      // } else {
      //   this.showAlert = control.invalid;
      // }
    }
    if (!this.showAlert) {
      this.searchData(false);
    }
    event.stopPropagation();
  }

  /**
   * @description Método para la búsqueda de datos del search-input
   * @param [initial] Primera búsqueda lanzada por la propiedad del control "initialLoad"
   */
  searchData(initial?: boolean) {
    let dependencyData = null;
    let dependencyData2 = null;

    // Si se tiene depedencia de un valor de otro control del formulario. Viene seteado en la configuración del control
    if (this.dependency) {
      this.checkdependencyInitValue();
      dependencyData2 = this.setDependency2IfDefined(dependencyData2);
      dependencyData = this.setDependency(dependencyData);
    }

    // Petición de datos del search-input que rellenarán los desplegables de selección
    const toSend = {
      name: this.controlName,
      value: this.parentForm.controls[this.controlName]?.value,
      // value: !this.filter ? this.parentForm.controls[this.controlName]?.value :  null,
      keysMap: this.inputCrlConfig.resultsMapKey
    };

    const toSenddependency = {
      key: this.inputCrlConfig.dependency ? this.inputCrlConfig.dependency.dependencyKey : undefined,
      value: this.inputCrlConfig.dependency?.dependencyDataId ? this.inputCrlConfig.dependency.dependencyDataId : dependencyData
    };

    const toSenddependency2 = {
      key: this.inputCrlConfig.dependency2 ? this.inputCrlConfig.dependency2.dependencyKey : undefined,
      value: dependencyData2
    };

    this.filterTableService.searchInput(toSend, toSenddependency, toSenddependency2, this.initialLoad, this.catalog)
      .pipe(takeUntil(this._destroy$))
      .subscribe((val) => {
          this.resultOversize = this.filterTableService.moreResultThanSize;
          if (val && val.length > 0) {
            this.results = val;
            // Si es la no es la primera llamada (initialLoad) mostramos los resultados
            if (!initial) {
              this.showResults = true;
            }
            this.setCustomLogicByControlName(val);
          } else {
            this.parentForm.controls[this.controlName].setValue('');
            this.setCustomErrorHandlerByControlName();
          }
        }
      );
  }

  private setDependency(dependencyData): any {
    if (this.dependencyCtrl && this.dependencyCtrl.value && this.dependencyCtrl.value !== '') {
      dependencyData = this.dependencyCtrl.value;
    } else if (
      ((this.dependencyInitData && this.dependencyInitData !== '') ||
        typeof this.dependencyInitData === 'number')) {
      dependencyData = this.dependencyInitData;
      this.dependencyInitData = null;
    } else if (this.dependencyData) {
      dependencyData = this.dependencyData;
    }
    return dependencyData;
  }

  private setDependency2IfDefined(dependencyData2): any {
    if (this.dependencyCtrl2 && this.dependencyCtrl2.value && this.dependencyCtrl2.value !== '') {
      dependencyData2 = this.dependencyCtrl2.value;
    } else if (
      ((this.dependencyInitData2 && this.dependencyInitData2 !== '') ||
        typeof this.dependencyInitData2 === 'number')) {
      dependencyData2 = this.dependencyInitData2;
      this.dependencyInitData2 = null;
    } else if (this.dependencyData2) {
      dependencyData2 = this.dependencyData2;
    }
    return dependencyData2;
  }

  /**
   * @description Método que hace cerrar el desplegable del select si se hace click fuera de este
   */
  clickOutside() {
    this.showResults = false;
  }

  /**
   * @description Método que hace cerrar el desplegable del select si se hace click en el input
   */
  clickInput($event) {
    $event.preventDefault();
    if (this.selectType) {
      this.showResults = !this.showResults;
    }
    $event.stopPropagation();
  }

  /**
   * @description Método que evalúa si debe aparecer el alert del input "Introduce al menos 3 caracteres"
   */
  inputChanges(event) {
    if (this.minLength) {
      this.showAlert = this.inputCtrl.invalid;
    }
    if (event.inputType === 'deleteContentBackward') {
      this.cleanField();
    } else if (event.inputType === 'insertText') {
      this.parentForm.controls[this.controlName].setErrors({ 'incorrect': true });
    }
  }

  cleanField() {
    if (this.inputCtrl.value === '' || this.inputCtrl.value.length === 0) {
      const item = null;
      this.setValue.emit({ item, controlName: this.controlName, delete: true });
      this.parentForm.controls[this.controlName].setErrors({ 'incorrect': true });
    }
  }

  /**
   * @description Método que lanza el evento de borrado del input
   */
  delete() {
    this.placeholder = this.originalPlaceholder;
    this.inputCtrl.setValue(null);
    if (this.selectType) {
      this.selectIdCtrl.setValue(null);
    }
    this.showAlert = false;
    const item = null;

    // Si es de tipo select y no tiene los results seteados en la configuración se vuelven a pedir los datos a Back
    this.selectType && !this.controlResults && this.searchData();

    this.setValue.emit({ item, controlName: this.controlName, delete: true });
  }

  private subscribeToDependencyEvents(): void {
    if (!this.disabled && this.strictDependency && this.dependency) {
      const dependencyControl: AbstractControl = this.parentForm.get(this.dependency.controlName);

      if (dependencyControl.value !== '') {
        this.parentForm.get(this.controlName).enable();
      } else if (dependencyControl.value === '') {
        this.parentForm.get(this.controlName).disable();
      }

      this.parentForm.get(this.dependency.controlName).valueChanges
        .pipe(takeUntil(this._destroy$))
        .subscribe(value => {
          if (value === '' || value === null) {
            this.placeholder = this.originalPlaceholder;
            this.parentForm.get(this.controlName).setValue('');
            this.parentForm.get(this.controlName).disable();
          } else {
            this.parentForm.get(this.controlName)?.enable();
          }
        });

      if (this.dependency2) {
        this.parentForm.get(this.dependency2.controlName).valueChanges
          .pipe(takeUntil(this._destroy$))
          .subscribe(value => {
            this.placeholder = '';
          });
      }
    }
  }

  private controlLoadResults(changes: SimpleChanges) {
    // Si es un select y vienen datos en el input "results"
    if (this.selectType && changes.results && changes.results.firstChange) {
      this.controlResults = true;
    } else if (!this.selectType && changes.results && changes.results.currentValue && changes.results.currentValue.length > 0) {
      // Si no es un select y no vienen datos en el input "results"
      this.showResults = true;
      this.placeholder = '';
    }
  }

  private setCustomLogicByControlName(val: any): void {
    switch (this.controlName) {
      case FilterCtrlEnum.WITHHOLDING_CERTIFICATE_YEAR:
      case FilterCtrlEnum.YEAR:
      case FilterCtrlEnum.MONTH:
        this.selectValue(val[0]);
        break;
    }
  }

  private setCustomErrorHandlerByControlName(): void {
    switch (this.controlName) {
      case FilterCtrlEnum.WITHHOLDING_CERTIFICATE_YEAR:
        this.placeholder = this.translateService.instant('WITHHOLDING_CERTIFICATE.NO_CERTIFICATES');
        this.setValue.emit(null);
        break;
      case FilterCtrlEnum.YEAR:
        this.placeholder = this.translateService.instant('RETRIBUTIONS.NO_CERTIFICATES');
        this.setValue.emit(null);
        break;
      default:
        this.placeholder = 'COMMON.SEARCH_INPUT.NO_DATA';
    }
  }
}
