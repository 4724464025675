<div class="intro-header" *ngIf="headerService.isHeaderVisible | async">
  <div class="d-flex">
    <div class="pointer">
      <img [routerLink]="['/home']"
           class="img-fluid logo-img-header"
           src="./assets/img/santanderSilver_neg.svg" alt="logo">
    </div>
    <div class="header-language">
      <div class="header-language-item" (click)="openTooltipLanguage()">
        <i class="icn-access"></i>
      </div>
      <div class="tooltip-container" *ngIf="showLanguagesDropdown">
        <div *ngFor="let language of availableLanguages" class="tooltip-text"
             [ngClass]="{'icon-success': language.isDefault}"
             (click)="setLanguage(language.hrLanguage, language.cmsLanguageCode)">{{ language.name }}</div>
      </div>
    </div>
  </div>
</div>
